import { ConsultantProfile } from './../../consultants/models/consultant-profile';
import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';
import { backEndpoint } from 'src/app/config';
import {Observable} from 'rxjs';

const endpoint =  backEndpoint+'/api/consultant-profile';

@Injectable({
  'providedIn': 'root'
})
export class ConsultantProfileService {

  profile: ConsultantProfile = null;
  consultantid: string = null;

  constructor(private http: HttpClient){}

  setProfile(data: ConsultantProfile){
    this.profile = data;
  }

  getProfile(){
    return this.profile;
  }

  setConsultantid(data: string){
    this.consultantid = data;
  }

  getConsultantid(): string{
    return this.consultantid;
  }

  getProfileById(id: string): Observable<any>{
    return this.http.get(endpoint+"/getByid", {params: {'id': id}});
  }

  getProfileByUserid(userid: string): Observable<any>{
    return this.http.get(endpoint+"/getByUserid", {params: {'userid': userid}});
  }

  getProfileByConsultantid(id: string): Observable<any>{
    return this.http.get(endpoint+"/getByConsultantid", {params: {'id': id}});
  }

  updateProfile(data): Observable<ConsultantProfile>{
    return this.http.put<ConsultantProfile>(endpoint + '/update-profile', data);
  }

  updateProfileByRH(data): Observable<ConsultantProfile>{
    return this.http.put<ConsultantProfile>(endpoint + '/update-profile-rh', data);
  }

  addCv(data: any): Observable<any> {
    return this.http.post(endpoint+"/add-cv", data);
  }

  deleteCv(location : string, id:string) : Observable<any> {
    return this.http.get(endpoint+"/delete-cv", {params: {"filepath": location, "id": id}});
  }

  addDocument(data: any): Observable<any> {
    return this.http.post(endpoint+"/add-document", data);
  }

  deleteDocument(location : string, intitule: string, id:string) : Observable<any> {
    return this.http.get(endpoint+"/delete-document", {params: {"filepath": location, "id": id, "intitule": intitule}});
  }

  qualifierConsultant(idprofile: string): Observable<any>{
    return this.http.get(endpoint+"/qualifier", {params: {'id': idprofile}});
  }

  registerProfile(data: any): Observable<any>{
    return this.http.post(endpoint+"/register-consultant", data);
  }

  downloadFile(location: string){
    return this.http.get(endpoint+'/downloadFile', {params: {"filepath": location}, responseType: 'blob'});
  }

}
