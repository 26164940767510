import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { TokenStorageService } from './token-storage.service';

import { CRM_BACK_ENDPOINT } from "../config";
import { Client } from "../Model/client";
import { Contact } from "../Model/contact.model";

const URL = CRM_BACK_ENDPOINT+"/";

@Injectable({
  providedIn: "root",
})
export class ClientServiceService {
  constructor(private http: HttpClient, private token: TokenStorageService) {}



  yvesgetBesoin(
    pageNum,
    pageSize,
    nomClient?,
    nomContact?,
    prenomContact?,
    dateSaisie?,
    descript?,
    statut?,
    ao_statut?,
    trigGestionnaireRH?,
    trigBesoin?,
    sortby = "dateSaisie",

    searchbar?,
    dateenvoi?,
    sortDirection = "1"
  ) {
    let fd = new FormData();

    console.log(this.token.getUser().roles[1])


    fd.append("nomClient", nomClient);
    fd.append("nomContact", nomContact);
    fd.append("prenomContact", prenomContact);
    fd.append("dateSaisie", dateSaisie);
    fd.append("dateenvoi", dateenvoi);
    fd.append("statut", statut);
    fd.append("descript", descript);
    fd.append("ao_statut", ao_statut);
    fd.append("trigGestionnaireRH", trigGestionnaireRH);
    fd.append("trigBesoin", trigBesoin);
    fd.append("sortBy", sortby);
    fd.append("droit", this.token.getUser().roles[1]);
    fd.append("trigrammeuser", this.token.getUser().trigramme);
    fd.append("searchbar", searchbar);
    fd.append("sortDirection", sortDirection);
    fd.append("pageSize", pageSize);
    fd.append("pageNum", pageNum);

    return this.http.post<any[]>(
      CRM_BACK_ENDPOINT+"/yves/getBesoinsYves",
      fd
    );
  }

  FindById(id) {
    let params = new HttpParams();
    params = params.append(`id`, id);
    params = params.append(`tri`, this.token.getUser().trigramme);
    return this.http.get<any>(URL + "client/find", { params });
  }

  FindByIdCRM(id) {
    let params = new HttpParams();
    params = params.append(`id`, id);
    params = params.append(`tri`, this.token.getUser().trigramme);
    return this.http.get<any>(CRM_BACK_ENDPOINT + "/client/find", { params });
  }

  FindCotactById(id) {
    return this.http.get<any>(URL + "client/getcontactbyid/" + id);
  }
  FindByEmail(id) {
    let params = new HttpParams();
    params = params.append(`id`, id);
    return this.http.get<Contact>(URL + "client/findByEmail", { params });
  }
  Find(id) {
    let params = new HttpParams();
    params = params.append(`id`, id);
    return this.http.get<Client>(URL + "client/findClientByEmail", { params });
  }
  filterbesoin(besoin, tri) {
    let params = new HttpParams();
    params = params.append(`besoin`, besoin);
    params = params.append(`trigramme`, tri);

    return this.http.get<any>(URL + "client/filterbesoin", { params });
  }
  filter(nom, tri) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token.getToken(),
    });

    return this.http.get<any>(
      URL + "client/filter/?nom=" + nom + "&trigramme=" + tri,
      { headers }
    );
  }
  ListAllStatut() {
    return this.http.get<any[]>(URL + "refstatutbesoin/all");
  }
  updatebesoin() {

    return this.http.get<any[]>(URL + "client/besoinsupdate");
  }

  getallbesoin(idClient, idcontact) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token.getToken(),
    });
    return this.http.get<any[]>(
      URL +
        "client/allbesoinsupdate/?idClient=" +
        idClient +
        "&idContact=" +
        idcontact,

      { headers }
    );
  }
}
