import { authEndpoint, BASE_URL } from './../config/index';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable ,BehaviorSubject, Subject} from 'rxjs';
import { Router } from '@angular/router';
import { TokenStorageService } from './token-storage.service';


const AUTH_API = authEndpoint+'/api/auth/';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  roles: string[] = [];
  private readonly _authverify = new BehaviorSubject<Boolean>(false);
  readonly authverify$ = this._authverify.asObservable()
  authSubject = new Subject<any>()

  private userid: string = "";
  private tokenid: string = "";
  private useremail: string = "";

  constructor(private http: HttpClient, private route:Router, private tokenStorage: TokenStorageService) { }

  getUserid(): string {
    return this.userid;
  }

  setUserid(id: string): void {
    this.userid = id;
  }

  getTokenid(): string {
    return this.tokenid;
  }

  setTokenid(id: string): void {
    this.tokenid = id;
  }

  getUseremail(): string {
    return this.useremail;
  }

  setUseremail(email: string): void {
    this.useremail = email;
  }

  emitAuthentication(isAuth: boolean){
    this.authSubject.next(isAuth)
  }

  setauth(state:Boolean){
    this._authverify.next(state);
  }

  requestPassword(email:any): Observable<any> {
    return this.http.post(AUTH_API+'requestPassword', email, httpOptions);
  }

  sendCode(email: string, code: string): Observable<any>{
    return this.http.get(AUTH_API+'requestPassword', {params: {
      "email": email,
      "code": code
    }});
  }

  resetPassword(credentials: any): Observable<any> {
    return this.http.post(AUTH_API + 'resetPassword', credentials, httpOptions);
  }

  registerConsultant(data: any): Observable<any> {
    data.lienDesinscription = BASE_URL+"/identification/";
    return this.http.post(AUTH_API+"register-consultant", data);
  }

  getauth():Boolean{
    let user = this.tokenStorage.getUser();
    if(user){
      this.setauth(true)
    }else{
      this.setauth(false)
    }
    return this._authverify.getValue();
  }
  getname(){
    return this.tokenStorage.getUser().nom +" " +  this.tokenStorage.getUser().prenom;
  }
  login(credentials): Observable<any> {
    return this.http.post(AUTH_API + 'signin', {
      email: credentials.email,
      password: credentials.password
    }, httpOptions);
  }

  register(user): Observable<any> {

    return this.http.post(AUTH_API + 'signup', {
      email: user.email,
      trigramme: user.trigramme.toUpperCase(),
      password: user.password,
      rolerh: user.roles,
      rolecrm: user.rolescrm,
      nom:user.nom,
      prenom:user.prenom
    }, httpOptions);
  }

  getUsers(): Observable<any> {
    return this.http.get(AUTH_API + 'get');
  }

  delete(id): Observable<any> {
    return this.http.delete<any>(AUTH_API + `delete/${id}`, httpOptions);
  }

  canActivate(){
    let token= this.tokenStorage.getToken();
    const user = this.tokenStorage.getUser();

    this.roles = user.roles;
    if(token && user){
      if(this.tokenStorage.getsignature()===""){
        this.tokenStorage.setsignature(user.trigramme)
      }
    return true;
    }
    return false;
  }

  canModerate(){
    let token= this.tokenStorage.getToken();
    const user = this.tokenStorage.getUser();
    this.roles = user.roles;
    if((token) && ((this.roles[0]=== "Gestionnaire")||(this.roles[0]=== "Facturation")||(this.roles[0]=== "Ecriture"))){
    return true;
    }else{
    return false;
    }
  }

  getRole()
{
  let token= this.tokenStorage.getToken();
  const user = this.tokenStorage.getUser();
  if(token){
    return user.roles[0]
  }
  return false;
}

canAdmin(){
    let token= this.tokenStorage.getToken();
    const user = this.tokenStorage.getUser();
    this.roles = user.roles;
    if((token) && ((this.roles[0]=== "Gestionnaire"))){
    return true;
    }else{
    return false;
    }
  }

  canWrite(){
    let token= this.tokenStorage.getToken();
    const user = this.tokenStorage.getUser();
    this.roles = user.roles;
    if((token) && ((this.roles[0]=== "Lecture"))){
    return true;
    }else{
    return false;
    }
  }

}
