import { Observation } from './observation';

export class Rappels {
    id: String;
    idC: String;
    date: string;
    libelleRappel: String;
    trigramme: String;
    actif: String;
    email: String;
    nom: String;
    prenom: String;
    titre: String;
    observation: Observation;
    numrappel: number;
}
