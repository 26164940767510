import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbNavConfig } from '@ng-bootstrap/ng-bootstrap';
import { ConsultantProfileService } from 'src/app/services/consultants/consultant-profile.service';

@Component({
  selector: 'app-test-page',
  templateUrl: './test-page.component.html',
  styleUrls: ['./test-page.component.scss'],
  providers: [NgbNavConfig]
})
export class TestPageComponent implements OnInit {

  category: string = "";
  name: string = "";
  consultantid: string = "";

  constructor(config: NgbNavConfig, private activatedRoute: ActivatedRoute, private profileService: ConsultantProfileService) {
		// customize default values of navs used by this component tree
		config.destroyOnHide = true;
		config.roles = false;

    this.category = this.activatedRoute.snapshot.paramMap.get("category")!;
    this.name = this.activatedRoute.snapshot.paramMap.get("name")!;

	}

  ngOnInit(): void {
      this.consultantid = this.profileService.getConsultantid();
  }

}
