import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  toggled = false;
  _hasBackgroundImage = true;
  menus = [
    {
      title: 'Dashboard',
      icon: 'fa fa-home',
      active: true,
      path: 'dashboard'
    },
    {
      title: 'Consultants',
      icon: 'fa fa-users',
      active: false,
      path: 'Liste',
      type: 'dropdown',
      submenus: [
        {
          title: 'Liste',
          icon: 'fa fa-list',
          path: 'Liste'
        },
        {
          title: 'Ajout',
          icon: 'fa fa-user-plus',
          path: 'ajoutc'
        },
        {
          title: 'Rech Tags',
          icon: 'fa fa-tag',
          active: false,
          path: '/rechtags'
        },
        {
          title: 'Rech MC',
          icon: 'fa fa-search',
          active: false,
          path: '/rechmc'
        }
      ]
    },
    {
      title: 'Rappels',
      icon: 'fa fa-calendar-alt',
      active: false,
      path: '/rappels'
    },
    {
      title: 'Paramètres',
      icon: 'fa fa-cog',
      active: false,
      path: 'tags',
      type: 'dropdown',
      submenus: [
        {
          title: 'TAGs',
          icon: 'fa fa-tag',
          path: 'tags'
        },
        {
          title: 'Listes',
          icon: 'fa fa-edit',
          path: '/listeD'
        },
        {
          title: 'Utilisateurs',
          icon: 'fa fa-user-plus',
          path: 'users'
        }
      ]
    }
  ];
  constructor() { }

  toggle() {
    this.toggled = ! this.toggled;
  }

  getSidebarState() {
    return this.toggled;
  }

  setSidebarState(state: boolean) {
    this.toggled = state;
  }

  getMenuList() {
    return this.menus;
  }

  get hasBackgroundImage() {
    return this._hasBackgroundImage;
  }

  set hasBackgroundImage(hasBackgroundImage) {
    this._hasBackgroundImage = hasBackgroundImage;
  }
}
