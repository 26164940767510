<form *ngIf='csdata' [formGroup]="registerForm">
    <div class="alert alert-success m-2" *ngIf="consultant.qualifie">
        Profil qualifié et validé.
    </div>

    <div class="alert alert-danger m-2" *ngIf="consultant.nonqualifie">
        Désolé, votre profil n'a pas été accepté.
    </div>
    <!-- Grid row -->
    <div class="container-fluid mb-3">
        <div class="card">
            <div class="card-body">
                <div class="w-100 h5 text-danger text-center font-weight-bold mb-3">Informations personnelles</div>
                <div class="row">
                    <div class="col-md-2 form-group">
                        <label for="">Nom <font size="2" face="georgia" color="red">(*)</font></label>
                        <input [ngModelOptions]="{standalone: true}" mdbInput type="text" formControlName="nom" [(ngModel)]="consultant.nom" class="form-control" id="inputEmail4MD" name="nom" [ngClass]="{ 'is-invalid': submitted && f.nom.errors }" required>
                    </div>

                    <div class="col-md-2 form-group">
                        <label for="">Prénom <font size="2" face="georgia" color="red">(*)</font></label>
                        <input [ngModelOptions]="{standalone: true}" mdbInput type="text" class="form-control" id="inputEmail4MD" [(ngModel)]="consultant.prenom" formControlName="prenom" name="prenom" [ngClass]="{ 'is-invalid': submitted && f.prenom.errors }" required>
                    </div>

                    <div class="form-group col-md-3">
                        <label for="inputAddressMD">Titre <font size="2" face="georgia" color="red">(*)</font></label>
                        <input [ngModelOptions]="{standalone: true}" mdbInput mdbValidate type="text" [(ngModel)]="consultant.titre" class="form-control" id="inputForTitre" formControlName="titre" [ngClass]="{ 'is-invalid': submitted && f.titre.errors }" name="titre">
                    </div>

                    <div class="col-md-1 form-group">
                        <label for="">TJM</label>
                        <input [ngModelOptions]="{standalone: true}" type="text" class="form-control" [(ngModel)]="consultant.tjm" id="inputPassword4MD" formControlName="tjm" name="tjm">
                    </div>

                    <div class="col-md-1 form-group">
                        <label for="">Disponibilité</label>
                        <select [ngModelOptions]="{standalone: true}" class="browser-default custom-select" (change)="changeDispo($event.target.value)" formControlName="dis" name="dis" [(ngModel)]="consultant.codedispo.libelle">
                        <option  *ngFor="let disponibilitedata of Dispo" [value]="disponibilitedata.libelle">{{disponibilitedata.libelle}}</option>
                      </select>
                    </div>

                    <div class="col-md-1 form-group">
                        <label for="inputCVMD">CV <font size="2" face="georgia" color="red">(*)</font> </label><br>
                        <div style="display: flex;border: 1px solid  #dadada;padding: 2px;margin-left: 5px;">
                            <i class="bi bi-paperclip" style="font-size: 22px; font-weight: 800;" (click)="fileInput.click()"></i>
                            <i class="bi bi-eye-fill mr-3" style="font-size: 22px; font-weight: 800;" *ngIf="consultant.cv.length>0" (click)="openModal(listCvModal)"></i>
                            <!-- <i class="bi bi-x-circle-fill" style="font-size: 22px; font-weight: 800; color: red;" *ngIf="consultant.cv && consultant.cv != ''" (click)="deleteFile()"></i> -->
                            <!-- (click) = "showCV(cvmodal)" -->
                        </div>
                        <input (change)="onFilechange($event)" type="file" style="display: none;" #fileInput>
                    </div>

                    <div class="col-md-2 form-group">
                        <label for="">Tél Principal<font size="2" face="georgia" color="red">(*)</font></label>
                        <input [ngModelOptions]="{standalone: true}" mdbInput type="text" class="form-control" id="inputAddressMD" [(ngModel)]="consultant.telephone_principal" formControlName="telp" id="telp" name="telp" [ngClass]="{ 'is-invalid': submitted && f.telp.errors }">
                    </div>

                </div>

                <div class="row">

                    <div class="form-group col-md-3">
                        <label for="inputAddressMD">E-mail <font size="2" face="georgia" color="red">(*)</font></label>
                        <input [ngModelOptions]="{standalone: true}" mdbInput mdbValidate type="email" [(ngModel)]="consultant.email" class="form-control" id="inputEmailMD" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" name="email">
                    </div>

                    <div class="col-md-1 form-group">
                        <label for="inputAddressMD">C.P.</label>
                        <input [ngModelOptions]="{standalone: true}" mdbInput type="text" class="form-control" id="inputPostalMD" [(ngModel)]="consultant.postal" formControlName="code" name="code">
                    </div>

                    <div class="col-md-3 form-group" style="margin-left:0px;margin-right: 0px;">
                        <label for="inputVilleMD">Ville</label>
                        <input [ngModelOptions]="{standalone: true}" mdbInput type="text" class="form-control" [(ngModel)]="consultant.ville" id="inputAddress2MD" formControlName="ville" name="ville">
                    </div>


                    <div class="col-md-2 form-group ">
                        <label for="inputDDAMD ">Date début activité</label><br>
                        <input [ngModelOptions]="{standalone: true}" style="font-size: 1rem;" class="form-control " [(ngModel)]="consultant.dda" [max]="today" formControlName="dda" name="dda" type="date">
                    </div>


                    <div class="col-md-2 form-group ">
                        <label for="inputAddressMD ">LinkedIn</label>
                        <div style="display: flex;border: 1px solid  #dadada;padding: 2px; ">
                            <div style="margin-right:1.5vw ;font-weight: bold;padding: 2px; ">

                                <img width="25 " height="25 " style="color: blue;margin-left: 0.5vw;cursor: pointer;margin-right: 0.5vw; " *ngIf="linkedinTest(consultant.linkedin) " (click)="openLinkekin(consultant.linkedin) " src="../../assets/linkedin.png" alt=" ">
                            </div>
                            <a (click)="showLinkedin(linkedin); " style="border: 1px solid black;margin-right:1.5vw ;font-weight: bold;padding: 2px; ">MàJ</a>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>

    <div class="container-fluid d-flex flex-row-reverse">
        <button class="btn btn-primary" (click)="update()">Enregistrer</button>
    </div>

</form>

<div class="container-fluid mb-5" *ngIf='csdata'>
    <div class="mb-2" style="font-weight: bold;">Mes attentes</div>
    <div class="md-form md-outline ">
        <div style="overflow: auto;font-weight: bold; ">
            <table style="border:0px ">
                <tr>
                    <th style="padding: 5px;display: flex;width: 100%;justify-content: space-between;align-items: center;"></th>
                    <th style="width: 10%;text-align: center;padding: 5px;"></th>
                </tr>
            </table>
        </div>
        <div id="ob " style="max-height: 40vw; overflow: auto; border:1px solid black ">
            <table class="table ">
                <tbody *ngIf="consultant.attentes">
                    <tr *ngFor="let elt of consultant.attentes; let i = index; ">
                        <td style="padding: 5px;display: flex;width: 100%;justify-content: space-between;align-items: center; ">

                            <div style="width: 100%;display: flex; ">
                                <p style="width: 100%;margin: 0;font-size: 18px; ">{{TruncateObs(elt.attente)}}</p>
                                <button (click)="OpenPopup(elt.attente) " *ngIf="elt.attente.length>=220" style="border: 2px solid black;font-weight: bold;">...</button>
                            </div>
                            <div style="width: auto;margin-left: 25px;display: flex;">
                                <img (click)="removeAttente(i)" style="cursor: pointer;margin-left: 5px;" width="20" height="20" src="../../assets/delete.png" alt="">
                            </div>
                        </td>
                        <td style="width: 10%;text-align: center;padding: 5px;">{{elt.date}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="mt-2 d-flex">
            <button class="btn btn-sm mr-2 px-3" style="background-color: #86CB8C;" (click)="addAttente()"> <i class="fa fa-plus"></i></button>

            <input type="text" id="obs" class="form-control" placeholder="Saisir les attentes ..." [(ngModel)]="attente" name="attente">
        </div>
    </div>
</div>

<!-- <div class="mt-4" *ngIf='csdata'>
    <div class="d-flex justify-content-center mb-4">
        <div class="col-md-3">
            <div class="w-100 text-center font-weight-bold mb-2" style="font-size: 30px;"><i class="bi bi-people-fill"></i></div>
            <div class="card">
                <div class="card-body p-2">
                    <div class="w-100 h5 text-danger text-center font-weight-bold mb-1">Parrainage Consultant</div>
                    <div class="w-100 h6 text-danger text-center font-weight-bold mb-2">Code pour parrainer : {{consultant.codeparrainage}}</div>

                    <div class="row col-12">
                        <div class="col-8">
                            <label style="font-weight: bold; text-align: left;">Consultants inscrits</label>
                        </div>
                        <div class="col-4">
                            <input type="text" class="form-control form-control-sm mb-1" name="tcompte" [(ngModel)]="consultant.nbreinscription" disabled>
                        </div>
                    </div>
                    <div class="row col-12">
                        <div class="col-8">
                            <label style="font-weight: bold; text-align: left;">Consultants qualifiés</label>
                        </div>
                        <div class="col-4">
                            <input type="text" class="form-control form-control-sm mb-1" name="tcompte" [(ngModel)]="consultant.nbrequalification" disabled>
                        </div>
                    </div>
                    <div class="row col-12">
                        <div class="col-8">
                            <label style="font-weight: bold; text-align: left;">Consultants placés</label>
                        </div>
                        <div class="col-4">
                            <input type="text" class="form-control form-control-sm mb-1" name="tcompte" [(ngModel)]="consultant.nbreplacement" disabled>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-around">
        <div class="col-md-3">
            <div class="card">
                <div class="card-body p-2">
                    <div class="w-100 h5 text-danger text-center font-weight-bold mb-2">Coordonnées bancaires</div>
                    <form>
                        <div class="form-group">
                            <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Titulaire</label>
                            <input type="text" class="form-control form-control-sm mb-1" name="tcompte" [(ngModel)]="facturation.paiementtitulaire" disabled>
                            <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">IBAN</label>
                            <input type="text" class="form-control form-control-sm mb-1" name="icompte" [(ngModel)]="facturation.paiementiban" disabled>
                            <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">BIC</label>
                            <input type="text" class="form-control form-control-sm mb-1" name="bcompte" [(ngModel)]="facturation.paiementbic" disabled>
                            <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Domiciliation</label>
                            <input type="text" class="form-control form-control-sm mb-1" name="dcompte" [(ngModel)]="facturation.paiementdomiciliation" disabled>
                            <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Adresse</label>
                            <textarea type="text" class="form-control form-control-sm mb-1" rows="2" name="ad" [(ngModel)]="facturation.paiementadresse" disabled></textarea>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="card mb-lg-3">
                <div class="card-body p-2">
                    <div class="w-100 h5 text-danger text-center font-weight-bold mb-2">Informations société <br> (le cas échéant)</div>
                    <form>
                        <div class="form-group">
                            <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Société</label>
                            <input type="text" class="form-control form-control-sm mb-1" name="societe" [(ngModel)]="facturation.paiementsociete" disabled>
                            <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">RC</label>
                            <input type="text" class="form-control form-control-sm mb-1" name="rc" [(ngModel)]="facturation.paiementrc" disabled>
                            <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">MF</label>
                            <input type="text" class="form-control form-control-sm mb-1" name="mf" [(ngModel)]="facturation.paiementmf" disabled>
                            <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">N° TVA</label>
                            <input type="text" class="form-control form-control-sm mb-1" name="tva" [(ngModel)]="facturation.paiementtva" disabled>
                        </div>
                        <div class="form-group">
                            <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Adresse</label>
                            <textarea type="text" class="form-control form-control-sm mb-1" rows="2" name="ad" [(ngModel)]="facturation.paiementadresse" disabled></textarea>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div> -->

<ng-template #linkedin class="modal fade" id="update" role="dialog" style="border:1px solid black">
    <div class="modal-header" style="background-color: whitesmoke;">

        <h4 class="modal-title" style="margin-left:120px;">Modifier le lien</h4>
        <button type="button" class="close" data-dismiss="modal" (click)="CancelModal()">&times;</button>
    </div>
    <div class="modal-body" style="background-color: whitesmoke;">
        <form role="form">
            <div class="form-group">
                <input [ngModelOptions]="{standalone: true}" mdbInput type="text" class="form-control" style="display: block;" [(ngModel)]="consultant.linkedin" name="linkedin">
            </div>
            <button (click)="changeLinkedin()" type="submit" id="boutton" class="btn btn-success btn-block" style="width: 150px;margin:auto;font-size: small;">Modifier</button>
        </form>
    </div>
    <div class="modal-footer" style="background-color: whitesmoke;">
        <button type="submit" class="btn btn-danger btn-sm" style="margin:auto;display:block;width: 100px;" data-dismiss="modal" (click)="cancelrappel()">Cancel</button>
    </div>
</ng-template>

<ng-template #listCvModal let-modal class="modal fade" role="dialog" style="border:1px solid black">
    <div class="modal-header text-center" style="background-color: whitesmoke;">
        <h5 class="text-center">Historique des CVs</h5>
    </div>
    <div class="modal-body p-4" style="background-color: whitesmoke;">
        <table class="table table-striped table-hover">
            <thead>
                <tr class="table-secondary">
                    <th>Date</th>
                    <th>Voir CV</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of consultant.cv">
                    <td>{{item.dateajout}}</td>
                    <td><i class="bi bi-eye-fill mr-3" style="font-size: 22px; font-weight: 800;" (click)="visualiser(item.path)"></i></td>
                </tr>
            </tbody>
        </table>
        <div class="d-flex justify-content-center mt-4">
            <button type="button" class="btn btn-secondary" (click)="modal.close('click close button')">Fermer</button>
        </div>
    </div>
</ng-template>