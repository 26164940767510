import { authEndpoint, siteBackEndpoint } from './../config/index';
import { HttpClient } from '@angular/common/http';
import {Injectable} from "@angular/core";
import { Observable } from 'rxjs';
import { backEndpoint, RHbackEndpoint } from '../config';

const endpoint =  backEndpoint+'/api/consultant-profile';
const rhEndpoint =  RHbackEndpoint+'/consultant';
const siteEndpoint = siteBackEndpoint+'/user-data';

@Injectable({
  providedIn: 'root'
})
export class DeleteAccountService {
  private userid: string = "";

  constructor(private http: HttpClient){ }

  getUserid(): string {
    return this.userid;
  }

  setUserid(id: string): void {
    this.userid = id;
  }

  deleteProfile(deleteData: boolean): Observable<any> {
    return this.http.delete(endpoint+"/delete-account/"+deleteData);
  }

  deleteRHData(deleteData: boolean): Observable<any>{
    return this.http.delete(rhEndpoint+"/delete-account/"+deleteData);
  }

  deleteSiteData(deleteData: boolean): Observable<any>{
    return this.http.delete(siteEndpoint+"/delete-account/"+deleteData+"/consultant");
  }

  deleteUser(): Observable<any>{
    return this.http.delete(authEndpoint+"/api/auth/unsuscribe")
  }

}
