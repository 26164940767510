<div class="container-fluid mt-4" *ngIf="profile">

    <div class="row">
        <div class="col-md-6">
            <div class="container-fluid mt-2 mb-5">

                <div class="table-container table-responsive">
                    <div class="w-100 text-center py-1 text-danger" style="font-size: 23px; background-color: #EFEEEE; font-weight: 700;">
                        Technologies
                    </div>
                    <table id="element" class="table table-striped table-hover">
                        <thead>
                            <tr class="table-secondary">
                                <th scope="col">
                                    Technologie
                                </th>
                                <th scope="col">
                                    Niveau de compétence
                                </th>

                                <th scope="col">
                                    Durée pratique (Mois)
                                </th>

                                <th scope="col">
                                    Référence
                                </th>

                                <th scope="col">
                                    Documents et liens
                                </th>
                            </tr>
                        </thead>
                        <tbody style="position: relative;">
                            <tr *ngFor="let tech of profile.competencestechnos; let i=index;">
                                <td class="font-weight-bold">{{tech.intitule}}</td>
                                <td>
                                    <select class="form-select form-select-sm p-1" [name]="'niv'+i" [(ngModel)]="tech.niveau" style="width: 90%;">
                                    <option value="notions">Notions</option>
                                    <option value="intermediaire">Intermédiaire</option>
                                    <option value="avance">Avancé</option>
                                    <option value="expert">Expert</option>
                                  </select>
                                </td>
                                <td>
                                    <input type="number" [name]="'duree'+i" [(ngModel)]="tech.dureepratique">
                                </td>
                                <td>
                                    <i class="bi bi-person-fill action-icon mr-2" (click)="openModal(listRefModal, tech)"></i>
                                    <i class="bi bi-plus-circle-fill action-icon" style="color: green;" (click)="openModal(addRefModal, tech)"></i>
                                </td>
                                <td>
                                    <span class="mr-4">
                                    <i class="bi bi-eye-fill action-icon mr-2" *ngIf="tech.document!=''" (click)="visualiser(tech.document)"></i>
                                    <i class="bi bi-paperclip action-icon" (click)="openFileInput(fileInput, tech)" *ngIf="tech.document==''"></i>
                                    <i class="bi bi-x-circle-fill action-icon" style="color: red;" (click)="deleteDocument(tech)" *ngIf="tech.document!=''"></i>
                                  </span>
                                    <span>
                                    <i class="bi bi-browser-chrome action-icon mr-2" (click)="openLink(client.tech)"></i>
                                    <i class="bi bi-plus-circle-fill action-icon mr-2" style="color: green;" (click)="openModal(linkModal, tech)" *ngIf="tech.lien==''"></i>
                                    <i class="bi bi-x-circle-fill action-icon" style="color: red;" (click)="deleteLink(tech)" *ngIf="tech.lien!=''"></i>
                                  </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>

        <div class="col-md-6">
            <div class="container-fluid mt-2 mb-5">

                <div class="table-container table-responsive">
                    <div class="w-100 text-center py-1 text-danger" style="font-size: 23px; background-color: #EFEEEE; font-weight: 700;">
                        Activités
                    </div>
                    <table id="element" class="table table-striped table-hover">
                        <thead>
                            <tr class="table-secondary">
                                <th scope="col">
                                    Activité
                                </th>
                                <th scope="col">
                                    Niveau de compétence
                                </th>

                                <th scope="col">
                                    Durée pratique
                                </th>

                                <th scope="col">
                                    Référence
                                </th>

                                <th scope="col">
                                    Documents et liens
                                </th>
                            </tr>
                        </thead>
                        <tbody style="position: relative;">
                            <tr *ngFor="let act of profile.competencesactivites; let i=index;">
                                <td class="font-weight-bold">{{act.intitule}}</td>
                                <td>
                                    <select class="form-select form-select-sm p-1" [name]="'aniv'+i" [(ngModel)]="act.niveau" style="width: 90%;">
                                    <option value="notions">Notions</option>
                                    <option value="intermediaire">Intermédiaire</option>
                                    <option value="avance">Avancé</option>
                                    <option value="expert">Expert</option>
                                  </select>
                                </td>
                                <td>
                                    <input type="number" [name]="'aduree'+i" [(ngModel)]="act.dureepratique">
                                </td>
                                <td>
                                    <i class="bi bi-person-fill action-icon mr-2" (click)="openModal(listRefModal, act)"></i>
                                    <i class="bi bi-plus-circle-fill action-icon" style="color: green;" (click)="openModal(addRefModal, act)"></i>
                                </td>
                                <td>
                                    <span class="mr-4">
                                      <i class="bi bi-eye-fill action-icon mr-2" *ngIf="act.document!=''" (click)="visualiser(act.document)"></i>
                                      <i class="bi bi-paperclip action-icon" (click)="openFileInput(fileInput, act)" *ngIf="act.document==''"></i>
                                      <i class="bi bi-x-circle-fill action-icon" style="color: red;" (click)="deleteDocument(act)" *ngIf="act.document!=''"></i>
                                  </span>
                                    <span>
                                    <i class="bi bi-browser-chrome action-icon mr-2" (click)="openLink(act.lien)"></i>
                                    <i class="bi bi-plus-circle-fill action-icon mr-2" style="color: green;" (click)="openModal(linkModal, act)" *ngIf="act.lien==''"></i>
                                    <i class="bi bi-x-circle-fill action-icon" style="color: red;" (click)="deleteLink(act)" *ngIf="act.lien!=''"></i>
                                  </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    </div>

    <div class="row mt-5">

        <div class="col-md-6">
            <div class="container-fluid mt-2 mb-5">

                <div class="table-container table-responsive">
                    <div class="w-100 text-center py-1 text-danger" style="font-size: 23px; background-color: #EFEEEE; font-weight: 700;">
                        Secteurs
                    </div>
                    <table id="element" class="table table-striped table-hover">
                        <thead>
                            <tr class="table-secondary">
                                <th scope="col">
                                    Secteur
                                </th>

                                <th scope="col">
                                    Durée pratique
                                </th>

                                <th scope="col">
                                    Référence
                                </th>

                                <th scope="col">
                                    Documents et liens
                                </th>
                            </tr>
                        </thead>
                        <tbody style="position: relative;">
                            <tr *ngFor="let sect of profile.competencessecteurs; let i=index;">
                                <td class="font-weight-bold">{{sect.intitule}}</td>
                                <td>
                                    <input type="number" [name]="'sduree'+i" [(ngModel)]="sect.dureepratique">
                                </td>
                                <td>
                                    <i class="bi bi-person-fill action-icon mr-2" (click)="openModal(listRefModal, sect)"></i>
                                    <i class="bi bi-plus-circle-fill action-icon" style="color: green;" (click)="openModal(addRefModal, sect)"></i>
                                </td>
                                <td>
                                    <span class="mr-4">
                                      <i class="bi bi-eye-fill action-icon mr-2" *ngIf="sect.document!=''" (click)="visualiser(sect.document)"></i>
                                      <i class="bi bi-paperclip action-icon" (click)="openFileInput(fileInput, sect)" *ngIf="sect.document==''"></i>
                                      <i class="bi bi-x-circle-fill action-icon" style="color: red;" (click)="deleteDocument(sect)" *ngIf="sect.document!=''"></i>
                              </span>
                                    <span>
                                <i class="bi bi-browser-chrome action-icon mr-2" (click)="openLink(sect.lien)"></i>
                                  <i class="bi bi-plus-circle-fill action-icon mr-2" style="color: green;" (click)="openModal(linkModal, sect)" *ngIf="sect.lien==''"></i>
                                    <i class="bi bi-x-circle-fill action-icon" style="color: red;" (click)="deleteLink(sect)"  *ngIf="sect.lien!=''"></i>
                              </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>

        <div class="col-md-6">
            <div class="container-fluid mt-2 mb-5">

                <div class="table-container table-responsive">
                    <div class="w-100 text-center py-1 text-danger" style="font-size: 23px; background-color: #EFEEEE; font-weight: 700;">
                        Clients
                    </div>
                    <table id="element" class="table table-striped table-hover">
                        <thead>
                            <tr class="table-secondary">
                                <th scope="col">
                                    Client
                                </th>

                                <th scope="col">
                                    Durée pratique
                                </th>

                                <th scope="col">
                                    Référence
                                </th>

                                <th scope="col">
                                    Documents et liens
                                </th>
                            </tr>
                        </thead>
                        <tbody style="position: relative;">
                            <tr *ngFor="let client of profile.competencesclients; let i=index;">
                                <td class="font-weight-bold">{{client.intitule}}</td>
                                <td>
                                    <input type="number" [name]="'cduree'+i" [(ngModel)]="client.dureepratique">
                                </td>
                                <td>
                                    <i class="bi bi-person-fill action-icon mr-2" (click)="openModal(listRefModal, client)"></i>
                                    <i class="bi bi-plus-circle-fill action-icon" style="color: green;" (click)="openModal(addRefModal, client)"></i>
                                </td>
                                <td>
                                    <span class="mr-4">
                                      <i class="bi bi-eye-fill action-icon mr-2" *ngIf="client.document!=''" (click)="visualiser(client.document)"></i>
                                      <i class="bi bi-paperclip action-icon" (click)="openFileInput(fileInput, client)" *ngIf="client.document==''"></i>
                                      <i class="bi bi-x-circle-fill action-icon" style="color: red;" (click)="deleteDocument(client)" *ngIf="client.document!=''"></i>
                                  </span>
                                    <span>
                                    <i class="bi bi-browser-chrome action-icon mr-2" (click)="openLink(client.lien)"></i>
                                    <i class="bi bi-plus-circle-fill action-icon mr-2" style="color: green;" (click)="openModal(linkModal, client)" *ngIf="client.lien==''"></i>
                                    <i class="bi bi-x-circle-fill action-icon" style="color: red;" (click)="deleteLink(client)" *ngIf="client.lien!=''"></i>
                                  </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>

    </div>

    <div class="d-flex justify-content-center">
        <button class="btn btn-success" (click)="update()">
        Enregistrer
      </button>
    </div>

</div>

<input (change)="onFilechange($event)" type="file" style="display: none;" #fileInput>


<ng-template #addRefModal let-modal class="modal fade" role="dialog" style="border:1px solid black">
    <div class="modal-header text-center" style="background-color: whitesmoke;">
        <h5 class="text-center">Nouvelle référence</h5>
    </div>
    <div class="modal-body p-4" style="background-color: whitesmoke;">
        <form #addReferenceForm="ngForm" (ngSubmit)="addReference()">
            <div class="form-group">
                <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Société</label>
                <input type="text" class="form-control form-control-sm" name="societe" placeholder="Société" [(ngModel)]="reference.societe">
            </div>
            <div class="form-group row">
                <div class="col-md-6">
                    <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Nom</label>
                    <input type="text" class="form-control form-control-sm" name="nom" placeholder="Nom" [(ngModel)]="reference.nom" required>
                </div>
                <div class="col-md-6">
                    <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Prénom</label>
                    <input type="text" class="form-control form-control-sm" name="prenom" placeholder="Prénom" [(ngModel)]="reference.prenom">
                </div>
            </div>
            <div class="form-group">
                <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Fonction</label>
                <input type="text" class="form-control form-control-sm" name="fonction" [(ngModel)]="reference.fonction" required>
            </div>
            <div class="form-group">
                <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Téléphone</label>
                <input type="text" class="form-control form-control-sm" name="tel" [(ngModel)]="reference.telephone" required>
            </div>
            <div class="form-group">
                <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Email</label>
                <input type="email" class="form-control form-control-sm" name="email" [(ngModel)]="reference.email" required>
            </div>
            <div class="form-group">
                <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Adresse</label>
                <textarea type="text" class="form-control form-control-sm mt-1" rows="2" name="ad" placeholder="Adresse" [(ngModel)]="reference.adresse"></textarea>
            </div>

            <div class="d-flex justify-content-center mt-4">
                <button type="submit" class="btn btn-primary mr-4" [disabled]="!addReferenceForm.valid">Enregistrer</button>
                <button type="button" class="btn btn-secondary" (click)="cancelReferenceFrom()">Fermer</button>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #listRefModal let-modal class="modal fade" role="dialog" style="border:1px solid black">
    <div class="modal-header text-center" style="background-color: whitesmoke;">
        <h5 class="text-center">Références</h5>
    </div>
    <div class="modal-body p-4" style="background-color: whitesmoke;">
        <table class="table table-striped table-hover">
            <thead>
                <tr class="table-secondary">
                    <th>Nom</th>
                    <th>Prenom</th>
                    <th>Fonction</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of selectedItem.references">
                    <td>{{item.nom}}</td>
                    <td>{{item.prenom}}</td>
                    <td>{{item.fonction}}</td>
                    <td><i class="bi bi-trash-fill text-danger action-icon" (click)="removeReference(item)"></i></td>
                </tr>
            </tbody>
        </table>
        <div class="d-flex justify-content-center mt-4">
            <button type="button" class="btn btn-secondary" (click)="modal.close('click close button')">Fermer</button>
        </div>
    </div>
</ng-template>

<ng-template #linkModal let-modal class="modal fade" role="dialog" style="border:1px solid black">
    <div class="modal-header text-center" style="background-color: whitesmoke;">
        <h5 class="text-center">Ajouter un lien</h5>
    </div>
    <div class="modal-body p-4" style="background-color: whitesmoke;">
        <input type="text" class="form-control form-control-sm" [(ngModel)]="selectedItem.lien">
        <div class="d-flex justify-content-center mt-4">
            <button type="button" class="btn btn-primary mr-4" [disabled]="selectedItem.lien==''" (click)="modal.close('click close button')">Enregistrer</button>
            <button type="button" class="btn btn-secondary" (click)="cancelLink()">Fermer</button>
        </div>
    </div>
</ng-template>