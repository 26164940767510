<div class="d-flex justify-content-center">
    <div id="content" class="container">

        <h1 id="title">Vous souhaitez vous désinscrire de Source Talents</h1>
        <p id="subtitle" class="text-danger text-center">Attention, cette opération est irreversible et supprimera votre compte de notre plate forme. Cette action annulera vos candidatures et vous ne figurerez plus dans notre liste de candidats potentiels.
        </p>

        <div class="w-100 delete-account d-flex justify-content-center">
            <button (click)="openModal(confirmModal)">
              Me désinscrire quand même
            </button>
        </div>

        <ng-template #confirmModal let-modal>
            <div class="modal-body py-4 text-center">
                <span class="big">Attention !!!</span> <br> Cette action effacera toutes vos données et vous ne figurerez plus dans notre base

                <div class="form-check check-option mt-2">
                    <input type="checkbox" class="form-check-input" id="exampleCheck1" (change)="changeVal()">
                    <label class="form-check-label" for="exampleCheck1">Supprimer aussi mes données personnelles</label>
                </div>

                <div class="d-flex justify-content-center mt-4">
                    <button class="btn btn-outline-success mr-5" (click)="modal.close()">
                      Annuler
                    </button>
                    <button class="btn btn-outline-danger" (click)="deleteAccount()">
                      Je confirme
                    </button>
                </div>
            </div>
        </ng-template>


    </div>
</div>