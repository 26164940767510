import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteAccountService } from '../services/delete-account.service';
import { ProgressSpinnerDialogComponent } from '../progress-spinner-dialog/progress-spinner-dialog.component';
import { AlertEmptyComponent } from '../alert-empty/alert-empty.component';

@Component({
  selector: 'app-delete-account',
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.scss']
})
export class DeleteAccountComponent implements OnInit {

  deleteData: boolean = false;

  constructor(
    private modalService : NgbModal,
    private dialog: MatDialog,
    private deleteService: DeleteAccountService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  openModal(content: any) {
    this.modalService.open(content, {centered: true});
  }

  deleteAccount(){
    this.modalService.dismissAll();
    this.Loader();
    this.deleteService.deleteProfile(this.deleteData).subscribe(
      data => this.deleteService.deleteRHData(this.deleteData).subscribe(
        data => this.deleteService.deleteSiteData(this.deleteData).subscribe(
          data => this.deleteService.deleteUser().subscribe(
            data => {
              this.dialog.closeAll();
              this.Alert("Votre compte a été supprimé", true);
            },
            error => {
              this.Alert("Echec de la suppression de votre compte", false);
            }
          ),
          error => {
            this.dialog.closeAll();
            this.Alert("Echec de la suppression de votre compte", false);
          }
        ),
        error => {
          this.dialog.closeAll();
          this.Alert("Echec de la suppression de votre compte", false);
        }
      ),
      error => {
        this.dialog.closeAll();
        this.Alert("Echec de la suppression de votre compte", false);
      }
    )
  }

  changeVal(){
    this.deleteData = !this.deleteData;
  }

  Loader() {
    this.dialog.open(ProgressSpinnerDialogComponent, {
      data: {
        message: `
       Suppression en Cours`,
      },
      disableClose: true,
    });
  }

  Alert(msg, success: boolean) {
    const dialogRef = this.dialog.open(AlertEmptyComponent, {
      data: {
        message: msg,
        buttonText: {
          cancel: "OK",
        },
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if(success){
        this.router.navigate(['/login']);
      }
    });
  }

}
