import { Component, OnInit,AfterViewInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { SidebarService } from './sidebar.service';
import { Router } from '@angular/router';
// import { MenusService } from './menus.service';

import { AuthService } from '../services/auth.service';


declare var $;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  styles: [`
  :host {
      position: fixed,
      top: 0,
      left: 0
  }
`],
  animations: [
    trigger('slide', [
      state('up', style({ height: 0 })),
      state('down', style({ height: '*' })),
      transition('up <=> down', animate(200))
    ])
  ]
})
export class SidebarComponent implements OnInit,AfterViewInit  {
  menus = [];
  constructor(public sidebarservice: SidebarService, public router: Router, public auth : AuthService) {
    this.menus = sidebarservice.getMenuList();
   }

  ngOnInit() {
   
  }
  ngAfterViewInit() {
    if(!this.auth.canModerate()){
      let aj = document.getElementById("Ajout")
      aj.style.color="grey"
      aj.style.pointerEvents="none"
    }

    if(!this.auth.canAdmin()){
      let aj = document.getElementById("Paramètres")
      aj.style.color="#bbbbbb"
      aj.style.pointerEvents="none"
    }

  }

  getSideBarState() {
    return this.sidebarservice.getSidebarState();
  }

  toggle(currentMenu,path: string) {
    this.router.navigate([path]);
    if (currentMenu.type === 'dropdown') {
      this.menus.forEach(element => {
        if (element === currentMenu) {
          currentMenu.active = !currentMenu.active;
        } else {
          element.active = false;
        }
      });
    }
  }

  getState(currentMenu) {

    if (currentMenu.active) {
      return 'down';
    } else {
      return 'up';
    }
  }

  hasBackgroundImage() {
    return this.sidebarservice.hasBackgroundImage;
  }

}
