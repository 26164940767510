import { RHbackEndpoint } from 'src/app/config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

const endpoint  = RHbackEndpoint + "/api/test"
const endpoint2  = RHbackEndpoint + "/api/consultant-test"

@Injectable({
    providedIn: 'root'
})
export class TestService {

    constructor(private http: HttpClient){ }

    getTests(name: string, domain: string, subdomain: string, technology: string, organism: string,
        sortby: string, sortdirection: string, page: number, size: number): Observable<any>{
       return this.http.get(endpoint+"/find", {params: {
           "name": name,
           "domain": domain,
           "subdomain": subdomain,
           "technology": technology,
           "organism": organism,
           "sortby": sortby,
           "sortdirection": sortdirection,
           "page": page.toString(),
           "size": size.toString()
       }});
   }

  addTest(data: any): Observable<any>{
    return this.http.post(endpoint, data);
  }

  updateTest(data: any): Observable<any>{
    return this.http.put(endpoint, data);
  }

  delete(id: string): Observable<any>{
    return this.http.delete(endpoint+"/"+id);
  }


  getConsultantTest(consultantid: string, name: string, domain: string, subdomain: string, technology: string, organism: string, date: string,
    sortby: string, sortdirection: string, page: number, size: number): Observable<any>{
   return this.http.get(endpoint2+"/find", {params: {
       "consultantid": consultantid,
       "name": name,
       "domain": domain,
       "subdomain": subdomain,
       "technology": technology,
       "organism": organism,
       "date": date,
       "sortby": sortby,
       "sortdirection": sortdirection,
       "page": page.toString(),
       "size": size.toString()
   }});
  }

  addConsultantTest(data: any): Observable<any>{
    return this.http.post(endpoint2, data);
  }

  updateConsultantTest(data: any): Observable<any>{
    return this.http.put(endpoint2, data);
  }

  download(path: string): Observable<any>{
    return this.http.get(endpoint2+"/download", {
      headers: new HttpHeaders({'content-Type': 'application/json'}),
      responseType: 'blob',
      params: {"filename": path}
    })
  }

}
