import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { RHbackEndpoint, CRM_BACK_ENDPOINT } from '../config';

const endpoint = RHbackEndpoint+'/api/notification';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(private http: HttpClient){}

  saveRegisterNotification(data:any): Observable<any> {
    return this.http.post(endpoint+"/register-notification", data);
  }

  saveUpdateNotification(data:any): Observable<any> {
    return this.http.post(endpoint+"/update-profile-notification", data);
  }
}
