<div class="container-fluid table-responsive" id="content">
    <div class="container-fluid text-center py-2 total">
        <span class="text-danger total-value">{{totalelements}}</span> formations terminées
    </div>
    <div class="table-container">
        <table class="table table-striped table-hover">
            <thead>
                <tr class="table-secondary">
                    <th scope="col">
                        <input type="text" class="filter-input" placeholder="Intitulé" [(ngModel)]="name" (keyup)="filter()">
                        <i class="bi bi-filter-left ms-2 filter-icon" *ngIf="this.sortby==='' || this.sortby!=='name'" (click)="setSort('name', 'ASC')"></i>
                        <i class="bi bi-sort-down ms-2 filter-icon" *ngIf="sortby==='name' && sortDirection==='DESC'" (click)="setSort('', '')"></i>
                        <i class="bi bi-sort-up ms-2 filter-icon" *ngIf="sortby==='name' && sortDirection==='ASC'" (click)="setSort('name', 'DESC')"></i>
                    </th>
                    <th scope="col">
                        <input type="text" class="filter-input" placeholder="Domaine" [(ngModel)]="domain" (keyup)="filter()">
                        <i class="bi bi-filter-left ms-2 filter-icon" *ngIf="this.sortby==='' || this.sortby!=='domain'" (click)="setSort('domain', 'ASC')"></i>
                        <i class="bi bi-sort-down ms-2 filter-icon" *ngIf="sortby==='domain' && sortDirection==='DESC'" (click)="setSort('', '')"></i>
                        <i class="bi bi-sort-up ms-2 filter-icon" *ngIf="sortby==='domain' && sortDirection==='ASC'" (click)="setSort('domain', 'DESC')"></i>
                    </th>
                    <th scope="col">
                        <input type="text" class="filter-input" placeholder="Sous-domaine" [(ngModel)]="subdomain" (keyup)="filter()">
                        <i class="bi bi-filter-left ms-2 filter-icon" *ngIf="this.sortby==='' || this.sortby!=='subdomain'" (click)="setSort('subdomain', 'ASC')"></i>
                        <i class="bi bi-sort-down ms-2 filter-icon" *ngIf="sortby==='subdomain' && sortDirection==='DESC'" (click)="setSort('', '')"></i>
                        <i class="bi bi-sort-up ms-2 filter-icon" *ngIf="sortby==='subdomain' && sortDirection==='ASC'" (click)="setSort('subdomain', 'DESC')"></i>
                    </th>
                    <th scope="col">
                        <input type="text" class="filter-input" placeholder="Technologie" [(ngModel)]="technology" (keyup)="filter()">
                    </th>
                    <th scope="col">
                        <input type="text" class="filter-input" placeholder="Organisme" [(ngModel)]="organism" (keyup)="filter()">
                        <i class="bi bi-filter-left ms-2 filter-icon" *ngIf="this.sortby==='' || this.sortby!=='organism'" (click)="setSort('organism', 'ASC')"></i>
                        <i class="bi bi-sort-down ms-2 filter-icon" *ngIf="sortby==='organism' && sortDirection==='DESC'" (click)="setSort('', '')"></i>
                        <i class="bi bi-sort-up ms-2 filter-icon" *ngIf="sortby==='organism' && sortDirection==='ASC'" (click)="setSort('organism', 'DESC')"></i>
                    </th>
                    <th scope="col">
                        Url
                    </th>
                    <th scope="col">
                        <input type="text" class="filter-input" placeholder="Date" [(ngModel)]="date" (keyup)="filter()">
                        <i class="bi bi-filter-left ms-2 filter-icon" *ngIf="this.sortby==='' || this.sortby!=='date'" (click)="setSort('date', 'ASC')"></i>
                        <i class="bi bi-sort-down ms-2 filter-icon" *ngIf="sortby==='date' && sortDirection==='DESC'" (click)="setSort('', '')"></i>
                        <i class="bi bi-sort-up ms-2 filter-icon" *ngIf="sortby==='date' && sortDirection==='ASC'" (click)="setSort('date', 'DESC')"></i>
                    </th>
                    <th scope="col">
                        Url résultat
                    </th>
                    <th scope="col">
                        Certificat
                    </th>
                </tr>
            </thead>
            <tbody *ngIf="trainings.length > 0 && !isLoading">
                <tr *ngFor="let training of trainings" (click)="select(training)" [ngClass]="{ 'selected': selected==training}">
                    <td [title]="training.name" [innerHTML]="training.name | highlight:name"></td>
                    <td [innerHTML]="training.domain | highlight:domain"></td>
                    <td [innerHTML]="training.subdomain | highlight:subdomain"></td>
                    <td>{{training.technologies ? training.technologies.toString() : ""}}</td>
                    <td [innerHTML]="training.organism | highlight:organism"></td>
                    <td>
                        <i *ngIf="training.url!=null && training.url!=''" class="bi bi-box-arrow-up-right" (click)="openUrl(training.url)"></i>
                    </td>
                    <td>{{training.date}}</td>
                    <td>
                        <i *ngIf="training.certificateurl!=null && training.certificateurl!=''" class="bi bi-box-arrow-up-right" (click)="openUrl(training.certificateurl)"></i>
                    </td>
                    <td><i *ngIf="training.certificate!=null && training.certificate!=''" class="bi bi-eye-fill" (click)="download(training.certificate)"></i></td>
                </tr>
            </tbody>
        </table>
        <div class="d-flex justify-content-center align-items-center ms-auto" *ngIf="isLoading" style="height: 400px;">
            <div class="spinner-border text-secondary" style="width: 80px; height: 80px;">
                <span class="sr-only me-2" role="status" aria-hidden="true"></span>
            </div>
        </div>
    </div>
    <hr>

    <div class="row d-flex mb-5" *ngIf="pages.length>0">
        <div class="col-md-1 d-flex justify-content-center">

        </div>
        <div class="col-md-6 align-self-center text-center page-info">
            <ul class="nav nav-pills">
                <li class="nav-item">
                    <a class="nav-link" href="" (click)="setPage(0, $event)"><i class="bi bi-chevron-bar-left"></i></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" role="button" href="" (click)="setPage(page-1, $event)"><i class="bi bi-chevron-double-left"></i></a>
                </li>

                <!-- <li class="nav-item" *ngFor="let p of pages; let i=index">
                  <a class="nav-link" *ngIf="i===page || i===page-1 || i===page+1" [ngClass]="{active: i===page}" href="" (click)="setPage(i, $event)">{{i+1}}</a>
              </li> -->
                <div class="mt-2">
                    <input type="number" style="width: 50px; height: 25px;" [(ngModel)]="currentPage" #pageInput (keyup)="changePage()"> / <span>{{pages.length}}</span>
                </div>


                <li class="nav-item">
                    <a class="nav-link" href="" (click)="setPage(page+1, $event)"><i class="bi bi-chevron-double-right"></i></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="" (click)="setPage(pages.length-1, $event)"><i class="bi bi-chevron-bar-right"></i></a>
                </li>
            </ul>
        </div>
        <div class="col-md-3 d-flex justify-content-center">
            <select class="form-select form-select-sm" id="size-select" name="size" #selectSize [ngModel]="size" (change)="setSize(+(selectSize.value))">
              <option value="5">5 elts/page</option>
              <option value="10">10 elts/page</option>
              <option value="15">15 elts/page</option>
              <option value="20">20 elts/page</option>
              <option value="25">25 elts/page</option>
          </select>
        </div>
    </div>
</div>