import { AfterViewInit, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { AuthService } from "../services/auth.service";
import { TokenStorageService } from "../services/token-storage.service";

@Component({
  selector: "app-nav",
  templateUrl: "./nav.component.html",
  styleUrls: ["./nav.component.scss"],
})
export class NavComponent implements OnInit, AfterViewInit {
  constructor(private router: Router, public auth: AuthService) {}
  params = false;
  BesoinFilter: string = "";

  ngOnInit(): void {
    window.scroll(0, 0);
  }
  ngAfterViewInit() {
    if (!this.auth.canModerate()) {
      let aj = document.getElementById("Ajout");
      // aj.style.color = "grey";
      // aj.style.pointerEvents = "none";
    }

    if (!this.auth.canAdmin()) {
      this.params = true;
      let aj = document.getElementById("Paramètres");
      aj.style.color = "#bbbbbb";
      aj.style.pointerEvents = "none";
    }
  }
  openRoute(param) {
    //console.log(param, "iohohiohohiohohohohohiohiho");
  }
  RechercheBesoin() {
    if (this.BesoinFilter === "") {
      this.router.navigate(["/RechercheBesoin"]);
    } else {
      this.router.navigate(["/RechercheBesoin"]);

      this.router.navigate(["/RechercheBesoin", this.BesoinFilter]);

    }
  }

  isFacturer():boolean {
    return (this.auth.getRole() == "Facturation")
  }

  changeRoute(param) {
    if(!this.params){
      if (param === "null") {
        param = this.router.url;
        //this.router.navigate([param]);
      } else {
        this.router.navigate(["/" + param]);
      }
      this.ngOnInit();
    }
  }
}
