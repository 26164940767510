import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { TestService } from 'src/app/services/consultants/test.service';

@Component({
  selector: 'app-consultant-test',
  templateUrl: './consultant-test.component.html',
  styleUrls: ['./consultant-test.component.scss']
})
export class ConsultantTestComponent {

  constructor(private modalService: NgbModal, private testService : TestService, private authService: AuthService) {
  }

  addspin: boolean = false;
  deletespin: boolean = false;
  adderror = null;

  selected: any = null;

  page: number=0;
  currentPage: number = 1;
  size: number=10;
  sortDirection: string = 'DESC'
  sortby: string = "name";
  pages: number[] = [0,1];
  totalelements: number = 0;

  name: string = "";
  domain: string = "";
  subdomain: string = "";
  technology: string = "";
  organism: string = "";
  @Input() consultantid: string = "";
  date: string = "";

  tests: any[] = [];
  isLoading: boolean = true;

  technos: any[] = [];

  isAdmin: boolean = false;

  ngOnInit(): void {
    this.getTests();
    this.isAdmin = this.authService.canAdmin();
  }

  change(){
    this.getTests();
  }

  setPage(i: number, event: Event) {
    event.preventDefault();
    if(i>=0 && i<this.pages.length){
      this.page = i;
      this.currentPage = this.page+1;
      this.getTests();
    }
  }

  changePage(){
    if(this.currentPage>0){
      this.page = this.currentPage - 1;
      this.getTests();
    }
  }

  setSize(i: number) {
    this.size = i;
    this.getTests();
  }

  setSort(sortby: string, sortDirection: string) {
    this.sortby = sortby;
    this.sortDirection = sortDirection;
    this.getTests();
  }


  getTests(): void{
    this.isLoading = true;
    this.testService.getConsultantTest(this.consultantid, this.name, this.domain, this.subdomain, this.technology,
       this.organism, this.date, this.sortby,this.sortDirection, this.page, this.size).subscribe(
      data => {
        this.tests = [...data.content];
        this.pages = new Array(data.totalPages);
        this.totalelements = data.numberOfElements;
        this.isLoading = false;
      },
      error => {
        this.isLoading = false
      }
    );
  }

  filter() {
    this.page = 0;
    this.currentPage = 1;
    this.getTests();
  }


  openModal(content: any, data: any){
    this.selected = {...data};
    this.adderror = null;
    this.modalService.open(content, {centered: true});
  }

  select(domain:any){
    this.selected != domain ? this.selected = domain : this.selected = null;
  }

  download(path: string){
    this.testService.download(path).subscribe(
      blob => {
        let fileURL = window.URL.createObjectURL(blob);
        window.open(fileURL);
      },
      error => console.log(error)
    );
  }

  openUrl(url: string){
    window.open("//" +url, "_blank");
  }

  selectcolor(test){
    switch (test.managerdecision) {
      case "BON":
        return "green"
        break;
      case "MAUVAIS":
        return "red"
        break;
      default:
        return "white";
        break;
    }
  }

  updateTest(test){
    this.testService.updateConsultantTest(test).subscribe(
      (data) => {
        this.getTests();
      }
    )
  }

}
