<div class="d-flex justify-content-center">
    <img src="assets/RH.png" height="40" alt="">
</div>
<hr style="border: 1px solid black">

<mat-dialog-content>
    <p style="font-size: 20px;width: 350px;word-wrap: break-word;"> {{message}}</p>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button mdbBtn style="background-color: #BBE2BE; color:black;position:relative;font-size: 15px;font-weight: bold" mat-dialog-close tabindex="-1">{{cancelButtonText}}</button>
</mat-dialog-actions>