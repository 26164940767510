<div class="container-fluid mt-5" style="height: 90vh">
    <ul ngbNav #nav="ngbNav" class="nav-tabs">
        <li ngbNavItem>
            <a ngbNavLink>Formations que nous vous proposons</a>
            <ng-template ngbNavContent>
                <app-list-training [category]="category" [value]="name" [consultantid]="consultantid"></app-list-training>
            </ng-template>
        </li>
        <li ngbNavItem>
            <a ngbNavLink>Mes formations</a>
            <ng-template ngbNavContent>
                <app-consultant-training [consultantid]="consultantitd"></app-consultant-training>
            </ng-template>
        </li>
    </ul>

    <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>