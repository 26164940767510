<div class="container-fluid mt-5" style="height: 90vh">
    <ul ngbNav #nav="ngbNav" class="nav-tabs">
        <li ngbNavItem>
            <a ngbNavLink>Tests adaptés pour vous</a>
            <ng-template ngbNavContent>
                <app-list-test [category]="category" [value]="name" [consultantid]="consultantid"></app-list-test>
            </ng-template>
        </li>
        <li ngbNavItem>
            <a ngbNavLink>Mes tests réalisés</a>
            <ng-template ngbNavContent>
                <app-consultant-test [consultantid]="consultantid"></app-consultant-test>
            </ng-template>
        </li>
    </ul>

    <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>