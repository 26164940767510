import { ProgressSpinnerDialogComponent } from '../../progress-spinner-dialog/progress-spinner-dialog.component';
import { CraService } from '../../services/cra.service';
import { Component, OnInit } from '@angular/core';
import * as fileSaver from 'file-saver';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { TokenStorageService } from '../../services/token-storage.service';
import { AuthService } from '../../services/auth.service';
import { ClientServiceService } from '../../services/client-service.service';

function delay(ms: number) {
  return new Promise( resolve => setTimeout(resolve, ms) );
}

@Component({
  selector: 'app-cra-consultant',
  templateUrl: './cra-consultant.component.html',
  styleUrls: ['./cra-consultant.component.scss']
})
export class CraConsultantComponent implements OnInit {

  constructor(private craService: CraService, private tokenStorageService: TokenStorageService, private router: Router,
    private dialog: MatDialog, public authService: AuthService, private clientService: ClientServiceService) { }

  craconsultant: any = {
    "id": null,
    "mois": "",
    "cient": "",
    "contrat": "",
    "annee": ""
  };
  file: any;
  file2: any;
  contrats: any[] = []
  contrat: string = "";
  options: string[] = ["Travaillé", "Astreinte", "Congé payé", "RTT", "Congé sans solde", "Maladie", "WE", "Férié"];
  dialogRef: any;
  signataire: any;
  client: any;
  feries = {};

  ngOnInit(): void {

    this.craService.getJoursFeries().subscribe(
      data => this.feries = data
    )

    this.craService.getMissions().subscribe(
      (data) => {
        this.contrats = [...data];
      },
      (error) => {
        console.log(error)
      }
    )

  }

  setSignataire(){
    this.craconsultant.signataire = {
      id: this.signataire.id,
      nom: this.signataire.nom,
      prenom: this.signataire.prenom
    }
  }

  calcul(){
    let jt=0;
    let cp =0;
    let rtt = 0;
    let css = 0;
    let maladie = 0;
    this.craconsultant.days.forEach(day => {
      if(day.matin == "Travaillé"){
        jt += 0.5;
      }
      if(day.apresmidi == "Travaillé"){
        jt += 0.5;
      }
      if(day.matin == "Congé payé"){
        cp += 0.5;
      }
      if(day.apresmidi == "Congé payé"){
        cp += 0.5;
      }
      if(day.matin == "RTT"){
        rtt += 0.5;
      }
      if(day.apresmidi == "RTT"){
        rtt += 0.5;
      }
      if(day.matin == "Congé sans solde"){
        css += 0.5;
      }
      if(day.apresmidi == "Congé sans solde"){
        css += 0.5;
      }
      if(day.matin == "Maladie"){
        maladie += 0.5;
      }
      if(day.apresmidi == "Maladie"){
        maladie += 0.5;
      }
    });
    this.craconsultant.totaltravail = jt;
    this.craconsultant.totalcp = cp;
    this.craconsultant.totalrtt = rtt;
    this.craconsultant.totalcss = css;
    this.craconsultant.totalmaladie = maladie;
  }

  calculAstreintes(){
    let total = 0;
    this.craconsultant.days.forEach(day => {
      total += day.astreinte;
    });
    this.craconsultant.totalastreintes = total;
  }

  updateCra(){
    if(this.craconsultant.id != null) {
      this.spin("Sauvegarge du CRA en cours...");
      this.craService.updateCra(this.craconsultant).subscribe(
        (data) => {
          this.craconsultant = data;
          this.dialogRef.close()
        },
        (error) => {
          console.log(error)
          this.dialogRef.close()
        }
      )
    }
  }

  getColor(day: any): string {
    let date = new Date(day.date);
    if (date.getDay() == 6 || date.getDay() == 0) {
      return 'we';
    }
    if(day.date in this.feries){
      return 'ferie';
    }
    return '';
  }

  getCra(){
    this.spin("Chargement du CRA...");
    this.craService.getCra(this.contrat).subscribe(
      (data) => {
        this.craconsultant = data;
        this.signataire = data.signataire;
        this.calcul();
        this.clientService.FindById(data.idclient).subscribe(
          (data) => {
            this.client = data
            console.log("client", data);
          }
        )
        this.dialogRef.close()
      },
      (error) => {
        console.log(error)
        this.dialogRef.close()
      }
    )
  }

  onFilechange(event: any){
    this.file = event.target.files[0];
    this.uploadFileCra();
  }

  uploadFileCra(){
    const formData: FormData = new FormData();
    formData.append('file', this.file);
    formData.append('idcra', this.craconsultant.id);
    this.craService.addCraFile(formData).subscribe(
      (data) => {
        this.craconsultant.crafile = data.crafile;
      }
    )
  }

  onFilechange2(event: any){
    this.file2 = event.target.files[0];
    this.uploadFacture();
  }

  uploadFacture(){
    const formData: FormData = new FormData();
    formData.append('file', this.file2);
    formData.append('idcra', this.craconsultant.id);
    this.craService.addCraFacture(formData).subscribe(
      (data) => {
        this.craconsultant.crafacture = data.crafacture;
      }
    )
  }

  downloadFile(){
    this.craService.downloadCra(this.craconsultant.crafile).subscribe(
      blob => {
        this.saveFile(blob, this.craconsultant.crafile.slice(this.craconsultant.crafile.lastIndexOf("/")+1));
      },
      error => console.log(error)
    )
  }

  downloadFacture(){
    this.craService.downloadCra(this.craconsultant.crafacture).subscribe(
      blob => {
        this.saveFile(blob, this.craconsultant.crafile.slice(this.craconsultant.crafile.lastIndexOf("/")+1));
      },
      error => console.log(error)
    )
  }

  saveFile(data: any, filename?: string) {
    const blob = new Blob([data]);
    fileSaver.saveAs(blob, filename);
  }

  deleteFile(){
    this.craService.deleteCra(this.craconsultant.crafile, this.craconsultant.id).subscribe(
      (data) => {
        this.craconsultant.crafile = "";
      },
     (error) => {
      console.log(error)
     }
    )
  }

  deleteFacture(){
    this.craService.deleteFacture(this.craconsultant.crafacture, this.craconsultant.id).subscribe(
      (data) => {
        this.craconsultant.crafacture = "";
      },
     (error) => {
      console.log(error)
     }
    )
  }

  logout() {
    this.tokenStorageService.signOut();
    window.location.reload();
    (async () => {
      this.spin("Déconnexion...");
      await delay(2000);
      this.authService.setauth(false)
      this.dialogRef.close();
      this.router.navigate(["/login"]);

    })();
  }

  spin(message: string) {
    this.dialogRef = this.dialog.open(ProgressSpinnerDialogComponent, {
      data: {
        message: message
      },
    });
  }

}
