import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ProgressSpinnerDialogComponent } from '../progress-spinner-dialog/progress-spinner-dialog.component';
import { MatTableDataSource, MatPaginator, MatDialog } from '@angular/material';

import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { TokenStorageService } from '../services/token-storage.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  form: any = {};
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';
  roles: string[] = [];
  isLoginFailed2 = false;
  isPasswordFailed = false;
  username: string;
  password: string;
  registerForm: FormGroup;
  submitted = false;
  modified : any;

  constructor(public dialog: MatDialog,private authService: AuthService,  private formBuilder: FormBuilder, private tokenStorage: TokenStorageService,private route:Router) { }

  ngOnInit() {
    if (this.tokenStorage.getToken()) {
      this.isLoggedIn = true;
      this.roles = this.tokenStorage.getUser().roles;
    }
    this.registerForm = this.formBuilder.group({


      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]

  });
    this.modified = sessionStorage.getItem("modifiedEmail");
  }
  get f() { return this.registerForm.controls; }

  onSubmit() {
    this.loader()
    this.form.email = this.username
    this.form.password = this.password

    this.authService.login(this.registerForm.value).subscribe(
      data => {
        if(data.roles.includes("Consultant")){
          this.tokenStorage.saveToken(data.accessToken);
          this.tokenStorage.saveUser(data);

          this.isLoginFailed = false;
          this.isLoggedIn = true;
          this.dialog.closeAll();
          this.roles = this.tokenStorage.getUser().roles;
          this.authService.emitAuthentication(true);
          this.authService.setauth(true)
          this.route.navigate(['/consultant/informations']);
        }  else {
          this.dialog.closeAll()

          this.errorMessage = "Echec d'authentification";
          this.isLoginFailed = true;
        }

      },
      err => {
        this.dialog.closeAll()

        this.errorMessage = err.error.message;
        this.isLoginFailed = true;
      }
    );
  }

  goToRegister(){
    this.route.navigateByUrl("/register");
  }


  loader(){
    this.dialog.open(ProgressSpinnerDialogComponent, {
      data: {

        message: `
         Chargement en cours`

      },
      disableClose: true,
    });
  }
  reloadPage() {
    console.log(this.isLoggedIn)
    if(this.isLoggedIn){
      this.authService.setauth(true)
      this.route.navigate(['/home']);
    }
  }
}
