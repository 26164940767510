<div class="container-fluid table-responsive" id="content">
    <div class="container-fluid text-center py-2 total">
        <span class="text-danger total-value">{{totalelements}}</span> formations
    </div>
    <div class="table-container">
        <table class="table table-striped table-hover">
            <thead>
                <tr class="table-secondary">
                    <th scope="col">
                        <input type="text" class="filter-input" placeholder="Intitulé" [(ngModel)]="name" (keyup)="filter()">
                        <i class="bi bi-filter-left ml-2 filter-icon" *ngIf="this.sortby==='' || this.sortby!=='name'" (click)="setSort('name', 'ASC')"></i>
                        <i class="bi bi-sort-down ml-2 filter-icon" *ngIf="sortby==='name' && sortDirection==='DESC'" (click)="setSort('', '')"></i>
                        <i class="bi bi-sort-up ml-2 filter-icon" *ngIf="sortby==='name' && sortDirection==='ASC'" (click)="setSort('name', 'DESC')"></i>
                    </th>
                    <th scope="col">
                        <input type="text" class="filter-input" placeholder="Domaine" [(ngModel)]="domain" (keyup)="filter()">
                        <i class="bi bi-filter-left ml-2 filter-icon" *ngIf="this.sortby==='' || this.sortby!=='domain'" (click)="setSort('domain', 'ASC')"></i>
                        <i class="bi bi-sort-down ml-2 filter-icon" *ngIf="sortby==='domain' && sortDirection==='DESC'" (click)="setSort('', '')"></i>
                        <i class="bi bi-sort-up ml-2 filter-icon" *ngIf="sortby==='domain' && sortDirection==='ASC'" (click)="setSort('domain', 'DESC')"></i>
                    </th>
                    <th scope="col">
                        <input type="text" class="filter-input" placeholder="Sous-domaine" [(ngModel)]="subdomain" (keyup)="filter()">
                        <i class="bi bi-filter-left ml-2 filter-icon" *ngIf="this.sortby==='' || this.sortby!=='subdomain'" (click)="setSort('subdomain', 'ASC')"></i>
                        <i class="bi bi-sort-down ml-2 filter-icon" *ngIf="sortby==='subdomain' && sortDirection==='DESC'" (click)="setSort('', '')"></i>
                        <i class="bi bi-sort-up ml-2 filter-icon" *ngIf="sortby==='subdomain' && sortDirection==='ASC'" (click)="setSort('subdomain', 'DESC')"></i>
                    </th>
                    <th scope="col">
                        <input type="text" class="filter-input" placeholder="Technologie" [(ngModel)]="technology" (keyup)="filter()">
                    </th>
                    <th scope="col">
                        <input type="text" class="filter-input" placeholder="Organisme" [(ngModel)]="organism" (keyup)="filter()">
                        <i class="bi bi-filter-left ml-2 filter-icon" *ngIf="this.sortby==='' || this.sortby!=='organism'" (click)="setSort('organism', 'ASC')"></i>
                        <i class="bi bi-sort-down ml-2 filter-icon" *ngIf="sortby==='organism' && sortDirection==='DESC'" (click)="setSort('', '')"></i>
                        <i class="bi bi-sort-up ml-2 filter-icon" *ngIf="sortby==='organism' && sortDirection==='ASC'" (click)="setSort('organism', 'DESC')"></i>
                    </th>
                    <th scope="col">
                        Url
                    </th>
                    <th scope="col">
                        Type
                    </th>
                    <th scope="col">
                        Durée
                    </th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody *ngIf="trainings.length > 0 && !isLoading">
                <tr *ngFor="let training of trainings" (click)="select(training)" [ngClass]="{ 'selected': selected==training}">
                    <td [title]="training.name" [innerHTML]="training.name | highlight:name"></td>
                    <td [innerHTML]="training.domain | highlight:domain"></td>
                    <td [innerHTML]="training.subdomain | highlight:subdomain"></td>
                    <td>{{training.technologies ? training.technologies.toString() : ""}}</td>
                    <td [innerHTML]="training.organism | highlight:organism"></td>
                    <td>
                        <i *ngIf="training.url!=null && training.url!=''" class="bi bi-box-arrow-up-right" (click)="openUrl(training.url)"></i>
                    </td>
                    <td>{{training.type}}</td>
                    <td>{{training.duration}}</td>
                    <td>
                        <i class="bi bi-pencil-fill mr-3" (click)="openModal(updateModal, training)"></i>
                        <i class="bi bi-trash-fill" (click)="openModal(deleteModal, training)"></i>
                    </td>
                </tr>
            </tbody>
            <tr>
                <td>
                    <input [(ngModel)]="tname" placeholder="Intitulé" type="text" class="form-control form-control-sm">
                </td>
                <td>
                    <select [(ngModel)]="tdomain" class="form-select form-select-sm" (change)="getSubdomains()">
                      <option value="" selected>Domaine</option>
                      <option *ngFor="let domain of domains" [value]="domain.name">{{domain.name}}</option>
                  </select>
                </td>
                <td>
                    <select [(ngModel)]="tsubdomain" class="form-select form-select-sm" (change)="getTechnologies()">
                      <option value="" selected>Sous-domaine</option>
                      <option *ngFor="let subdomain of subdomains" [value]="subdomain.name">{{subdomain.name}}</option>
                  </select>
                </td>
                <td>
                    <ng-select [items]="technologies" placeholder="Technologies" bindLabel="name" bindValue="name" [multiple]=true [(ngModel)]="ttechnos">
                    </ng-select>
                </td>
                <td>
                    <input [(ngModel)]="torganism" placeholder="Organisme" type="text" class="form-control form-control-sm">
                </td>
                <td>
                    <input [(ngModel)]="turl" placeholder="Url" type="text" class="form-control form-control-sm">
                </td>
                <td>
                    <select [(ngModel)]="ttype" class="form-select form-select-sm">
                      <option value="FORMATION">Formation</option>
                      <option value="CERTIFICATION">Certification</option>
                  </select>
                </td>
                <td>
                    <input [(ngModel)]="tduration" placeholder="Durée" type="text" class="form-control form-control-sm">
                </td>
                <td style="text-align: left;">
                    <button class="btn btn-sm btn-success ml-2" (click)="addTraining()" style="background-color: green;">
                      <i class="bi bi-plus" *ngIf="!addspin"></i>
                      <span class="spinner-border spinner-border-sm mr-1 mt-1" role="status" aria-hidden="true" *ngIf="addspin"></span>
                  </button>
                </td>
            </tr>
        </table>
        <div class="d-flex justify-content-center align-iteml-center ml-auto" *ngIf="isLoading" style="height: 400px;">
            <div class="spinner-border text-secondary" style="width: 80px; height: 80px;">
                <span class="sr-only mr-2" role="status" aria-hidden="true"></span>
            </div>
        </div>
    </div>
    <hr>

    <div class="row d-flex mb-5" *ngIf="pages.length>0">
        <div class="col-md-1 d-flex justify-content-center">

        </div>
        <div class="col-md-6 align-self-center text-center page-info">
            <ul class="nav nav-pills">
                <li class="nav-item">
                    <a class="nav-link" href="" (click)="setPage(0, $event)"><i class="bi bi-chevron-bar-left"></i></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" role="button" href="" (click)="setPage(page-1, $event)"><i class="bi bi-chevron-double-left"></i></a>
                </li>

                <!-- <li class="nav-item" *ngFor="let p of pages; let i=index">
                  <a class="nav-link" *ngIf="i===page || i===page-1 || i===page+1" [ngClass]="{active: i===page}" href="" (click)="setPage(i, $event)">{{i+1}}</a>
              </li> -->
                <div class="mt-2">
                    <input type="number" style="width: 50px; height: 25px;" [(ngModel)]="currentPage" #pageInput (keyup)="changePage()"> / <span>{{pages.length}}</span>
                </div>


                <li class="nav-item">
                    <a class="nav-link" href="" (click)="setPage(page+1, $event)"><i class="bi bi-chevron-double-right"></i></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="" (click)="setPage(pages.length-1, $event)"><i class="bi bi-chevron-bar-right"></i></a>
                </li>
            </ul>
        </div>
        <div class="col-md-3 d-flex justify-content-center">
            <select class="form-select form-select-sm" id="size-select" name="size" #selectSize [ngModel]="size" (change)="setSize(+(selectSize.value))">
              <option value="5">5 elts/page</option>
              <option value="10">10 elts/page</option>
              <option value="15">15 elts/page</option>
              <option value="20">20 elts/page</option>
              <option value="25">25 elts/page</option>
          </select>
        </div>
    </div>
</div>

<ng-template #updateModal let-modal>
    <div class="modal-title text-center fw-bold p-3">Modification de la formation</div>
    <div class="modal-body p-3">
        <div class="alert alert-danger error-section" *ngIf="adderror">{{adderror}}</div>
        <div class="form-group mb-1">
            <label>Nom</label>
            <input type="text" class="form-control form-control-sm" [(ngModel)]="selected.name">
        </div>
        <div class="form-group mb-1">
            <label>Domaine</label>
            <select [(ngModel)]="selected.domain" class="form-select form-select-sm" (change)="getSubdomains()">
              <option *ngFor="let domain of domains" [value]="domain.name">{{domain.name}}</option>
          </select>
        </div>
        <div class="form-group mb-1">
            <label>Sous-domaine</label>
            <select [(ngModel)]="selected.subdomain" class="form-select form-select-sm" (change)="getTechnologies()">
              <option *ngFor="let subdomain of subdomains" [value]="subdomain.name">{{subdomain.name}}</option>
          </select>
        </div>
        <div class="form-group mb-1">
            <label>Technologies</label>
            <ng-select [items]="technologies" bindLabel="name" bindValue="name" [multiple]=true [(ngModel)]="selected.technologies">
            </ng-select>
        </div>
        <div class="form-group mb-1">
            <label>Organisme</label>
            <input type="text" class="form-control form-control-sm" [(ngModel)]="selected.organism">
        </div>
        <div class="form-group mb-1">
            <label>Url</label>
            <input type="text" class="form-control form-control-sm" [(ngModel)]="selected.url">
        </div>
        <div class="form-group mb-1">
            <label>Type</label>
            <select [(ngModel)]="selected.type" class="form-select form-select-sm">
              <option value="FORMATION">Formation</option>
              <option value="CERTIFICATION">Certification</option>
          </select>
        </div>
        <div class="form-group mb-1">
            <label>Durée</label>
            <input type="text" class="form-control form-control-sm" [(ngModel)]="selected.duration">
        </div>
        <div class="d-flex justify-content-around mt-2">
            <button class="btn btn-primary" (click)="update()">
              <span class="spinner-border spinner-border-sm mr-1 mt-1" role="status" aria-hidden="true" *ngIf="addspin"></span>
              Enregistrer
          </button>
            <button class="btn btn-secondary" (click)="modal.close()"> Annuler </button>
        </div>
    </div>
</ng-template>

<ng-template #deleteModal let-modal>
    <div class="modal-body p-3">
        <p class="text-center mb-3">Voulez-vous vraiment supprimer l'élément ?</p>
        <div class="d-flex justify-content-around mt-2">
            <button class="btn btn-danger" (click)="delete()">
              <span class="spinner-border spinner-border-sm mr-1 mt-1" role="status" aria-hidden="true" *ngIf="deletespin"></span>
              Supprimer
          </button>
            <button class="btn btn-secondary" (click)="modal.close()"> Annuler </button>
        </div>
    </div>
</ng-template>