import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { RHbackEndpoint } from '../config';

const endpoint =  RHbackEndpoint+'/technologie';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class TechnologieService {

  constructor(private http: HttpClient) { }

  private extractData(res: Response) {
    const body = res;
    return body || { };
  }

  getTechnologie(): Observable<any> {
    return this.http.get(endpoint + '/mod/all').pipe(
      map(this.extractData));
  }

  addTechno(libelle: String): Observable<any> {
    return this.http.post<any>(endpoint + '/mod/add', libelle);
  }

  delTechno(libelle: String): Observable<any> {
    return this.http.delete<any>(endpoint + `/admin/delete/${libelle}`);
  }

  updateTechno(id:String,libelle: String): Observable<any> {
    return this.http.put<any>(endpoint + `/admin/update/${id}`, libelle);
  }

  findByLibelle(libelle: String): Observable<any> {
    return this.http.get(endpoint + `/admin/find/${libelle}`);
  }

  Technologies(request) {
    const params = request;
    return this.http.get(endpoint, { params });
  }

}
