import { SkillService } from './../../../services/consultants/skill.service';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4plugins_forceDirected from "@amcharts/amcharts4/plugins/forceDirected";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { ConsultantProfileService } from 'src/app/services/consultants/consultant-profile.service';

@Component({
  selector: 'app-cartographie',
  templateUrl: './cartographie.component.html',
  styleUrls: ['./cartographie.component.scss']
})
export class CartographieComponent implements OnInit, AfterViewInit {

  title = 'test';

  skills: any;
  skillsToDevelop: any;
  consultantid: string = "";
  constructor(private router: Router, private skillService: SkillService, private profileService: ConsultantProfileService) { }

  ngAfterViewInit(): void {
    this.consultantid = this.profileService.getConsultantid();
    this.skillService.getSkills(this.consultantid).subscribe(
      (data: any) => {
        this.skills = JSON.parse(data.actual);
        this.skillsToDevelop = JSON.parse(data.mayDevelop);
        this.displayGraph1();
        this.displayGraph2();
      },
    )

  }

  ngOnInit(): void {

  }

  displayGraph1(){
    am4core.useTheme(am4themes_animated);

    var chart = am4core.create("skillsChart", am4plugins_forceDirected.ForceDirectedTree);
    chart.legend = new am4charts.Legend();
    if (chart.logo) {
      chart.logo.disabled = true;
    }

    var networkSeries = chart.series.push(new am4plugins_forceDirected.ForceDirectedSeries());
    networkSeries.data = this.skills;
    console.log(this.skills)

    networkSeries.dataFields.name = "name";
    networkSeries.dataFields.id = "name";
    networkSeries.dataFields.category = "type";
    networkSeries.dataFields.value = "value";
    networkSeries.dataFields.children = "children";
    networkSeries.links.template.strokeWidth = 2;
    networkSeries.links.template.strokeOpacity = 1;


    networkSeries.nodes.template.events.on("doublehit", (event: any) => {
      // console.log("double click", event.target.label.dataItem.category)
      this.router.navigate(
        ['consultant/test-page', event.target.label.dataItem.category, event.target.label.dataItem.name]
      );
    })

    networkSeries.nodes.template.tooltipText = "{name}";
    networkSeries.nodes.template.fillOpacity = 1;

    networkSeries.nodes.template.outerCircle.__disabled;

    networkSeries.maxRadius = am4core.percent(6);
    networkSeries.manyBodyStrength = -16;
    networkSeries.nodes.template.label.hideOversized = true;
    networkSeries.nodes.template.label.truncate = true;


    // Start collapsed
    networkSeries.maxLevels = 3;

    // Expand single level only
    networkSeries.nodes.template.expandAll = false;
  }

  displayGraph2(){
    am4core.useTheme(am4themes_animated);

    var chart = am4core.create("skillsToDevChart", am4plugins_forceDirected.ForceDirectedTree);
    chart.legend = new am4charts.Legend();
    if (chart.logo) {
      chart.logo.disabled = true;
    }

    var networkSeries = chart.series.push(new am4plugins_forceDirected.ForceDirectedSeries());
    networkSeries.data = this.skillsToDevelop;

    networkSeries.dataFields.linkWith = "linkWith";
    networkSeries.dataFields.name = "name";
    networkSeries.dataFields.category = "type";
    networkSeries.dataFields.id = "name";
    networkSeries.dataFields.value = "value";
    networkSeries.dataFields.children = "children";
    networkSeries.links.template.strokeWidth = 2;
    networkSeries.links.template.strokeOpacity = 1;


    networkSeries.nodes.template.events.on("doublehit", (event: any) => {
      // console.log("double click", event.target.label.dataItem.category)
      this.router.navigate(
        ['consultant/training-page', event.target.label.dataItem.category, event.target.label.dataItem.name]
      );
    })

    networkSeries.nodes.template.tooltipText = "{name}";
    networkSeries.nodes.template.fillOpacity = 1;

    networkSeries.nodes.template.outerCircle.__disabled;

    networkSeries.maxRadius = am4core.percent(6);
    networkSeries.manyBodyStrength = -16;
    networkSeries.nodes.template.label.hideOversized = true;
    networkSeries.nodes.template.label.truncate = true;


    // Start collapsed
    networkSeries.maxLevels = 3;

    // Expand single level only
    networkSeries.nodes.template.expandAll = false;
  }

}
