import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { RHbackEndpoint, CRM_BACK_ENDPOINT } from '../config'

const endpoint =  RHbackEndpoint+'/clients';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(private http: HttpClient) { }

  private extractData(res: Response) {
    const body = res;
    return body || { };
  }

  getClients(): Observable<any> {
    return this.http.get(endpoint + '/mod/all').pipe(
      map(this.extractData));
  }

  getAllClients(): Observable<any> {
    return this.http.get(CRM_BACK_ENDPOINT+"/client/findall");
  }

  calculate(): Observable<any> {
    return this.http.get(endpoint + '/calculate').pipe(
      map(this.extractData));
  }

  addClient(libelle: String): Observable<any> {

    return this.http.post<any>(endpoint + '/mod/add', libelle.toUpperCase());
  }

  delClient(libelle: String): Observable<any> {
    return this.http.delete<any>(endpoint + `/admin/delete/${libelle}`);
  }

  updateClient(id:String,libelle: String): Observable<any> {
    return this.http.put<any>(endpoint + `/admin/update/${id}`, libelle.toUpperCase());
  }

  findByLibelle(libelle: String): Observable<any> {
    return this.http.get(endpoint + `/admin/find/${libelle}`);
  }

  Clients(request) {

    const params = request;
    return this.http.get(endpoint, { params });
  }
  updateClients(newClient, id,libelle) {
    let params = new HttpParams();
    params = params.append('id', id);
	 params = params.append('libelle', libelle.toUpperCase());
    return this.http.put<any>(endpoint + '/admin/updated', newClient, {params});
  }

}
