<div class="d-flex justify-content-center">
    <div id="content">

        <h1 id="title">Mot de passe oublié</h1>
        <p id="subtitle">Enter votre adresse mail et nous vous enverons un lien pour réinitialiser votre mot de passe</p>

        <form #requestForm="ngForm" [formGroup]="form" (ngSubmit)="requestPassword()">
            <div class="alert alert-danger error-section" *ngIf="error">{{error}}</div>
            <div class="form-group">
                <label>Adresse mail</label>
                <input formControlName="email" type="email" class="form-control" [class.status-error]="form.controls.email.invalid && form.controls.email.touched" [class.status-success]="form.controls.email.valid && form.controls.email.touched" placeholder="Email address">
                <div *ngIf="(form.controls.email.invalid && form.controls.email.touched) || form.controls.email.dirty">
                    <small *ngIf="form.controls.email.errors?.required" class="text-danger">Vous devez entrer votre adresse mail!</small>
                    <small *ngIf="form.controls.email.errors?.email" class="text-danger">Entrer une adresse mail valide s'il vous plaît.</small>
                </div>
            </div>
            <div class="d-flex justify-content-center">
                <button class="btn btn-primary" type="submit" [disabled]="!requestForm.valid" [ngClass]="{'btn-disabled': !requestForm.valid}">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="spin"></span>
              Envoyer
          </button>
            </div>

            <div *ngIf="!(data === '')" class="resend">
                {{data}}
            </div>
        </form>
        <div class="another-action d-flex justify-content-between">
            <a [routerLink]="['../login']">connexion</a>
            <a [routerLink]="['../register']">Inscription</a>
        </div>


    </div>
</div>
