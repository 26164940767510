<div class="container-flud" style="height: 90vh">
    <div class="row">
        <div class="col-md-6">
            <div style="height: 400px;" class="text-center">
                <h2>Mes compétences actuelles</h2>
                <div id="skillsChart" style="width: 100%; height: 90%"></div>
            </div>
        </div>
        <div class="col-md-6">
            <div style="height: 400px;" class="text-center">
                <h2>perspectives d'apprentissage</h2>
                <div id="skillsToDevChart" style="width: 100%; height: 90%"></div>
            </div>
        </div>
    </div>
</div>