<div class="container-fluid">
    <div class="row">
        <div class="col-lg-6 p-2">
            <app-configs-domain></app-configs-domain>
        </div>
        <div class="col-lg-6 p-2">
            <app-configs-subdomain></app-configs-subdomain>
        </div>
        <div class="col-lg-6 p-2">
            <app-configs-techno></app-configs-techno>
        </div>
        <div class="col-12">
            <app-configs-training></app-configs-training>
        </div>
        <div class="col-12">
            <app-configs-tests></app-configs-tests>
        </div>
    </div>
</div>