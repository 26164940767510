<div class="container mt-5 table-responsibe" id="container">

    <div class="my-4 row">
        <div style="font-size: 22px; font-weight: bold;" class="ml-3 mr-2">Sélectionner un contrat </div>
        <select name="contrat" class="form-select" style="width: 300px;" id="" [(ngModel)]="contrat" (change)="getCra()">
          <option [value]="con.contrat" *ngFor="let con of contrats">{{con.contrat}}</option>
        </select>
    </div>

    <div class="w-100 text-center py-1" style="font-size: 20px; background-color: #EFEEEE; font-weight: 700;">
        CRA <span class="text-info">{{craconsultant.mois+" "+craconsultant.annee}}</span>
        <div class="px-2 row mt-2">
            <div class="col-md-6">
                Client <span class="text-info">{{craconsultant.client}}</span>
            </div>
            <div class="col-md-6">
                Contrat <span class="text-info">{{craconsultant.contrat}}</span>
            </div>
        </div>
    </div>
    <table class="table table-striped">
        <thead>
            <tr class="table-secondary">
                <th>
                    Date
                </th>
                <th>
                    Matin
                </th>
                <th>
                    Après-midi
                </th>
                <th style="max-width: 150px">
                    Unités d'astreinte
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let day of craconsultant.days; let i = index" [ngClass]="{'we': getColor(day) == 'we', 'ferie': getColor(day) == 'ferie'}">
                <td class="text-center">
                    {{day.date | date:'fullDate'}}
                </td>
                <td class="p-2 text-center">
                    <select [name]="'z'+i" id="" class="form-select form-select-sm" [(ngModel)]="day.matin" (change)="calcul()">
                  <option [value]="option" *ngFor="let option of options">{{option}}</option>
                </select>
                </td>
                <td class="p-2 text-center">
                    <select [name]="'y'+i" id="" class="form-select form-select-sm" [(ngModel)]="day.apresmidi" (change)="calcul()">
                <option [value]="option" *ngFor="let option of options">{{option}}</option>
              </select>
                </td>
                <td class="p-2 text-center">
                    <input type="number" class="form-control form-control-sm text-center" [(ngModel)]="day.astreinte" [name]="'x'+i" (change)="calculAstreintes()">
                </td>
            </tr>
        </tbody>
    </table>
    <div class="w-100 text-center py-2" style=" background-color: #EFEEEE;">
        <div style="font-weight: bold; font-size: 20px;">
            Total
        </div>
        <div class="row mt-1">
            <div class="col-md-4">Jours travaillés: <span class="text-info">{{craconsultant.totaltravail}} j</span></div>
            <div class="col-md-4">Astreintes: <span class="text-info">{{craconsultant.totalastreintes}} Unités</span></div>
            <div class="col-md-4">CP: <span class="text-info">{{craconsultant.totalcp}} j</span></div>
        </div>
        <div class="row mt-1">
            <div class="col-md-4">RTT: <span class="text-info">{{craconsultant.totalrtt}} j</span></div>
            <div class="col-md-4">CSS: <span class="text-info">{{craconsultant.totalcss}} j</span></div>
            <div class="col-md-4">Maladie: <span class="text-info">{{craconsultant.totalmaladie}} j</span></div>
        </div>
        <div class="row mt-2 pl-3" *ngIf="craconsultant.id != null">
            <div class="col-md-4 row">
                <div style="font-size: 18px; font-weight: bold;" class="ml-3 mr-2 mt-1">Signataire </div>
                <ng-select [clearable]="false" [items]="client.contacts" bindLabel="nom" bindValue="id" #selectTrigramme (change)="setSignataire()" placeholder="" style="width: 200px;" [(ngModel)]="signataire" *ngIf="client!=undefined">
                </ng-select>
            </div>
            <div class="col-md-4 row">
                <div style="font-size: 18px; font-weight: bold;" class="ml-3 mr-2 mt-1">CRA validé par signataire </div>
                <i class="bi bi-paperclip" style="font-size: 22px; font-weight: 800;" *ngIf="craconsultant.crafile == ''" (click)="fileInput.click()"></i>
                <i class="bi bi-eye-fill mr-3" style="font-size: 22px; font-weight: 800;" *ngIf="craconsultant.crafile != ''" (click)="downloadFile()"></i>
                <i class="bi bi-x-circle-fill" style="font-size: 22px; font-weight: 800; color: red;" *ngIf="craconsultant.crafile != ''" (click)="deleteFile()"></i>
            </div>
            <div class="col-md-4 row">
                <div style="font-size: 18px; font-weight: bold;" class="ml-3 mr-2 mt-1">Charger une facture</div>
                <i class="bi bi-paperclip" style="font-size: 22px; font-weight: 800;" *ngIf="craconsultant.crafacture == '' || craconsultant.crafacture == null" (click)="fileInput2.click()"></i>
                <i class="bi bi-eye-fill mr-3" style="font-size: 22px; font-weight: 800;" *ngIf="craconsultant.crafacture != '' && craconsultant.crafacture != null" (click)="downloadFacture()"></i>
                <i class="bi bi-x-circle-fill" style="font-size: 22px; font-weight: 800; color: red;" *ngIf="craconsultant.crafacture != '' && craconsultant.crafacture != null" (click)="deleteFacture()"></i>
            </div>
        </div>
        <input (change)="onFilechange($event)" type="file" style="display: none;" #fileInput>
        <input (change)="onFilechange2($event)" type="file" style="display: none;" #fileInput2>
    </div>

</div>

<div class="d-flex justify-content-center mt-4 mb-5">
    <button class="btn btn-success mr-5" (click)="updateCra()">Enregister</button>
    <button class="btn btn-secondary mr-5" (click)="logout()">Annuler et quitter</button>
</div>