import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { RHbackEndpoint } from '../config';

const endpoint =  RHbackEndpoint+'/tagmet';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class TagmetService {

  constructor(private http: HttpClient) { }

  private extractData(res: Response) {
    const body = res;
    return body || { };
  }

  getTagmet(): Observable<any> {
    return this.http.get(endpoint + '/public/all').pipe(
      map(this.extractData));
  }

  getOneTagmet(libelle): Observable<any> {
    return this.http.get(endpoint + '/public/getone/'+libelle).pipe(
      map(this.extractData));
  }


  getTagmetRMC(trigramme,qualitecv,dispo,experience, nom,prenom,titre,observation,tech,tagfoncs,tagmetiers,tagtechs,zones,dispos,exps,qualitecvs): Observable<any> {

    let fd = new FormData();
    fd.append('tagTech',tagtechs)
    fd.append('tagFonc',tagfoncs)
    fd.append('tagmetier',tagmetiers)
    fd.append('zone',zones)
    fd.append('qualitecv',qualitecvs)
    fd.append('exp',exps)
    fd.append('dispo',dispos)
    fd.append('qualitesearch',qualitecv)
    fd.append('nom',nom)
    fd.append('prenom',prenom)
    fd.append('titre',titre)
    fd.append('exposearch',experience)
    fd.append('tech',tech)
    fd.append('disposearch',dispo)
    fd.append('observation',observation)


      return this.http.post(endpoint + '/getTagmetwithScore/'+trigramme
      ,fd).pipe(
      map(this.extractData));

  }

  addTagmet(libelle: String): Observable<any> {
    return this.http.post<any>(endpoint + '/admin/add', libelle);
  }

  delTagmet(libelle: String): Observable<any> {
    return this.http.delete<any>(endpoint + `/admin/delete/${libelle}`);
  }

  mets(request) {
    //let url = 'http://localhost:8080/tags';
    const params = request;
    return this.http.get(endpoint, { params });
  }
  UpdateTechnique(technique, id,libelle) {
    let params = new HttpParams();
    params = params.append('id', id);
	 params = params.append('libelle', libelle);
    return this.http.put<any>(endpoint + '/admin/update', technique, {params});
  }
}
