import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { AuthService } from './../../services/auth.service';
import { TokenStorageService } from './../../services/token-storage.service';
import { Component, OnInit } from '@angular/core';
import { ProgressSpinnerDialogComponent } from 'src/app/progress-spinner-dialog/progress-spinner-dialog.component';
import { ConsultantProfileService } from 'src/app/services/consultants/consultant-profile.service';

function delay(ms: number) {
  return new Promise( resolve => setTimeout(resolve, ms) );
}

@Component({
  selector: 'app-home-consultant',
  templateUrl: './home-consultant.component.html',
  styleUrls: ['./home-consultant.component.scss']
})
export class HomeConsultantComponent implements OnInit {

  dialogRef: any;
  userid: string;
  consultant: any;
  qualifie: boolean = false;

  constructor(
    private tokenStorageService: TokenStorageService,
    private authService: AuthService,
    private router: Router,
    private dialog: MatDialog,
    private profileService: ConsultantProfileService
  ) { }

  ngOnInit(): void {
    const user = this.tokenStorageService.getUser();
    console.log("user", user)
    this.userid = user.id;
    this.getConsultantbyUserid(this.userid);
  }

  logout() {
    this.tokenStorageService.signOut();
    window.location.reload();
    (async () => {
      this.spin("Déconnexion...");
      await delay(2000);
      this.authService.setauth(false)
      this.dialogRef.close();
      this.router.navigate(["/login"]);

    })();
  }

  getConsultantbyUserid(userid: string) {
    this.profileService.getProfileByUserid(userid).subscribe((data: any) => {
      if (data) {
        this.profileService.setConsultantid(data.consultantid);
        this.qualifie = data.qualifie;
      }
    });
  }

  spin(message: string) {
    this.dialogRef = this.dialog.open(ProgressSpinnerDialogComponent, {
      data: {
        message: message
      },
    });
  }

}
