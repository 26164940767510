export class User {
    id: String;
    username: String;
    nom: String;
    prenom: String;
    email: String;
    trigramme: String;
    password: String;
    rolesrh: String;
    rolecrm: String;
}
