import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RHbackEndpoint } from '../config'

const endpoint =  RHbackEndpoint+'/CRA';

@Injectable({
  providedIn: 'root'
})
export class CraService {
  constructor(private http: HttpClient){}

  getJoursFeries(): Observable<any>{
    return this.http.get("https://calendrier.api.gouv.fr/jours-feries/metropole/2023.json");
  }

  getCra(contrat: string): Observable<any>{
    return this.http.get(endpoint+"/getcurrent", {params: {'contrat': contrat}});
  }

  getMissions(): Observable<any>{
    return this.http.get(endpoint+"/getMissions");
  }

  addCraFile(data: any): Observable<any> {
    return this.http.post(endpoint+"/addCraFile", data);
  }

  addCraFacture(data: any): Observable<any> {
    return this.http.post(endpoint+"/addCraFacture", data);
  }

  downloadCra(location : string) : Observable<any> {
    return this.http.get(endpoint+"/downloadCraFile", {params: {"filepath": location}, responseType: 'blob'});
  }

  deleteCra(location : string, id:string) : Observable<any> {
    return this.http.get(endpoint+"/deleteCraFile", {params: {"filepath": location, "idcra": id}});
  }

  deleteFacture(location : string, id:string) : Observable<any> {
    return this.http.get(endpoint+"/deleteCraFacture", {params: {"filepath": location, "idcra": id}});
  }

  updateCra(data: any): Observable<any> {
    return this.http.put(endpoint+"/updateCra", data);
  }

  getLastMission(): Observable<any>{
    return this.http.get(endpoint+"/get-last-mission");
  }
}
