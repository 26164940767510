
<nav id="sidebar" class="sidebar" style="margin-top: 10%" >

  <div class="sidebar-content" >
    <perfect-scrollbar>

      <div class="sidebar-menu">
        <ul>
          <li *ngFor="let menu of menus" id="{{menu.title}}" [ngClass]="{'active': menu.active , 'sidebar-dropdown':menu.type === 'dropdown' , 'header-menu':menu.type === 'header','ajout':menu.title}">
            <span *ngIf="menu.type === 'header'" >{{menu.title}}</span>
            <a *ngIf="menu.type !== 'header'"  (click)='toggle(menu,menu.path)' routerLink="">
              <i class="{{menu.icon}}" style="font-size: medium;"></i>
              <span style="font-size:23px;">{{menu.title}}</span>
              <span *ngIf="menu.badge" class="badge badge-pill" [ngClass]="menu.badge.class">{{menu.badge.text}}</span>
            </a>
            <div *ngIf="menu.type === 'dropdown'" class="sidebar-submenu" [@slide]="getState(menu)">
              <ul>
                <li *ngFor="let submenu of menu.submenus">

                  <a id="{{submenu.title}}"  routerLink="/{{submenu.path}}" ><i class="{{submenu.icon}} " style="font-size: medium;"></i> <span style="font-size:20px;">{{submenu.title}}</span>
                    <span *ngIf="submenu.badge" class="badge badge-pill" [ngClass]="submenu.badge.class">{{submenu.badge.text}}</span>
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </perfect-scrollbar>
  </div>

</nav>
