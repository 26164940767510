import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SkillService } from 'src/app/services/consultants/skill.service';
import { TrainingService } from 'src/app/services/consultants/training.service';

@Component({
  selector: 'app-configs-training',
  templateUrl: './configs-training.component.html',
  styleUrls: ['./configs-training.component.scss']
})
export class ConfigsTrainingComponent {


  constructor(private skillService: SkillService, private modalService: NgbModal, private trainingSerice : TrainingService) {

  }

  addspin: boolean = false;
  deletespin: boolean = false;
  adderror = null;

  selected: any = null;

  tname: string = "";
  tdomain: string = "";
  tsubdomain: string = "";
  tduration: string = "";
  torganism: string = "";
  ttype: string = "";
  turl: string = "";
  ttechnos: string[] = [];

  page: number=0;
  currentPage: number = 1;
  size: number=10;
  sortDirection: string = 'DESC'
  sortby: string = "name";
  pages: number[] = [0,1];
  totalelements: number = 0;

  name: string = "";
  domain: string = "";
  subdomain: string = "";
  technology: string = "";
  organism: string = "";

  trainings: any[] = [];
  domains: any[] = [];
  subdomains: any[] = [];
  technologies: any[] = [];
  isLoading: boolean = true;

  technos: any[] = [];

  ngOnInit(): void {
    this.getTrainings();
    this.skillService.findAllDomains().subscribe(
      data => this.domains = [...data]
    )
  }

  getSubdomains(){
    this.skillService.findSubdomainByDomain(this.tdomain).subscribe(
      data => this.subdomains = [...data]
    )
  }

  getTechnologies(){
    this.skillService.findTechnoBySubdomain(this.tsubdomain).subscribe(
      data => this.technologies = [...data]
    )
  }

  change(){
    this.getTrainings();
  }

  setPage(i: number, event: Event) {
    event.preventDefault();
    if(i>=0 && i<this.pages.length){
      this.page = i;
      this.currentPage = this.page+1;
      this.getTrainings();
    }
  }

  changePage(){
    if(this.currentPage>0){
      this.page = this.currentPage - 1;
      this.getTrainings();
    }
  }

  setSize(i: number) {
    this.size = i;
    this.getTrainings();
  }

  setSort(sortby: string, sortDirection: string) {
    this.sortby = sortby;
    this.sortDirection = sortDirection;
    this.getTrainings();
  }


  getTrainings(): void{
    this.isLoading = true;
    this.trainingSerice.getTrainings(this.name, this.domain, this.subdomain, this.technology,
       this.organism, this.sortby,this.sortDirection, this.page, this.size).subscribe(
      data => {
        this.trainings = [...data.content];
        this.pages = new Array(data.totalPages);
        this.totalelements = data.numberOfElements;
        this.isLoading = false;
      },
      error => {
        this.isLoading = false
      }
    );
  }

  filter() {
    this.page = 0;
    this.currentPage = 1;
    this.getTrainings();
  }


  addTraining(){
    if(
      this.tname == "" ||
      this.tdomain == "" ||
      this.tsubdomain == "" ||
      this.ttechnos.length == 0 ||
      this.ttype == "" ||
      this.turl == ""
    ) return;
    if(!this.isValidURL(this.turl)) return ;
    this.addspin = true;
    let data = {
      "id": null,
      "name": this.tname,
      "domain": this.tdomain,
      "subdomain": this.tsubdomain,
      "technologies": this.ttechnos,
      "organism": this.torganism,
      "type": this.ttype,
      "url": this.turl,
      "duration": this.tduration
    }
    this.trainingSerice.addTraining(data).subscribe(
      data => {
        this.filter();
        this.addspin = false;
        this.tname = "";
        this.tdomain = "";
        this.torganism = "";
        this.ttype = "";
        this.tsubdomain = "";
        this.tduration = "";
        this.turl = "";
        this.ttechnos = [];
      },
      error => {
        this.addspin = false;
        this.adderror = error.error.message;
      }
    )
  }

  openModal(content: any, data: any){
    this.selected = {...data};
    this.adderror = null;
    this.modalService.open(content, {centered: true});
  }

  update(){
    if(
      this.selected.name == "" ||
      this.selected.domain == "" ||
      this.selected.subdomain == "" ||
      this.selected.technologies.length == 0 ||
      this.selected.type == "" ||
      this.selected.url == ""
    ) return;
    if(!this.isValidURL(this.selected.url)) return ;
    this.addspin = true;
    this.trainingSerice.updateTraining(this.selected).subscribe(
      data => {
        this.filter();
        this.addspin = false;
        this.modalService.dismissAll();
        this.selected = null;
      },
      error => {
        this.addspin = false;
        this.adderror = error.error.message;
      }
    )
  }

  delete(){
    this.deletespin = true;
    if(this.selected == null) return ;
    this.trainingSerice.delete(this.selected.id).subscribe(
      data => {
        this.filter();
        this.deletespin = false;
        this.selected = null;
        this.modalService.dismissAll();
      },
      error => {
        this.deletespin = false;
      }
    )
  }

  select(domain:any){
    this.selected != domain ? this.selected = domain : this.selected = null;
  }

  openUrl(url: string){
    window.open("//" +url, "_blank");
  }

  isValidURL(url: string) {
    var res = url.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    return (res !== null)
  };

}
