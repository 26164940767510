import { DeleteAccountComponent } from './delete-account/delete-account.component';
import { EnterCodeComponent } from './enter-code/enter-code.component';
import { HomeConsultantComponent } from './consultants/home-consultant/home-consultant.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MatButtonModule, MatFormFieldModule, MatInputModule, MatRippleModule } from '@angular/material';
import { ListeDComponent } from './liste-d/liste-d.component';
import { UserComponent } from './user/user.component';
import { LoginComponent } from './login/login.component';
import { AuthService } from './services/auth.service';
import { CraConsultantComponent } from './consultants/cra-consultant/cra-consultant.component';
import { ManagerGuard } from './services/guards/managerGuard.service';
import { AdministrativeGuard } from './services/guards/administrativeGuard.service';
import { ConsultantGuard } from './services/guards/consultantGuard.service';
import { CompetencesComponent } from './consultants/competences/competences.component';
import { ExperiencesConsultantComponent } from './consultants/experiences-consultant/experiences-consultant.component';
import { RequestPasswordComponent } from './request-password/request-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { InfosConsultantComponent } from './consultants/infos-consultant/infos-consultant.component';
import { RegisterComponent } from './register/register.component';
import { OpportuniteConsultantComponent } from './consultants/opportunite-consultant/opportunite-consultant.component';
import { ConfigsComponent } from './cartographie-configs/configs/configs.component';
import { CartographieComponent } from './consultants/cartographie/cartographie/cartographie.component';
import { TrainingPageComponent } from './consultants/cartographie/training-page/training-page.component';
import { TestPageComponent } from './consultants/cartographie/test-page/test-page.component';
import { IdentificationComponent } from './identification/identification.component';


const routes: Routes = [

  {path: "listeD", component: ListeDComponent,canActivate:[AuthService]},
  {path: "config-competences", component: ConfigsComponent,canActivate:[AuthService]},
  {path: "users", component: UserComponent,canActivate:[AuthService]},
  {path: "consultant", component: HomeConsultantComponent,canActivate:[AuthService, ConsultantGuard], children: [
    {path: "", redirectTo: "informations", pathMatch: "full"},
    {path: "cra", component: CraConsultantComponent},
    {path: "experiences", component: ExperiencesConsultantComponent},
    {path: "competences", component: CompetencesComponent},
    {path: "informations", component: InfosConsultantComponent},
    {path: "notif-opportunite", component: OpportuniteConsultantComponent},
    {path: "cartographie", component: CartographieComponent},
    {path:"training-page/:category/:name", component: TrainingPageComponent},
    {path:"test-page/:category/:name", component: TestPageComponent}
  ]},
  {path: "login", component: LoginComponent},
  {path: "register", component: RegisterComponent},
  {path: "request-password", component: RequestPasswordComponent},
  {path:'reset-password', component: ResetPasswordComponent},
  {path:'enter-code', component: EnterCodeComponent},
  {path:'identification/:id', component: IdentificationComponent},
  {path:'delete-account', component: DeleteAccountComponent}
  //{path: "valider-facture", component: ValiderFactureConsultantComponent, canActivate:[AuthService]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash:true}), MatFormFieldModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
