<div class="menu">
    <a class="item" routerLink="/home">
        <div class="item-image "><img src="../../assets/icon/Accueil.png" alt=""></div>
    </a>

    <a class="item" routerLink="/dashboard">
        <div class="item-image "><img src="../../assets/icon/Dashboard (3).png" alt=""></div>
        <div class="item-name">Dashboard</div>
    </a>

    <a class="item" routerLink="/Liste">
        <div class="item-image "><img src="../../assets/icon/liste-consultants.png" alt=""></div>
        <div class="item-name">Liste Consultants</div>
    </a>

    <a class="item" routerLink="/ajoutc">
        <div class="item-image "><img src="../../assets/icon/AjoutConsultant.png" alt=""></div>
        <div class="item-name">Ajout Consultants</div>
    </a>


    <a class="item">
        <div class="item-image zoom-in"><img src="../../assets/icon/Recherche.png" alt=""></div>
        <div class="item-name">Recherche</div>
        <div class="input-group">
            <select (click)="changeRoute($event.target.value)" (focus)="changeRoute($event.target.value)" name="" id="">
                    <option value="null"></option>
                    <option (click)="openRoute($event.target.value)" value="rechmc">par Mots-clés</option>
                    <option (click)="openRoute($event.target.value)" value="rechtags">par Tags</option>
                </select>
        </div>

        <div class="item-hidden">
            <div class="input-group">
                <select (click)="changeRoute($event.target.value)" (focus)="changeRoute($event.target.value)" name="" id="">
                        <option value="null"></option>
                        <option (click)="openRoute($event.target.value)" value="rechmc">par Mots-clés</option>
                        <option (click)="openRoute($event.target.value)" value="rechtags">par Tags</option>
                    </select>
            </div>
        </div>
    </a>

    <a class="item" routerLink="/rappels">
        <div class="item-image "><img src="../../assets/icon/rappel.png" alt=""></div>
        <div class="item-name">Rappels</div>
    </a>
    <a class="item" routerLink="/RechercheBesoin">
        <div class="item-image zoom-in"><img src="../../assets/besoin.png" alt=""></div>
        <div class="item-name">Besoins</div>
        <div class="input-group">
            <input [(ngModel)]="BesoinFilter" type="text" placeholder="Saisir Client/Contact/Desc" />
            <div (click)="RechercheBesoin()"><i class="fa fa-search"></i></div>
        </div>


    </a>



    <a class="item" *ngIf="isFacturer()" routerLink="/page-facturation">
        <div class="item-image zoom-in"><img src="../../assets/icon/paramétrage.png" alt=""></div>
        <div class="item-name">Facturation</div>
    </a>

    <a class="item" *ngIf="!isFacturer()">
        <div class="item-image zoom-in"><img src="../../assets/icon/paramétrage.png" alt=""></div>
        <div class="item-name">Paramétrage & Admin</div>

        <div class="input-group">
            <select [disabled]="params" (change)="changeRoute($event.target.value)" name="admin" id="">
                    <option value="null"></option>
                    <option (click)="openRoute($event.target.value)" value="users">Utilisateurs</option>
                    <option (click)="openRoute($event.target.value)" value="listeD">Clients</option>
                    <option (click)="openRoute($event.target.value)" value="tags">Tags</option>
                    <option (click)="openRoute($event.target.value)" value="page-manager">Suivi missions</option>
                    <option (click)="openRoute($event.target.value)" value="config-competences">Compétences</option>
                </select>
        </div>
    </a>

</div>