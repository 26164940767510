<div class="container-fluid">
    <div class="menu bg-light">
        <a class="item" [routerLink]="['informations']">
            <div class="item-image"><img src="../../../assets/icon/informations.png" alt=""></div>
            <div class="item-name">Information</div>
        </a>
        <a class="item" [routerLink]="['experiences']">
            <div class="item-image "><img src="../../../assets/icon/experience.png" alt=""></div>
            <div class="item-name">Expérience</div>
        </a>
        <!-- <a class="item" [routerLink]="['cartographie']" *ngIf="qualifie">
            <div class="item-image "><img src="../../../assets/icon/formation.png" alt=""></div>
            <div class="item-name">Cartographie de compétences</div>
        </a>
        <a class="item" [routerLink]="['cra']">
            <div class="item-image "><img src="../../../assets/icon/CRA.png" alt=""></div>
            <div class="item-name">Compte Rendu d'Activité (CRA)</div>
        </a> -->
        <a class="item" [routerLink]="['notif-opportunite']">
            <div class="item-image "><img src="../../../assets/icon/notification.png" alt=""></div>
            <div class="item-name">Notifier opportunité</div>
        </a>
        <a class="item" (click)="logout()">
            <div class="item-image "><img src="../../../assets/deconnexion.png" alt=""></div>
            <div class="item-name">Déconnexion</div>
        </a>
    </div>

    <div class="container-fluid pt-4">
        <router-outlet></router-outlet>
    </div>
</div>