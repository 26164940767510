<div class="container-fluid mt-2 mb-5">

    <div class="d-flex justify-content-center mb-2">
        <div> <img src="../../../assets/opportunite.png" alt=""> </div>
    </div>
    <div class="table-container table-responsive">
        <div class="w-100 text-center py-1 text-danger" style="font-size: 22px; background-color: #EFEEEE; font-weight: 600;">
            Opportunités
        </div>
        <table id="element" class="table table-striped table-hover">
            <thead>
                <tr class="table-secondary">
                    <th scope="col" style="width: 130px">
                        <div class="input">
                            <mat-form-field class="form-fiel" style="width: 60%">
                                <mat-label class="label">Date</mat-label>
                                <input matInput (keyup.enter)="filterOpportunites()" name="tdate" [(ngModel)]="tdate">
                            </mat-form-field>
                            <div class="sort" (click)="this.setSort('date')">
                                <img *ngIf="this.sortBy != 'date'" src="../../assets/asc.png" alt="">
                                <img style="background-color: '#6ab38c';" *ngIf="this.sortBy === 'date' && this.sortDirection == 'ASC' " src="../../assets/asc.png" alt="">
                                <img style="background-color: '#6ab38c';" *ngIf="this.sortBy === 'date' && this.sortDirection == 'DESC'" src="../../assets/desc.png" alt="">
                            </div>
                        </div>
                    </th>

                    <th scope="col" style="width: 130px">
                        <div class="input">
                            <mat-form-field class="form-fiel" style="width: 60%" title="date ouverture">
                                <mat-label class="label">Date Ouverture</mat-label>
                                <input matInput (keyup.enter)="filterOpportunites()" name="tdateouverture" [(ngModel)]="tdateouverture">
                            </mat-form-field>
                            <div class="sort" (click)="this.setSort('dateouverture')">
                                <img *ngIf="this.sortBy != 'dateouverture'" src="../../assets/asc.png" alt="">
                                <img style="background-color: '#6ab38c';" *ngIf="this.sortBy === 'dateouverture' && this.sortDirection == 'ASC' " src="../../assets/asc.png" alt="">
                                <img style="background-color: '#6ab38c';" *ngIf="this.sortBy === 'dateouverture' && this.sortDirection == 'DESC'" src="../../assets/desc.png" alt="">
                            </div>
                        </div>
                    </th>

                    <th scope="col" style="width: 130px">
                        <div class="input">
                            <mat-form-field class="form-fiel" style="width: 60%">
                                <mat-label class="label">Client</mat-label>
                                <input matInput (keyup.enter)="filterOpportunites()" name="tclient" [(ngModel)]="tclient">
                            </mat-form-field>
                            <div class="sort" (click)="this.setSort('client')">
                                <img *ngIf="this.sortBy != 'client'" src="../../assets/asc.png" alt="">
                                <img style="background-color: '#6ab38c';" *ngIf="this.sortBy === 'client' && this.sortDirection == 'ASC' " src="../../assets/asc.png" alt="">
                                <img style="background-color: '#6ab38c';" *ngIf="this.sortBy === 'client' && this.sortDirection == 'DESC'" src="../../assets/desc.png" alt="">
                            </div>
                        </div>
                    </th>

                    <th scope="col" class="secteur" style="width: 130px">
                        <div class="input" id="secteur">
                            <mat-form-field class="form-fiel" style="width: 60%">
                                <mat-label class="label">Contact</mat-label>
                                <input matInput (keyup.enter)="filterOpportunites()" name="tcontact" [(ngModel)]="tcontact">
                            </mat-form-field>
                            <div class="sort" (click)="this.setSort('contact')">
                                <img *ngIf="this.sortBy != 'contact'" src="../../assets/asc.png" alt="">
                                <img style="background-color: '#6ab38c';" *ngIf="this.sortBy === 'contact' && this.sortDirection == 'ASC' " src="../../assets/asc.png" alt="">
                                <img style="background-color: '#6ab38c';" *ngIf="this.sortBy === 'contact' && this.sortDirection == 'DESC'" src="../../assets/desc.png" alt="">
                            </div>
                        </div>
                    </th>

                    <th scope="col">
                        <div class="input" id="secteur">
                            <mat-form-field class="form-fiel" style="width: 60%" title="tel contact">
                                <mat-label class="label">Tel. contact</mat-label>
                                <input matInput (keyup.enter)="filterOpportunites()" name="ttelcontact" [(ngModel)]="ttelcontact">
                            </mat-form-field>
                            <div class="sort" (click)="this.setSort('telcontact')">
                                <img *ngIf="this.sortBy != 'telcontact'" src="../../assets/asc.png" alt="">
                                <img style="background-color: '#6ab38c';" *ngIf="this.sortBy === 'telcontact' && this.sortDirection == 'ASC' " src="../../assets/asc.png" alt="">
                                <img style="background-color: '#6ab38c';" *ngIf="this.sortBy === 'telcontact' && this.sortDirection == 'DESC'" src="../../assets/desc.png" alt="">
                            </div>
                        </div>
                    </th>

                    <th scope="col" class="ville">
                        <div class="input" id="secteur">
                            <mat-form-field class="form-fiel" style="width: 60%" title="email contact">
                                <mat-label class="label">Email contact</mat-label>
                                <input matInput (keyup.enter)="filterOpportunites()" name="temailcontact" [(ngModel)]="temailcontact">
                            </mat-form-field>
                            <div class="sort" (click)="this.setSort('emailcontact')">
                                <img *ngIf="this.sortBy != 'emailcontact'" src="../../assets/asc.png" alt="">
                                <img style="background-color: '#6ab38c';" *ngIf="this.sortBy === 'emailcontact' && this.sortDirection == 'ASC' " src="../../assets/asc.png" alt="">
                                <img style="background-color: '#6ab38c';" *ngIf="this.sortBy === 'emailcontact' && this.sortDirection == 'DESC'" src="../../assets/desc.png" alt="">
                            </div>
                        </div>
                    </th>

                    <th scope="col" style="width: 150px;">
                        <div class="input" id="secteur">
                            <mat-form-field class="form-fiel" style="width: 60%" title="description besoin">
                                <mat-label class="label">Description besoin</mat-label>
                                <input matInput (keyup.enter)="filterOpportunites()" name="tdescription" [(ngModel)]="tdescription">
                            </mat-form-field>
                            <div class="sort" (click)="this.setSort('description')">
                                <img *ngIf="this.sortBy != 'description'" src="../../assets/asc.png" alt="">
                                <img style="background-color: '#6ab38c';" *ngIf="this.sortBy === 'description' && this.sortDirection == 'ASC' " src="../../assets/asc.png" alt="">
                                <img style="background-color: '#6ab38c';" *ngIf="this.sortBy === 'description' && this.sortDirection == 'DESC'" src="../../assets/desc.png" alt="">
                            </div>
                        </div>
                    </th>

                    <th scope="col" class="note">
                        <div class="input" id="secteur">
                            <mat-form-field class="form-fiel" style="width: 60%" title="compétences demandées">
                                <mat-label class="label">Compétences demandées</mat-label>
                                <input matInput (keyup.enter)="filterOpportunites()" name="tcompetences" [(ngModel)]="tcompetences">
                            </mat-form-field>
                            <div class="sort" (click)="this.setSort('competences')">
                                <img *ngIf="this.sortBy != 'competences'" src="../../assets/asc.png" alt="">
                                <img style="background-color: '#6ab38c';" *ngIf="this.sortBy === 'competences' && this.sortDirection == 'ASC' " src="../../assets/asc.png" alt="">
                                <img style="background-color: '#6ab38c';" *ngIf="this.sortBy === 'competences' && this.sortDirection == 'DESC'" src="../../assets/desc.png" alt="">
                            </div>
                        </div>
                    </th>

                    <th scope="col" class="note">
                        Niveau de Compétence
                    </th>

                    <th scope="col" class="note">
                        <div class="input" id="secteur">
                            <mat-form-field class="form-fiel" style="width: 60%" title="debut mission souhaité">
                                <mat-label class="label">Debut mission souhaité</mat-label>
                                <input matInput (keyup.enter)="filterOpportunites()" name="tdebutmission" [(ngModel)]="tdebutmission">
                            </mat-form-field>
                            <div class="sort" (click)="this.setSort('debutmission')">
                                <img *ngIf="this.sortBy != 'debutmission'" src="../../assets/asc.png" alt="">
                                <img style="background-color: '#6ab38c';" *ngIf="this.sortBy === 'debutmission' && this.sortDirection == 'ASC' " src="../../assets/asc.png" alt="">
                                <img style="background-color: '#6ab38c';" *ngIf="this.sortBy === 'debutmission' && this.sortDirection == 'DESC'" src="../../assets/desc.png" alt="">
                            </div>
                        </div>
                    </th>

                    <th scope="col" class="note">
                        Doc
                    </th>

                    <th scope="col" class="note">
                        <div class="input" id="secteur">
                            <mat-form-field class="form-fiel" style="width: 60%" title="observations">
                                <mat-label class="label">Observations</mat-label>
                                <input matInput (keyup.enter)="filterOpportunites()" name="tobservations" [(ngModel)]="tobservations">
                            </mat-form-field>
                            <div class="sort" (click)="this.setSort('observations')">
                                <img *ngIf="this.sortBy != 'observations'" src="../../assets/asc.png" alt="">
                                <img style="background-color: '#6ab38c';" *ngIf="this.sortBy === 'observations' && this.sortDirection == 'ASC' " src="../../assets/asc.png" alt="">
                                <img style="background-color: '#6ab38c';" *ngIf="this.sortBy === 'observations' && this.sortDirection == 'DESC'" src="../../assets/desc.png" alt="">
                            </div>
                        </div>
                    </th>
                    <th scope="col" class="note">
                        Qualifiée
                    </th>
                    <th scope="col" class="note">
                        Gagnée
                    </th>
                </tr>
            </thead>
            <tbody style="position: relative;">
                <tr *ngFor="let op of opportunites; let i = index">
                    <td [innerHTML]="op.date | highlight:tdate"></td>
                    <td [innerHTML]="op.dateouverture | highlight:tdateouverture"></td>
                    <td [title]="op.client" [innerHTML]="op.client | highlight:tclient"></td>
                    <td [title]="op.contact" [innerHTML]="op.contact | highlight:tcontact"></td>
                    <td [innerHTML]="op.telcontact | highlight:ttelcontact"></td>
                    <td [innerHTML]="op.emailcontact | highlight:temailcontact"></td>
                    <td [title]="op.description" [innerHTML]="op.description | highlight:tdescription"></td>
                    <td [title]="op.competences" [innerHTML]="op.competences | highlight:tcompetences"></td>
                    <td>{{op.niveaucompetence}}</td>
                    <td [innerHTML]="op.debutmission | highlight:tdebutmission"></td>
                    <td><i class="bi bi-eye-fill" (click)="downloadFile(op.document)" *ngIf="op.document!=null && op.document!=''"></i></td>
                    <td [title]="op.observations" [innerHTML]="op.observations | highlight:tobservations"></td>
                    <td>
                        <select class="form-select" [ngStyle]="{ color: selectQualifColor(op) }" [name]="'qualif'+i" [(ngModel)]="op.qualifie" disabled>
                            <option value="NON" style="color: red;"> <p>&#xf111; &nbsp;</p> </option>
                            <option value="OUI" style="color: green;"> <p>&#xf111; &nbsp;</p> </option>
                      </select>
                    </td>
                    <td>
                        <select class="form-select" [ngStyle]="{ color: selectGagneColor(op) }" [name]="'gagne'+i" [(ngModel)]="op.gagne" disabled>
                            <option value="NON" style="color: red;"> <p>&#xf111; &nbsp;</p> </option>
                            <option value="OUI" style="color: green;"> <p>&#xf111; &nbsp;</p> </option>
                      </select>
                    </td>
                </tr>

                <tr>
                    <td class="font-weight-bold"> Eléments <br/> Probables</td>
                    <td>
                        <input type="date" class="form-control mt-1" name="dateouverture" title="" [(ngModel)]="dateouverture">
                    </td>
                    <td>
                        <input type="text" class="form-control mt-1" placeholder="" name="client" [(ngModel)]="client">
                    </td>
                    <td>
                        <input type="text" class="form-control mt-1" placeholder="" name="nco" [(ngModel)]="contact">
                    </td>
                    <td>
                        <input type="text" class="form-control mt-1" placeholder="" name="tco" [(ngModel)]="telcontact">
                    </td>
                    <td>
                        <input type="text" class="form-control mt-1" placeholder="" name="mailco" [(ngModel)]="emailcontact">
                    </td>
                    <td>
                        <input type="text" class="form-control mt-1" placeholder="" name="desc" [(ngModel)]="description">
                    </td>
                    <td>
                        <input type="text" class="form-control mt-1" placeholder="" name="competences" [(ngModel)]="competences">
                    </td>
                    <td>
                        <select type="text" class="form-select mt-1" style="height: 35px; width: 90%;" name="typef" [(ngModel)]="niveau">
                          <option value="" selected hidden></option>
                          <option value="Notions" selected hidden>Notions</option>
                          <option value="Intermédiaire">Intermédiaire</option>
                          <option value="Avancé">Avancé</option>
                          <option value="Expert">Expert</option>
                      </select>
                    </td>
                    <td>
                        <input type="date" class="form-control mt-1" [min]="today" title="" [(ngModel)]="debutmission">
                    </td>
                    <td>
                        <i class="bi bi-paperclip font-weight-bold mr-4" title="" style="font-size: 28px; font-weight: 800;" (click)="fileInput.click()"></i>
                        <input (change)="onFilechange($event)" type="file" style="display: none;" #fileInput>
                    </td>
                    <td>
                        <input type="text" class="form-control mt-1" placeholder="" name="obs" [(ngModel)]="observations">
                    </td>
                    <td>
                        <button class="btn btn-sm btn-rounded mt-1" style="background-color: #86CB8C;" (click)="addOpportunite()">
                            <i  class="bi bi-plus font-weight-bold" style="font-size: 18px;"></i>
                        </button>
                    </td>
                </tr>

            </tbody>
        </table>
    </div>

    <hr>

    <div class="row d-flex pb-1 mb-4" *ngIf="pages.length>0">
        <div class="col-md-4 d-flex justify-content-center">

        </div>
        <div class="col-md-4 align-self-center text-center page-info">
            <ul class="nav nav-pills">
                <li class="nav-item">
                    <a class="nav-link" href="" (click)="setPage(0, $event)"><i class="bi bi-chevron-bar-left"></i></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" role="button" href="" (click)="setPage(page-1, $event)"><i class="bi bi-chevron-double-left"></i></a>
                </li>

                <!-- <li class="nav-item" *ngFor="let p of pages; let i=index">
                    <a class="nav-link" *ngIf="i===page || i===page-1 || i===page+1" [ngClass]="{active: i===page}" href="" (click)="setPage(i, $event)">{{i+1}}</a>
                </li> -->
                <div class="mt-2">
                    <input type="number" style="width: 50px; height: 25px; color: red;" [(ngModel)]="currentPage" #pageInput (keyup)="changePage()"> / <span>{{pages.length}}</span>
                </div>


                <li class="nav-item">
                    <a class="nav-link" href="" (click)="setPage(page+1, $event)"><i class="bi bi-chevron-double-right"></i></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="" (click)="setPage(pages.length-1, $event)"><i class="bi bi-chevron-bar-right"></i></a>
                </li>
            </ul>
        </div>
        <div class="col-md-4 d-flex justify-content-center">
            <span class="mt-1">Par page:</span>
            <select class="form-select form-select-sm" id="size-select" name="size" #selectSize [ngModel]="size" (change)="setSize(+(selectSize.value))">
                <option value="10">10</option>
                <option value="30">30</option>
                <option value="60">60</option>
                <option value="120">120</option>
            </select>
        </div>
    </div>
</div>
