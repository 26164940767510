<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">

<div class="container-fluid" *ngIf="csdata">

    <div class="alert alert-success my-2" *ngIf="consultant.qualifie">
        Profil qualifié et validé.
    </div>
    <div class="alert alert-danger my-2" *ngIf="consultant.nonqualifie">
        Désolé, votre profil n'a pas été accepté.
    </div>

    <div class="container-fluid mb-3">
        <div class="row">

            <div class="col-md-6">
                <div class="card h-100">
                    <div class="card-body p-3">
                        <span style="padding-left: 40%;font-weight: bold;padding-top: 10px;padding-bottom: 10px;"> Technologies </span><br>
                        <div style="display: flex;">
                            <i class="fa fa-filter" style="font-size: 25px;" aria-hidden="true"></i>
                            <input class="form-control form-control-sm ml-2" id="regTag" name="regTag" placeholder="Filtre...">
                        </div>
                        <div class="row">
                            <div class="col-md-6 p-2">
                                <div class="bordInterDiv">
                                    <table style="border: none;">
                                        <tr *ngFor="let item of TagTechPageUpdate; let i = index ">
                                            <td>
                                                <input [ngModelOptions]="{standalone: true}" type="checkbox" style="cursor: pointer; height: auto;" checked (change)="ChangeTagTech(item,$event.target.checked)" />
                                            </td>
                                            <td style="padding-right: 70px;">
                                                {{item.libelle}}
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div class="col-md-6 p-2">
                                <div class="bordInterDiv">
                                    <table style="border: none;">
                                        <tr *ngFor="let item of TagTechTabFilter">
                                            <td>
                                                <input [ngModelOptions]="{standalone: true}" type="checkbox" style="cursor: pointer;" (change)="AddTagsToUpdate(item,$event.target.checked)">
                                            </td>
                                            <td style="padding-right: 55px;">
                                                {{item.libelle}}
                                            </td>

                                        </tr>
                                    </table>
                                </div>
                                <!-- <mdb-checkbox *ngFor="let item of tagTechPage" style="margin-left: 8px;">   {{item.libelle}} </mdb-checkbox> -->
                            </div>

                        </div>
                        <div style="display: flex;justify-content: center;align-items: center;flex-wrap: wrap;">
                            <div class="marginright" style="float: left;">
                                <span style="font-size: 11px;margin-right: 8px;">Élements par page</span>

                                <input [ngModelOptions]="{standalone: true}" type="number" style="font-size: 11px;width: 43px;" [(ngModel)]="size" [ngModelOptions]="{standalone: true}" (change)="ChangerTagsPageSize()" step="25" min="25" max="100">

                            </div>

                            <div style="width: 200px;">
                                <span style="font-size: 12px;margin-left:6%;"> page {{suivant+1}} sur {{totalPage}}</span>
                                <button class="fleche" (click)="PremiereTagsPage()" [disabled]="suivant===0"><svg width="1em" height="1em" viewBox="0 0 16 16"
          class="bi bi-chevron-bar-left" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd"
            d="M11.854 3.646a.5.5 0 0 1 0 .708L8.207 8l3.647 3.646a.5.5 0 0 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 0 1 .708 0zM4.5 1a.5.5 0 0 0-.5.5v13a.5.5 0 0 0 1 0v-13a.5.5 0 0 0-.5-.5z" />
        </svg>
      </button>
                                <button class="fleche" (click)="PreviousTagsPage()" [disabled]="suivant===0"><i class="fa fa-angle-left"></i></button>
                                <button class="fleche" (click)="nextTagsPage()" [disabled]="suivant===totalPage-1||totalPage===0"><i
          class="fa fa-angle-right"></i>
        </button>
                                <button class="fleche" (click)="FinalTagsPage()" [disabled]="suivant===totalPage-1||totalPage===0"><svg width="1em" height="1em"
          viewBox="0 0 16 16" class="bi bi-chevron-bar-right" fill="currentColor"
          xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd"
            d="M4.146 3.646a.5.5 0 0 0 0 .708L7.793 8l-3.647 3.646a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708 0zM11.5 1a.5.5 0 0 1 .5.5v13a.5.5 0 0 1-1 0v-13a.5.5 0 0 1 .5-.5z" />
        </svg>
      </button>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="card h-100">
                    <div class="card-body">
                        <span style="padding-left: 40%;font-weight: bold;padding-top: 10px;padding-bottom: 10px;">Activités </span><br>

                        <div class="d-flex">
                            <i class="fa fa-filter" style="font-size: 25px;" aria-hidden="true"></i>
                            <input class="form-control form-control-sm ml-2" type="text" id="fonction" name="fonction" placeholder="Filtre...">
                        </div>
                        <div class="row">
                            <div class="col-md-6 p-2">
                                <div class="bordInterDiv">

                                    <table style="border: none;">
                                        <tr *ngFor="let item of FonctionPageUpdate; let i = index ">
                                            <td>
                                                <input [ngModelOptions]="{standalone: true}" type="checkbox" style="cursor: pointer;" checked (change)="ChangeFonction(item,$event.target.checked)" />
                                            </td>
                                            <td style="padding-right: 70px;">
                                                {{item.libelle}}
                                            </td>

                                        </tr>
                                    </table>
                                </div>
                            </div>

                            <div class="col-md-6 p-2">
                                <div class="bordInterDiv">
                                    <table style="border: none;">
                                        <tr *ngFor="let item of FonctionTabFilter">
                                            <td>
                                                <input [ngModelOptions]="{standalone: true}" type="checkbox" style="cursor: pointer;" (change)="AddFonctionToUpdate(item,$event.target.checked)">
                                            </td>
                                            <td style="padding-right: 55px;">
                                                {{item.libelle}}
                                            </td>

                                        </tr>
                                    </table>
                                    <!-- <mdb-checkbox *ngFor="let item of tagTechPage" style="margin-left: 8px;">   {{item.libelle}} </mdb-checkbox> -->
                                </div>
                            </div>

                        </div>


                        <div style="display: flex;flex-wrap: wrap;justify-content: center;align-items: center;">
                            <div style="float: left;" class="marginright">
                                <span style="font-size: 11px;margin-right: 8px;">Élements par page</span>

                                <input [ngModelOptions]="{standalone: true}" type="number" style="font-size: 11px;width: 43px;" [(ngModel)]="sizeFonction" [ngModelOptions]="{standalone: true}" (change)="ChangerFonctionPageSize()" step="25" min="25" max="100">

                            </div>

                            <div style="width: 200px;">
                                <span style="font-size: 12px;margin-left:6%;"> page {{suivantFonction+1}} sur {{totalPageFonction}}</span>
                                <button class="fleche" (click)="PremiereFonctionPage()" [disabled]="suivantFonction===0"><svg width="1em" height="1em" viewBox="0 0 16 16"
          class="bi bi-chevron-bar-left" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd"
            d="M11.854 3.646a.5.5 0 0 1 0 .708L8.207 8l3.647 3.646a.5.5 0 0 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 0 1 .708 0zM4.5 1a.5.5 0 0 0-.5.5v13a.5.5 0 0 0 1 0v-13a.5.5 0 0 0-.5-.5z" />
        </svg>
      </button>
                                <button class="fleche" (click)="PreviousFonctionPage()" [disabled]="suivantFonction===0"><i class="fa fa-angle-left"></i></button>
                                <button class="fleche" (click)="nextFonctionPage()" [disabled]="suivantFonction===totalPageFonction-1||totalPageFonction===0"><i
          class="fa fa-angle-right"></i>
        </button>
                                <button class="fleche" (click)="FinalFonctionPage()" [disabled]="suivantFonction===totalPageFonction-1||totalPageFonction===0"><svg width="1em" height="1em"
          viewBox="0 0 16 16" class="bi bi-chevron-bar-right" fill="currentColor"
          xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd"
            d="M4.146 3.646a.5.5 0 0 0 0 .708L7.793 8l-3.647 3.646a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708 0zM11.5 1a.5.5 0 0 1 .5.5v13a.5.5 0 0 1-1 0v-13a.5.5 0 0 1 .5-.5z" />
        </svg>
      </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div class="container-fluid mb-4">
        <div class="row">
            <div class="col-md-6">
                <div class="card h-100">
                    <div class="card-body p-3">
                        <span style="padding-left: 43%;font-weight: bold;">Secteurs d'activité</span><br>
                        <div class="d-flex">
                            <i class="fa fa-filter" style="font-size: 25px;" aria-hidden="true"></i>
                            <input type="text" class="form-control form-control-sm ml-2" id="metier" name="metier" placeholder="Filtre..."> <br>

                        </div>

                        <div class="row">

                            <div class="col-md-6 p-2">
                                <div class="bordInterDiv">
                                    <table style="border: none;">
                                        <tr *ngFor="let item of MetierPageUpdate; let i = index ">
                                            <td>
                                                <input [ngModelOptions]="{standalone: true}" type="checkbox" style="cursor: pointer;" checked (change)="ChangeMetier(item,$event.target.checked)" />
                                            </td>
                                            <td style="padding-right: 70px;">
                                                {{item.libelle}}
                                            </td>

                                        </tr>
                                    </table>
                                </div>
                            </div>

                            <div class="col-md-6 p-2">
                                <div class="bordInterDiv">
                                    <table style="border: none;">
                                        <tr *ngFor="let item of MetierTabFilter">
                                            <td>
                                                <input [ngModelOptions]="{standalone: true}" type="checkbox" style="cursor: pointer;" (change)="AddMetierToUpdate(item,$event.target.checked)">
                                            </td>
                                            <td style="padding-right: 55px;">
                                                {{item.libelle}}
                                            </td>

                                        </tr>
                                    </table>
                                    <!-- <mdb-checkbox *ngFor="let item of tagTechPage" style="margin-left: 8px;">   {{item.libelle}} </mdb-checkbox> -->
                                </div>
                            </div>

                        </div>
                        <div style="display: flex;justify-content: center;align-items: center;flex-wrap: wrap;">
                            <div class="marginright" style="float: left;">
                                <span style="font-size: 11px;margin-right: 8px;">Élements par page</span>

                                <input [ngModelOptions]="{standalone: true}" type="number" style="font-size: 11px;width: 43px;" [(ngModel)]="sizeMetier" [ngModelOptions]="{standalone: true}" (change)="ChangerMetierPageSize()" step="25" min="25" max="100">

                            </div>

                            <div style="width: 200px;">
                                <span style="font-size: 12px;margin-left:6%;"> page {{suivantMetier+1}} sur {{totalPageMetier}}</span>
                                <button class="fleche" (click)="PremiereMetierPage()" [disabled]="suivantMetier===0"><svg width="1em" height="1em" viewBox="0 0 16 16"
  class="bi bi-chevron-bar-left" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd"
    d="M11.854 3.646a.5.5 0 0 1 0 .708L8.207 8l3.647 3.646a.5.5 0 0 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 0 1 .708 0zM4.5 1a.5.5 0 0 0-.5.5v13a.5.5 0 0 0 1 0v-13a.5.5 0 0 0-.5-.5z" />
</svg>
</button>
                                <button class="fleche" (click)="PreviousMetierPage()" [disabled]="suivantMetier===0"><i class="fa fa-angle-left"></i></button>
                                <button class="fleche" (click)="nextMetierPage()" [disabled]="suivantMetier===totalPageMetier-1||totalPageMetier===0"><i
  class="fa fa-angle-right"></i>
</button>
                                <button class="fleche" (click)="FinalMetierPage()" [disabled]="suivantMetier===totalPageMetier-1||totalPageMetier===0"><svg width="1em" height="1em"
  viewBox="0 0 16 16" class="bi bi-chevron-bar-right" fill="currentColor"
  xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd"
    d="M4.146 3.646a.5.5 0 0 0 0 .708L7.793 8l-3.647 3.646a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708 0zM11.5 1a.5.5 0 0 1 .5.5v13a.5.5 0 0 1-1 0v-13a.5.5 0 0 1 .5-.5z" />
</svg>
</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="card h-100">
                    <div class="card-body p-3" style="margin-bottom: 50px;">
                        <span style="font-weight: bold;text-align: center;padding-left: 47%;">Clients</span><br>
                        <div class="d-flex">
                            <i class="fa fa-filter" style="font-size: 25px" aria-hidden="true"></i>
                            <input type="text" class="form-control form-control-sm" id="client" name="client" placeholder="Filtre...">
                        </div>
                        <div class="row">
                            <div class="col-md-6 p-2">
                                <div class="bordInterDiv">
                                    <div style="margin-top: 7px;">
                                        <input [ngModelOptions]="{standalone: true}" type="text" style="height: 20px;width: 177px; margin-right: 3px;font-size: 14px;" placeholder="Ajouter Client ..." [(ngModel)]="clientUpdate.libelle" [ngModelOptions]="{standalone: true}" #inputs>
                                        <button class="icon" (click)="AddClient()"> <i class="fa fa-plus"></i></button>
                                    </div>
                                    <table style="margin-top: 10px;border: none;">
                                        <tr *ngFor="let item of ClientPageUpdate; let i = index ">
                                            <td>
                                                <input [ngModelOptions]="{standalone: true}" type="checkbox" style="cursor: pointer;" checked (change)="ChangeClient(item,$event.target.checked)" />
                                            </td>
                                            <td style="padding-right: 70px;">
                                                {{item.libelle}}
                                            </td>

                                        </tr>
                                    </table>
                                </div>
                            </div>

                            <div class="col-md-6 p-2">
                                <div class="bordInterDiv">
                                    <table style="border: none;">
                                        <tr *ngFor="let item of ClientTabFilter">
                                            <td>
                                                <input [ngModelOptions]="{standalone: true}" type="checkbox" style="cursor: pointer;" (change)="AddClientToUpdate(item,$event.target.checked)">
                                            </td>
                                            <td style="padding-right: 55px;">
                                                {{item.libelle}}
                                            </td>

                                        </tr>
                                    </table>
                                    <!-- <mdb-checkbox *ngFor="let item of tagTechPage" style="margin-left: 8px;">   {{item.libelle}} </mdb-checkbox> -->
                                </div>
                            </div>

                        </div>
                        <div style="display: flex;justify-content: center;align-items: center;flex-wrap: wrap;">
                            <div style="float: left;" class="marginright">
                                <span style="font-size: 11px;margin-right: 8px;">Élements par page</span>

                                <input [ngModelOptions]="{standalone: true}" type="number" style="font-size: 11px;width: 43px;" [(ngModel)]="sizeClient" [ngModelOptions]="{standalone: true}" (change)="ChangerClientPageSize()" step="25" min="25" max="100">

                            </div>

                            <div style="width: 200px;">
                                <span style="font-size: 12px;margin-left:6%;"> page {{suivantClient+1}} sur {{totalPageClient}}</span>
                                <button class="fleche" (click)="PremiereClientPage()" [disabled]="suivantClient===0"><svg width="1em" height="1em" viewBox="0 0 16 16"
      class="bi bi-chevron-bar-left" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd"
        d="M11.854 3.646a.5.5 0 0 1 0 .708L8.207 8l3.647 3.646a.5.5 0 0 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 0 1 .708 0zM4.5 1a.5.5 0 0 0-.5.5v13a.5.5 0 0 0 1 0v-13a.5.5 0 0 0-.5-.5z" />
    </svg>
  </button>
                                <button class="fleche" (click)="PreviousClientPage()" [disabled]="suivantClient===0"><i class="fa fa-angle-left"></i></button>
                                <button class="fleche" (click)="nextClientPage()" [disabled]="suivantClient===totalPageClient-1||totalPageClient===0"><i
      class="fa fa-angle-right"></i>
    </button>
                                <button class="fleche" (click)="FinalClientPage()" [disabled]="suivantClient===totalPageClient-1||totalPageClient===0"><svg width="1em" height="1em"
      viewBox="0 0 16 16" class="bi bi-chevron-bar-right" fill="currentColor"
      xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd"
        d="M4.146 3.646a.5.5 0 0 0 0 .708L7.793 8l-3.647 3.646a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708 0zM11.5 1a.5.5 0 0 1 .5.5v13a.5.5 0 0 1-1 0v-13a.5.5 0 0 1 .5-.5z" />
    </svg>
  </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
        <!-- Grid column -->
    </div>

    <!-- Extended material form grid -->
    <br><br>

    <div class="container-fluid mt-4 text-center h4 p-2 font-weight-bolder" style="background-color: #c0e3fc;">
        Afin de vous qualifier pour les opportunités qui se présenteront, et de préparer l'entretien, nous vous prions de bien vouloir remplir les éléments ci-dessous :
    </div>

    <div class="container-fluid mt-4">

        <div class="row">
            <div class="col-md-6">
                <div class="container-fluid mt-2 mb-5">

                    <div class="table-container table-responsive">
                        <div class="w-100 text-center py-1 text-danger" style="font-size: 23px; background-color: #EFEEEE; font-weight: 700;">
                            Technologies
                        </div>
                        <table id="element" class="table table-striped table-hover">
                            <thead>
                                <tr class="table-secondary">
                                    <th scope="col">
                                        Technologie
                                    </th>
                                    <th scope="col">
                                        Niveau de compétence
                                    </th>

                                    <th scope="col">
                                        Durée pratique (Mois)
                                    </th>

                                    <th scope="col">
                                        Référence
                                    </th>

                                    <th scope="col">
                                        Documents et liens
                                    </th>
                                </tr>
                            </thead>
                            <tbody style="position: relative;">
                                <tr *ngFor="let tech of consultant.competencestechnos; let i=index;">
                                    <td class="font-weight-bold">{{tech.intitule}}</td>
                                    <td>
                                        <select class="form-select form-select-sm p-1" [name]="'niv'+i" [(ngModel)]="tech.niveau" style="width: 90%;">
                                  <option value="notions">Notions</option>
                                  <option value="intermediaire">Intermédiaire</option>
                                  <option value="avance">Avancé</option>
                                  <option value="expert">Expert</option>
                                </select>
                                    </td>
                                    <td>
                                        <input type="number" [name]="'duree'+i" [(ngModel)]="tech.dureepratique">
                                    </td>
                                    <td>
                                        <i class="bi bi-person-fill action-icon mr-2" (click)="openModal(listRefModal, tech)"></i>
                                        <i class="bi bi-plus-circle-fill action-icon" style="color: green;" (click)="openModal(addRefModal, tech)"></i>
                                    </td>
                                    <td>
                                        <span class="mr-4">
                                  <i class="bi bi-eye-fill action-icon mr-2" *ngIf="tech.document!=''" (click)="visualiser(tech.document)"></i>
                                  <i class="bi bi-paperclip action-icon" (click)="openFileInput(fileInput, tech)" *ngIf="tech.document==''"></i>
                                  <i class="bi bi-x-circle-fill action-icon" style="color: red;" (click)="deleteDocument(tech)" *ngIf="tech.document!=''"></i>
                                </span>
                                        <span>
                                  <i class="bi bi-browser-chrome action-icon mr-2" (click)="openLink(tech.lien)"></i>
                                  <i class="bi bi-plus-circle-fill action-icon mr-2" style="color: green;" (click)="openModal(linkModal, tech)" *ngIf="tech.lien==''"></i>
                                  <i class="bi bi-x-circle-fill action-icon" style="color: red;" (click)="deleteLink(tech)" *ngIf="tech.lien!=''"></i>
                                </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>

            <div class="col-md-6">
                <div class="container-fluid mt-2 mb-5">

                    <div class="table-container table-responsive">
                        <div class="w-100 text-center py-1 text-danger" style="font-size: 23px; background-color: #EFEEEE; font-weight: 700;">
                            Activités
                        </div>
                        <table id="element" class="table table-striped table-hover">
                            <thead>
                                <tr class="table-secondary">
                                    <th scope="col">
                                        Activité
                                    </th>
                                    <th scope="col">
                                        Niveau de compétence
                                    </th>

                                    <th scope="col">
                                        Durée pratique
                                    </th>

                                    <th scope="col">
                                        Référence
                                    </th>

                                    <th scope="col">
                                        Documents et liens
                                    </th>
                                </tr>
                            </thead>
                            <tbody style="position: relative;">
                                <tr *ngFor="let act of consultant.competencesactivites; let i=index;">
                                    <td class="font-weight-bold">{{act.intitule}}</td>
                                    <td>
                                        <select class="form-select form-select-sm p-1" [name]="'aniv'+i" [(ngModel)]="act.niveau" style="width: 90%;">
                                  <option value="notions">Notions</option>
                                  <option value="intermediaire">Intermédiaire</option>
                                  <option value="avance">Avancé</option>
                                  <option value="expert">Expert</option>
                                </select>
                                    </td>
                                    <td>
                                        <input type="number" [name]="'aduree'+i" [(ngModel)]="act.dureepratique">
                                    </td>
                                    <td>
                                        <i class="bi bi-person-fill action-icon mr-2" (click)="openModal(listRefModal, act)"></i>
                                        <i class="bi bi-plus-circle-fill action-icon" style="color: green;" (click)="openModal(addRefModal, act)"></i>
                                    </td>
                                    <td>
                                        <span class="mr-4">
                                    <i class="bi bi-eye-fill action-icon mr-2" *ngIf="act.document!=''" (click)="visualiser(act.document)"></i>
                                    <i class="bi bi-paperclip action-icon" (click)="openFileInput(fileInput, act)" *ngIf="act.document==''"></i>
                                    <i class="bi bi-x-circle-fill action-icon" style="color: red;" (click)="deleteDocument(act)" *ngIf="act.document!=''"></i>
                                </span>
                                        <span>
                                  <i class="bi bi-browser-chrome action-icon mr-2" (click)="openLink(act.lien)"></i>
                                  <i class="bi bi-plus-circle-fill action-icon mr-2" style="color: green;" (click)="openModal(linkModal, act)" *ngIf="act.lien==''"></i>
                                  <i class="bi bi-x-circle-fill action-icon" style="color: red;" (click)="deleteLink(act)" *ngIf="act.lien!=''"></i>
                                </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </div>

        <div class="row mt-5">

            <div class="col-md-6">
                <div class="container-fluid mt-2 mb-5">

                    <div class="table-container table-responsive">
                        <div class="w-100 text-center py-1 text-danger" style="font-size: 23px; background-color: #EFEEEE; font-weight: 700;">
                            Secteurs
                        </div>
                        <table id="element" class="table table-striped table-hover">
                            <thead>
                                <tr class="table-secondary">
                                    <th scope="col">
                                        Secteur
                                    </th>

                                    <th scope="col">
                                        Durée pratique
                                    </th>

                                    <th scope="col">
                                        Référence
                                    </th>

                                    <th scope="col">
                                        Documents et liens
                                    </th>
                                </tr>
                            </thead>
                            <tbody style="position: relative;">
                                <tr *ngFor="let sect of consultant.competencessecteurs; let i=index;">
                                    <td class="font-weight-bold">{{sect.intitule}}</td>
                                    <td>
                                        <input type="number" [name]="'sduree'+i" [(ngModel)]="sect.dureepratique">
                                    </td>
                                    <td>
                                        <i class="bi bi-person-fill action-icon mr-2" (click)="openModal(listRefModal, sect)"></i>
                                        <i class="bi bi-plus-circle-fill action-icon" style="color: green;" (click)="openModal(addRefModal, sect)"></i>
                                    </td>
                                    <td>
                                        <span class="mr-4">
                                    <i class="bi bi-eye-fill action-icon mr-2" *ngIf="sect.document!=''" (click)="visualiser(sect.document)"></i>
                                    <i class="bi bi-paperclip action-icon" (click)="openFileInput(fileInput, sect)" *ngIf="sect.document==''"></i>
                                    <i class="bi bi-x-circle-fill action-icon" style="color: red;" (click)="deleteDocument(sect)" *ngIf="sect.document!=''"></i>
                            </span>
                                        <span>
                              <i class="bi bi-browser-chrome action-icon mr-2" (click)="openLink(sect.lien)"></i>
                                <i class="bi bi-plus-circle-fill action-icon mr-2" style="color: green;" (click)="openModal(linkModal, sect)" *ngIf="sect.lien==''"></i>
                                  <i class="bi bi-x-circle-fill action-icon" style="color: red;" (click)="deleteLink(sect)"  *ngIf="sect.lien!=''"></i>
                            </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>

            <div class="col-md-6">
                <div class="container-fluid mt-2 mb-5">

                    <div class="table-container table-responsive">
                        <div class="w-100 text-center py-1 text-danger" style="font-size: 23px; background-color: #EFEEEE; font-weight: 700;">
                            Clients
                        </div>
                        <table id="element" class="table table-striped table-hover">
                            <thead>
                                <tr class="table-secondary">
                                    <th scope="col">
                                        Client
                                    </th>

                                    <th scope="col">
                                        Durée pratique
                                    </th>

                                    <th scope="col">
                                        Référence
                                    </th>

                                    <th scope="col">
                                        Documents et liens
                                    </th>
                                </tr>
                            </thead>
                            <tbody style="position: relative;">
                                <tr *ngFor="let client of consultant.competencesclients; let i=index;">
                                    <td class="font-weight-bold">{{client.intitule}}</td>
                                    <td>
                                        <input type="number" [name]="'cduree'+i" [(ngModel)]="client.dureepratique">
                                    </td>
                                    <td>
                                        <i class="bi bi-person-fill action-icon mr-2" (click)="openModal(listRefModal, client)"></i>
                                        <i class="bi bi-plus-circle-fill action-icon" style="color: green;" (click)="openModal(addRefModal, client)"></i>
                                    </td>
                                    <td>
                                        <span class="mr-4">
                                    <i class="bi bi-eye-fill action-icon mr-2" *ngIf="client.document!=''" (click)="visualiser(client.document)"></i>
                                    <i class="bi bi-paperclip action-icon" (click)="openFileInput(fileInput, client)" *ngIf="client.document==''"></i>
                                    <i class="bi bi-x-circle-fill action-icon" style="color: red;" (click)="deleteDocument(client)" *ngIf="client.document!=''"></i>
                                </span>
                                        <span>
                                  <i class="bi bi-browser-chrome action-icon mr-2" (click)="openLink(client.lien)"></i>
                                  <i class="bi bi-plus-circle-fill action-icon mr-2" style="color: green;" (click)="openModal(linkModal, client)" *ngIf="client.lien==''"></i>
                                  <i class="bi bi-x-circle-fill action-icon" style="color: red;" (click)="deleteLink(client)" *ngIf="client.lien!=''"></i>
                                </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>

        </div>

    </div>

    <div class="d-flex justify-content-center">
        <button class="btn btn-success" (click)="update()">
            Enregistrer
        </button>
    </div>

    <input (change)="onFilechange($event)" type="file" style="display: none;" #fileInput>

</div>

<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="meduim" color="#fff" type="ball-pulse-sync">
    <p style="font-size: 15px; color: white">Patientez...</p>
</ngx-spinner>

<ng-template #linkedin class="modal fade" id="update" role="dialog" style="border:1px solid black">
    <div class="modal-header" style="background-color: whitesmoke;">

        <h4 class="modal-title" style="margin-left:120px;">Modifier le lien</h4>
        <button type="button" class="close" data-dismiss="modal" (click)="CancelModal()">&times;</button>
    </div>
    <div class="modal-body" style="background-color: whitesmoke;">
        <form role="form">
            <div class="form-group">
                <input [ngModelOptions]="{standalone: true}" mdbInput type="text" class="form-control" style="display: block;" [(ngModel)]="consultant.linkedin" name="linkedin">
            </div>
            <button (click)="changeLinkedin()" type="submit" id="boutton" class="btn btn-success btn-block" style="width: 150px;margin:auto;font-size: small;">Modifier</button>
        </form>
    </div>
    <div class="modal-footer" style="background-color: whitesmoke;">
        <button type="submit" class="btn btn-danger btn-sm" style="margin:auto;display:block;width: 100px;" data-dismiss="modal" (click)="cancelrappel()">Cancel</button>
    </div>
</ng-template>

<ng-template #cvmodal class="modal fade" id="update" role="dialog" style="border:1px solid black">
    <div class="modal-header" style="background-color: whitesmoke;">

        <h4 class="modal-title" style="margin-left:120px;">Modifier le fichier</h4>
        <button type="button" class="close" data-dismiss="modal" (click)="CancelModal()">&times;</button>
    </div>
    <div class="modal-body" style="background-color: whitesmoke;">
        <form role="form">
            <div class="form-group">


                <input [ngModelOptions]="{standalone: true}" mdbInput class="form-control" id="inputAddressMD" style="display: block;padding: 0;" [ngClass]="{ 'is-invalid': submitted && f.cv.errors }" type="file" (change)="selectFile($event)" formControlName="cv" name="cv"
                    id="chcv">

            </div>
        </form>
    </div>
    <div class="modal-footer" style="background-color: whitesmoke;">
        <button type="submit" class="btn btn-danger btn-sm" style="margin:auto;display:block;width: 100px;" data-dismiss="modal" (click)="cancelcv()">Annuler</button>
        <button type="submit" class="btn  btn-sm" style="margin:auto;display:block;width: 100px;background-color: white;border: 1px solid black;" data-dismiss="modal" (click)="cancelobs()">Fermer</button>

    </div>
</ng-template>

<ng-template #addRefModal let-modal class="modal fade" role="dialog" style="border:1px solid black">
    <div class="modal-header text-center" style="background-color: whitesmoke;">
        <h5 class="text-center">Nouvelle référence</h5>
    </div>
    <div class="modal-body p-4" style="background-color: whitesmoke;">
        <form #addReferenceForm="ngForm" (ngSubmit)="addReference()">
            <div class="form-group">
                <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Société</label>
                <input type="text" class="form-control form-control-sm" name="societe" placeholder="Société" [(ngModel)]="reference.societe">
            </div>
            <div class="form-group row">
                <div class="col-md-6">
                    <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Nom</label>
                    <input type="text" class="form-control form-control-sm" name="nom" placeholder="Nom" [(ngModel)]="reference.nom" required>
                </div>
                <div class="col-md-6">
                    <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Prénom</label>
                    <input type="text" class="form-control form-control-sm" name="prenom" placeholder="Prénom" [(ngModel)]="reference.prenom">
                </div>
            </div>
            <div class="form-group">
                <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Fonction</label>
                <input type="text" class="form-control form-control-sm" name="fonction" [(ngModel)]="reference.fonction" required>
            </div>
            <div class="form-group">
                <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Téléphone</label>
                <input type="text" class="form-control form-control-sm" name="tel" [(ngModel)]="reference.telephone" required>
            </div>
            <div class="form-group">
                <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Email</label>
                <input type="email" class="form-control form-control-sm" name="email" [(ngModel)]="reference.email" required>
            </div>
            <div class="form-group">
                <label style="font-weight: bold; margin-bottom: 2px; text-align: left;">Adresse</label>
                <textarea type="text" class="form-control form-control-sm mt-1" rows="2" name="ad" placeholder="Adresse" [(ngModel)]="reference.adresse"></textarea>
            </div>

            <div class="d-flex justify-content-center mt-4">
                <button type="submit" class="btn btn-primary mr-4" [disabled]="!addReferenceForm.valid">Enregistrer</button>
                <button type="button" class="btn btn-secondary" (click)="cancelReferenceFrom()">Fermer</button>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #listRefModal let-modal class="modal fade" role="dialog" style="border:1px solid black">
    <div class="modal-header text-center" style="background-color: whitesmoke;">
        <h5 class="text-center">Références</h5>
    </div>
    <div class="modal-body p-4" style="background-color: whitesmoke;">
        <table class="table table-striped table-hover">
            <thead>
                <tr class="table-secondary">
                    <th>Nom</th>
                    <th>Prenom</th>
                    <th>Fonction</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of selectedItem.references">
                    <td>{{item.nom}}</td>
                    <td>{{item.prenom}}</td>
                    <td>{{item.fonction}}</td>
                    <td><i class="bi bi-trash-fill text-danger action-icon" (click)="removeReference(item)"></i></td>
                </tr>
            </tbody>
        </table>
        <div class="d-flex justify-content-center mt-4">
            <button type="button" class="btn btn-secondary" (click)="modal.close('click close button')">Fermer</button>
        </div>
    </div>
</ng-template>

<ng-template #linkModal let-modal class="modal fade" role="dialog" style="border:1px solid black">
    <div class="modal-header text-center" style="background-color: whitesmoke;">
        <h5 class="text-center">Ajouter un lien</h5>
    </div>
    <div class="modal-body p-4" style="background-color: whitesmoke;">
        <input type="text" class="form-control form-control-sm" [(ngModel)]="selectedItem.lien">
        <div class="d-flex justify-content-center mt-4">
            <button type="button" class="btn btn-primary mr-4" [disabled]="selectedItem.lien==''" (click)="modal.close('click close button')">Enregistrer</button>
            <button type="button" class="btn btn-secondary" (click)="cancelLink()">Fermer</button>
        </div>
    </div>
</ng-template>