<div class="d-flex justify-content-center">
    <div id="content">

        <p id="title"> Code de vérification</p>

        <p id="subtitle">Entrer le code de vérification à 6 chiffres reçu par email</p>

        <form #codeForm="ngForm" [formGroup]="form" (ngSubmit)="sendCode()">
            <div class="alert alert-danger error-section" *ngIf="error">{{error}}</div>
            <div class="form-group">
                <input formControlName="code" type="text" class="form-control" [class.status-error]="form.controls.code.invalid && form.controls.code.touched" [class.status-success]="form.controls.code.valid && form.controls.code.touched" placeholder="Code de vérification">
                <div *ngIf="(form.controls.code.invalid && form.controls.code.touched) || form.controls.code.dirty">
                    <small *ngIf="form.controls.code.errors?.required" class="text-danger">Vous devez entrer le code!</small>
                </div>
            </div>
            <div class="d-flex justify-content-center">
                <button class="btn btn-primary" type="submit" [disabled]="!codeForm.valid" [ngClass]="{'btn-disabled': !codeForm.valid}">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="spin"></span>
            Valider
        </button>
            </div>

            <div *ngIf="!(data === '')" class="resend">
                {{data}}
            </div>
        </form>
        <div class="another-action d-flex justify-content-between">
            <a [routerLink]="['../request-password']">Renvoyer le code</a>
        </div>


    </div>
</div>