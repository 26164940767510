import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SkillService } from 'src/app/services/consultants/skill.service';

@Component({
  selector: 'app-configs-subdomain',
  templateUrl: './configs-subdomain.component.html',
  styleUrls: ['./configs-subdomain.component.scss']
})
export class ConfigsSubdomainComponent {

  constructor(private skillService: SkillService, private modalService: NgbModal) {

  }

  addspin: boolean = false;
  deletespin: boolean = false;
  adderror = null;

  selected: any = null;

  tname: string = "";
  tdomain: string = "";

  page: number=0;
  currentPage: number = 1;
  size: number=10;
  sortDirection: string = 'DESC'
  sortby: string = "name";
  pages: number[] = [0,1];
  totalelements: number = 0;

  name: string = "";
  domain: string = "";

  subdomains: any[] = [];
  isLoading: boolean = true;

  domains: any[] = []


  ngOnInit(): void {
    this.getSubdomains();
    this.skillService.findAllDomains().subscribe(
      data => this.domains = [...data]
    )
  }

  change(){
    this.getSubdomains();
  }

  setPage(i: number, event: Event) {
    event.preventDefault();
    if(i>=0 && i<this.pages.length){
      this.page = i;
      this.currentPage = this.page+1;
      this.getSubdomains();
    }
  }

  changePage(){
    if(this.currentPage>0){
      this.page = this.currentPage - 1;
      this.getSubdomains();
    }
  }

  setSize(i: number) {
    this.size = i;
    this.getSubdomains();
  }

  setSort(sortby: string, sortDirection: string) {
    this.sortby = sortby;
    this.sortDirection = sortDirection;
    this.getSubdomains();
  }


  getSubdomains(): void{
    this.isLoading = true;
    this.skillService.getSubdomains(this.name, this.domain, this.sortby,this.sortDirection, this.page, this.size).subscribe(
      data => {
        this.subdomains = [...data.content];
        this.pages = new Array(data.totalPages);
        this.totalelements = data.numberOfElements;
        this.isLoading = false;
      },
      error => {
        this.isLoading = false
      }
    );
  }

  filter() {
    this.page = 0;
    this.currentPage = 1;
    this.getSubdomains();
  }


  addSubdomain(){
    if(this.tname == "" || this.tdomain == "") return;
    this.addspin = true;
    let data = {
      "name": this.tname,
      "domain": this.tdomain
    }
    this.skillService.addSubdomain(data).subscribe(
      data => {
        this.filter();
        this.addspin = false;
        this.tname = "";
        this.tdomain = "";
      },
      error => {
        this.addspin = false;
        this.adderror = error.error.message;
      }
    )
  }

  openModal(content: any, data: any){
    this.selected = {...data};
    this.adderror = null;
    this.modalService.open(content, {centered: true});
  }

  update(){
    if(this.selected == null || this.selected.name == "" || this.selected.domain == "") return ;
    this.addspin = true;
    this.skillService.updateSubdomain(this.selected).subscribe(
      data => {
        this.filter();
        this.addspin = false;
        this.modalService.dismissAll();
        this.selected = null;
      },
      error => {
        this.addspin = false;
        this.adderror = error.error.message;
      }
    )
  }

  delete(){
    this.deletespin = true;
    if(this.selected == null) return ;
    this.skillService.deleteSubdomain(this.selected.id).subscribe(
      data => {
        this.filter();
        this.deletespin = false;
        this.selected = null;
        this.modalService.dismissAll();
      },
      error => {
        this.deletespin = false;
      }
    )
  }

  select(domain:any){
    this.selected != domain ? this.selected = domain : this.selected = null;
  }

}
