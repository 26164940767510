<form class="container-form">


    <div>

        <label>Clients</label>

        <div style="height: 555px;overflow: auto;">
            <table style="border:1px solid black;">
                <tbody>
                    <tr *ngFor="let cli of Client">
                        <td><input style="width:5%; height:auto; transform: scale(1.5);" name="clientcheck" class="checkbox" type="checkbox" (change)="deleteclient(cli.libelle)" [checked]="client === cli.libelle">&nbsp;{{cli.libelle}}</td>
                        <td style="display: flex;justify-content: space-between;align-items: center;">


                            <p>{{cli.nbreConsultant}}</p>
                            <i (click)="showUpdateClient(cli,modalClient);" class="fa fa-pencil" style="font-size: 15px;cursor: pointer;"></i>
                            <img src="/assets/consultants.png" style="cursor: pointer;" (click)="seeConsultantsBytags(cli.libelle,'cli.libelle','Client')" width="25" height="25" alt="">

                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div style="margin-top: 5px;display: flex;">
            <input style="border:1px solid black;" type="text" name="addclient" placeholder="Sélectionner le client à supprimer" id="delclient">
            <input type="submit" value="-" class="bubtn btn-danger" name="delclient" (click)="Deleteclient()">
        </div>
        <div style="margin-top: 5px;display: flex;">
            <input style="border:1px solid black;" type="text" placeholder="Saisir le nouveau client..." id="addclient" name="client">
            <input type="submit" value="+" (click)="addClient()" class="bubtn btn-success">
        </div>
    </div>



    <div style="margin-left: 10vw;">

        <label>Disponibilité</label>

        <div style="overflow: auto;">
            <table style="border:1px solid black;">
                <tbody>
                    <tr *ngFor="let dispo of Dispo" style="display: flex;justify-content: space-between;align-items: center;">
                        <td style="width: 200px;"><input style="width:5%; height:auto; transform: scale(1.5);" name="dispocheck" class="checkbox" type="checkbox" (change)="deletedisponibilite(dispo.libelle)" [checked]="disponibilite === dispo.libelle">&nbsp;{{dispo.libelle}}</td>
                        <td style="display: flex;justify-content: space-between;align-items: center;">


                            <p style="margin-bottom: 0;margin-right: 20px;">{{dispo.ordre}}</p>
                            <p style="margin-bottom: 0;margin-right: 20px;width: 35px;">{{dispo.nbreConsultant}}</p>
                            <i (click)="showUpdateDispo(dispo,modal);" class="fa fa-pencil" style="font-size: 15px;cursor: pointer;"></i>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div style="margin-top: 5px;display: flex;">
            <input style="border:1px solid black;width: 93%;" type="text" name="deldispo" placeholder="Sélectionner la Disponibilité à supprimer" id="deldispo">
            <input type="submit" value="-" name="deldispobtn" class="bubtn btn-danger" (click)="DeleteDis()">
        </div>
        <div style="margin-top: 5px;display: flex;">
            <input style="border:1px solid black;width: 55%" name="addsispo" type="text" placeholder="Saisir la dispo..." [(ngModel)]="dispo.libelle" (change)="changeLibelle($event.target.value)" id="libelledispo">
            <input style="border:1px solid black;width: 38%" name="ordre" (change)="changeOrdre($event.target.value)" placeholder="Ordre" [(ngModel)]="dispo.ordre" type="number" min=1 type="number" id="ordre">
            <input type="submit" value="+" name="add" (click)="AjouterDisponibilite()" class="bubtn btn-success">
        </div>
    </div>


    <ng-template #modal class="modal fade" id="update" role="dialog" style="border:1px solid black">
        <div class="modal-header" style="background-color: whitesmoke;">

            <h4 class="modal-title" style="margin-left:120px;">Modifier une Disponibilité</h4>
            <button type="button" class="close" data-dismiss="modal" (click)="CancelModal()">&times;</button>
        </div>
        <div class="modal-body" style="background-color: whitesmoke;">
            <form role="form">
                <div class="form-group">
                    <label for="nom">Libelle</label>
                    <input type="text" class="form-control" value="{{updateDispo.libelle}}" [(ngModel)]="updateDispo.libelle" [ngModelOptions]="{standalone: true}" />
                </div>
                <button type="submit" id="boutton" class="btn btn-success btn-block" style="width: 150px;margin:auto;font-size: small;" (click)="updateDispos()">Modifier</button>
            </form>
        </div>
        <div class="modal-footer" style="background-color: whitesmoke;">
            <button type="submit" class="btn btn-danger btn-sm" style="margin:auto;display:block;width: 100px;" data-dismiss="modal" (click)="CancelModal()">Cancel</button>
        </div>
    </ng-template>
    <ng-template #modalClient class="modal fade" id="update" role="dialog" style="border:1px solid black">
        <div class="modal-header" style="background-color: whitesmoke;">

            <h4 class="modal-title" style="margin-left:120px;">Modifier un Client</h4>
            <button type="button" class="close" data-dismiss="modal" (click)="CancelModal()">&times;</button>
        </div>
        <div class="modal-body" style="background-color: whitesmoke;">
            <form role="form">
                <div class="form-group">
                    <label for="nom">Libelle</label>
                    <input type="text" class="form-control" value="{{updateClient.libelle}}" [(ngModel)]="updateClient.libelle" [ngModelOptions]="{standalone: true}" />
                </div>
                <button type="submit" id="boutton" class="btn btn-success btn-block" style="width: 150px;margin:auto;font-size: small;" (click)="updateClients()">Modifier</button>
            </form>
        </div>
        <div class="modal-footer" style="background-color: whitesmoke;">
            <button type="submit" class="btn btn-danger btn-sm" style="margin:auto;display:block;width: 100px;" data-dismiss="modal" (click)="CancelModal()">Cancel</button>
        </div>
    </ng-template>
</form>



<br><br><br><br>