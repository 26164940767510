import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConsultantProfileService } from 'src/app/services/consultants/consultant-profile.service';
import { TestService } from 'src/app/services/consultants/test.service';

@Component({
  selector: 'app-list-test',
  templateUrl: './list-test.component.html',
  styleUrls: ['./list-test.component.scss']
})
export class ListTestComponent {

  constructor(private modalService: NgbModal, private testService : TestService) {

  }

  addspin: boolean = false;
  deletespin: boolean = false;
  adderror: string = "";

  selected: any = null;

  page: number=0;
  currentPage: number = 1;
  size: number=10;
  sortDirection: string = 'DESC'
  sortby: string = "name";
  pages: number[] = [0,1];
  totalelements: number = 0;

  @Input() category: string = "";
  @Input() value: string = "";
  @Input() consultantid: string = "";

  name: string = "";
  domain: string = "";
  subdomain: string = "";
  technology: string = "";
  organism: string = "";

  tests: any[] = [];
  isLoading: boolean = true;

  technos: any[] = [];
  file: any = null;

  tcertificateurl: string = "";
  tdate: string = "";

  ngOnInit(): void {
    switch (this.category) {
      case "domain":
        this.domain = this.value
        break;
      case "subdomain":
        this.subdomain = this.value
        break;
      case "technology":
        this.technology = this.value
        break;
      default:
        break;
    }

    this.getTests();
  }

  selectFile(event: Event) {
    this.file = (event.target as HTMLInputElement).files![0];
  }

  change(){
    this.getTests();
  }

  setPage(i: number, event: Event) {
    event.preventDefault();
    if(i>=0 && i<this.pages.length){
      this.page = i;
      this.currentPage = this.page+1;
      this.getTests();
    }
  }

  changePage(){
    if(this.currentPage>0){
      this.page = this.currentPage - 1;
      this.getTests();
    }
  }

  setSize(i: number) {
    this.size = i;
    this.getTests();
  }

  setSort(sortby: string, sortDirection: string) {
    this.sortby = sortby;
    this.sortDirection = sortDirection;
    this.getTests();
  }


  getTests(): void{
    this.isLoading = true;
    this.testService.getTests(this.name, this.domain, this.subdomain, this.technology,
       this.organism, this.sortby,this.sortDirection, this.page, this.size).subscribe(
      data => {
        this.tests = [...data.content];
        this.pages = new Array(data.totalPages);
        this.totalelements = data.numberOfElements;
        this.isLoading = false;
      },
      error => {
        this.isLoading = false
      }
    );
  }

  filter() {
    this.page = 0;
    this.currentPage = 1;
    this.getTests();
  }


  openModal(content: any, data: any){
    this.selected = {...data};
    this.adderror = "";
    this.modalService.open(content, {centered: true});
  }

  select(domain:any){
    this.selected != domain ? this.selected = domain : this.selected = null;
  }

  saveTest(){
    this.adderror = "";
    if (this.tcertificateurl=="" && (this.file == undefined || this.file == null)){
      this.adderror = "Vous devez fournir au moyen un moyen de vérification (lien URL ou fichier de certificat)";
      return ;
    };
    if(this.tdate == "") {
      this.adderror = "La date de passage du test est obligatoire";
      return ;
    }
    if (this.tcertificateurl!="" && !this.isValidURL(this.tcertificateurl)) {
      this.adderror = "Entrez une URL valide";
      return ;
    }
    this.addspin = true;
    let data: FormData = new FormData();
    data.append("testid", this.selected.id);
    data.append("consultantid", this.consultantid);
    data.append("name", this.selected.name);
    data.append("domain", this.selected.domain);
    data.append("technologies", this.selected.technologies);
    data.append("subdomain", this.selected.subdomain);
    data.append("url", this.selected.url);
    data.append("organism", this.selected.organism);
    data.append("certificateurl", this.tcertificateurl);
    data.append("date", this.tdate);
    data.append("certificate", this.file);

    this.testService.addConsultantTest(data).subscribe(
      data => {
        this.modalService.dismissAll();
        this.addspin = false;
        this.file = null;
      },
      error => {
        this.adderror = "Une erreur est survenue lors de l'opération"
        this.addspin = false;
      }
    )
  }

  openUrl(url: string){
    window.open("//" +url, "_blank");
  }

  isValidURL(url: string) {
    var res = url.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    return (res !== null)
  };

}
