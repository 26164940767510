import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';


@Component({
  selector: 'app-request-password',
  templateUrl: './request-password.component.html',
  styleUrls: ['./request-password.component.scss']
})
export class RequestPasswordComponent implements OnInit {

  form: FormGroup;

  error = null;

  data: string = "";

  spin = false;

  constructor(private fb: FormBuilder, private auth: AuthService, private router: Router) {
    this.form = this.fb.group({
      email: ['', [
        Validators.required,
        Validators.email
      ]]
    });
  }

  ngOnInit(): void {
  }

  requestPassword(): void {
    this.spin = true;
    this.error = null;
    this.auth.requestPassword(this.form.value.email).subscribe(
      data => this.handleData(data),
      error => this.handleError(error)
    );
  }

  handleData(data:any): void {
    this.spin = false;
    this.auth.setUseremail(this.form.value.email);
    this.data = "Un lien de réinitialisation a été envoyé à votre adresse"
    this.router.navigateByUrl("/enter-code");
  }

  handleError(error: any): void {
    this.spin = false;
    this.error = error.error.message;
  }

}
