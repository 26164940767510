import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConsultantProfileService } from 'src/app/services/consultants/consultant-profile.service';
import { TrainingService } from 'src/app/services/consultants/training.service';

@Component({
  selector: 'app-consultant-training',
  templateUrl: './consultant-training.component.html',
  styleUrls: ['./consultant-training.component.scss']
})
export class ConsultantTrainingComponent {
  constructor(private modalService: NgbModal, private trainingService : TrainingService) {

  }

  addspin: boolean = false;
  deletespin: boolean = false;
  adderror = null;

  selected: any = null;

  page: number=0;
  currentPage: number = 1;
  size: number=10;
  sortDirection: string = 'DESC'
  sortby: string = "name";
  pages: number[] = [0,1];
  totalelements: number = 0;

  name: string = "";
  domain: string = "";
  subdomain: string = "";
  technology: string = "";
  organism: string = "";
  @Input() consultantid: string = "";
  date: string = "";

  trainings: any[] = [];
  isLoading: boolean = true;

  technos: any[] = [];

  ngOnInit(): void {
    this.getTrainings();
  }

  change(){
    this.getTrainings();
  }

  setPage(i: number, event: Event) {
    event.preventDefault();
    if(i>=0 && i<this.pages.length){
      this.page = i;
      this.currentPage = this.page+1;
      this.getTrainings();
    }
  }

  changePage(){
    if(this.currentPage>0){
      this.page = this.currentPage - 1;
      this.getTrainings();
    }
  }

  setSize(i: number) {
    this.size = i;
    this.getTrainings();
  }

  setSort(sortby: string, sortDirection: string) {
    this.sortby = sortby;
    this.sortDirection = sortDirection;
    this.getTrainings();
  }


  getTrainings(): void{
    this.isLoading = true;
    this.trainingService.getConsultantTraining(this.consultantid, this.name, this.domain, this.subdomain, this.technology,
       this.organism, this.date, this.sortby,this.sortDirection, this.page, this.size).subscribe(
      data => {
        this.trainings = [...data.content];
        this.pages = new Array(data.totalPages);
        this.totalelements = data.numberOfElements;
        this.isLoading = false;
      },
      error => {
        this.isLoading = false
      }
    );
  }

  filter() {
    this.page = 0;
    this.currentPage = 1;
    this.getTrainings();
  }


  openModal(content: any, data: any){
    this.selected = {...data};
    this.adderror = null;
    this.modalService.open(content, {centered: true});
  }

  select(domain:any){
    this.selected != domain ? this.selected = domain : this.selected = null;
  }

  download(path: string){
    this.trainingService.download(path).subscribe(
      blob => {
        let fileURL = window.URL.createObjectURL(blob);
        window.open(fileURL);
      },
      error => console.log(error)
    );
  }

  openUrl(url: string){
    window.open("//" +url, "_blank");
  }
}
