import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { AlertEmptyComponent } from 'src/app/alert-empty/alert-empty.component';
import { ProgressSpinnerDialogComponent } from 'src/app/progress-spinner-dialog/progress-spinner-dialog.component';
import { ConsultantService } from 'src/app/services/consultant.service';
import { OpportuniteService } from 'src/app/services/consultants/opportunite.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';

@Component({
  selector: 'app-opporutnite-consultant',
  templateUrl: './opporutnite-consultant.component.html',
  styleUrls: ['./opporutnite-consultant.component.scss']
})
export class OpportuniteConsultantComponent implements OnInit {

  today = new Date().toJSON().split('T')[0];
  opportunites: any[] = [];
  userid: string = "";

  // header du tableau
  sortBy: string = "date";
  sortDirection = "DESC";
  page: number=0;
  currentPage: number = 1;
  size: number=10;
  pages: number[] = [0];
  totalelements: number = 0;
  tdate = "";
  tdateouverture = "";
  tclient = "";
  tcontact = "";
  ttelcontact = "";
  temailcontact = "";
  tdescription = "";
  tcompetences = "";
  tdebutmission = "";
  tobservations = "";


  // Formulaire d'ajout
  dateouverture: string = "";
  client: string = "";
  contact: string = "";
  emailcontact: string = "";
  telcontact: string = "";
  description: string = "";
  competences: string = "";
  niveau: string = "";
  debutmission: string = "";
  observations: string = "";
  file: any = null;

  constructor(
    private dialog: MatDialog,
    private tokenStorageService: TokenStorageService,
    private opService: OpportuniteService,
    private cs: ConsultantService
  ) { }

  ngOnInit(): void {
    this.Loader();
    const user = this.tokenStorageService.getUser();
    // this.trigramme = user.trigramme;
    this.userid = user.id;
    this.filterOpportunites();
  }

  setSort(sortcolumn: string){
    if(this.sortBy == sortcolumn){
      this.sortDirection == "ASC" ? this.sortDirection = "DESC" : this.sortDirection = "ASC";
    } else {
      this.sortBy = sortcolumn
      this.sortDirection = "ASC";
    }
    this.filterOpportunites();
  }

  setPage(i: number, event: Event) {
    event.preventDefault();
    if(i>=0 && i<this.pages.length){
      this.page = i;
      this.currentPage = this.page+1;
      this.filterOpportunites();
    }
  }

  changePage(){
    if(this.currentPage>0 && this.currentPage<=this.pages.length){
      this.page = this.currentPage - 1;
      this.filterOpportunites();
    }
  }

  setSize(i: number) {
    this.size = i;
    this.filterOpportunites();
  }

  filterOpportunites(){
    this.opService.getOpportunites("", this.userid, this.tdate, this.tdateouverture, this.tclient, this.tcontact,
    this.ttelcontact, this.temailcontact, this.tdescription, this.tcompetences, this.tdebutmission, this.tobservations,
    this.sortBy, this.sortDirection, this.page.toString(), this.size.toString()).subscribe(
      (data) => {
        this.opportunites = [...data.content];
        this.pages = new Array(data.totalPages);
        this.totalelements = data.numberOfElements;
        this.dialog.closeAll();
      },
      (error) => {
        this.Alert("Erreur survenue lors du chargement des opportunités");
        this.dialog.closeAll();
      }
    )
  }

  selectQualifColor(opportunite){
    switch (opportunite.qualifie) {
      case "NON":
        return "red"
        break;
      case "OUI":
        return "green"
        break;

      default:
        break;
    }
  }

  selectGagneColor(opportunite){
    switch (opportunite.gagne) {
      case "NON":
        return "red"
        break;
      case "OUI":
        return "green"
        break;

      default:
        break;
    }
  }

  onFilechange(event){
    this.file = event.target.files[0];
  }

  downloadFile(path: string){
    this.cs.downloadFile(path).subscribe(
      (blob) => {
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, '_blank');
      },
      error => {
        console.log(error)
        this.dialog.closeAll();
      }
    )
  }

  addOpportunite(){
    if(this.client == "" || this.contact == "") {
      this.Alert("Vous devez entrer le client et le contact");
    } else {
      this.Loader()
      const formData = new FormData();
      formData.append("client", this.client);
      formData.append("contact", this.contact);
      formData.append("emailcontact", this.emailcontact);
      formData.append("telcontact", this.telcontact);
      formData.append("dateouverture", this.dateouverture);
      formData.append("description", this.description);
      formData.append("competences", this.competences);
      formData.append("niveau", this.niveau);
      formData.append("debutmission", this.debutmission);
      formData.append("observations", this.observations);
      formData.append("file", this.file);
      formData.append("userid", this.userid);
      this.opService.addOpportunite(formData).subscribe(
        data => {
          this.filterOpportunites();
        },
        error => {
          this.dialog.closeAll();
          this.Alert("Erreur lors de l'ajout de l'élément;")
        }
      )
    }
  }

  Loader() {
    this.dialog.open(ProgressSpinnerDialogComponent, {
      data: {
        message: `
       Chargement en Cours`,
      },
      disableClose: true,
    });
  }

  Alert(msg) {
    const dialogRef = this.dialog.open(AlertEmptyComponent, {
      data: {
        message: msg,
        buttonText: {
          cancel: "OK",
        },
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

}
