import { Component, OnInit } from '@angular/core';
import { CommunicationService } from '../services/communication.service';
import { User } from '../Model/user';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { AlertEmptyComponent } from '../alert-empty/alert-empty.component';
import { AuthService } from '../services/auth.service';
import { TokenStorageService } from '../services/token-storage.service';
import { ProgressSpinnerDialogComponent } from '../progress-spinner-dialog/progress-spinner-dialog.component';
import {Title} from "@angular/platform-browser";
function delay(ms: number) {
  return new Promise( resolve => setTimeout(resolve, ms) );
}

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  userup = new User();

  id: String;

  Users: any = [];

  test: String = "Ok";

  registration = false;
  f8: any = [];
  f82: any = [];
  role: string;
  rolecrm: string;

  form: any = {};
  isSuccessful = false;
  isSignUpFailed = false;
  errorMessage = '';


  constructor(private us:AuthService,
    public communicationService: CommunicationService,
    private router: Router,private dialog: MatDialog,
    private titleService:Title,
    private tokenStorageService: TokenStorageService) {
    this.communicationService.Broadcast('OnChild5', 'Gestion des utilisateurs');
   }

  ngOnInit() {
    this.loader()
    this.Users = [];
    this.getUsers();
    this.titleService.setTitle("Portail Consultant")
    if(!this.us.canAdmin()){
      this.router.navigate(['/dashboard']);
    }
  }
  cancel(){
    this.registration=false
  }

  //Réupération de l'ensemble des utilisateurs
  getUsers() {
    this.us.getUsers().subscribe(data=>{

      for(const i in data){
        let user = new User();
        user.id = data[i].id;
        user.nom = data[i].nom;
        user.prenom = data[i].prenom;
        user.email = data[i].email;
        user.trigramme = data[i].trigramme;
        user.rolesrh = data[i].roles[0].name;
        data[i].droitcrm.length>0 ? user.rolecrm = data[i].droitcrm[0].name : user.rolecrm = "";
        this.Users.push(user);
      }
    })
    this.dialog.closeAll()
  }

  Reg(){
    this.registration = true;
  }

  liste(){
    this.registration = false;
  }

  DeleteUser(user){
    const utilisateur = this.tokenStorageService.getUser();
    if(user.email === utilisateur.email){
      this.openAlertWarning();
    }
    else{
      this.us.delete(user.id).subscribe(result => {
      });
      (async () => {
        const dialogRef = this.dialog.open(ProgressSpinnerDialogComponent, {
          data: {
            message: "Chargement en cours..."
          },
        });
        await delay(1000);
        dialogRef.close();
        this.ngOnInit();
      })();
    }
    this.ngOnInit();
  }

  openAlertWarning() {
    const dialogRef = this.dialog.open(AlertEmptyComponent, {
      data: {
        message: "Attention : Vous ne pouvez supprimer un utilisateur actif !",
        buttonText: {
          cancel: 'Retour'
        }
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
    });
  }


  onSubmit() {
    this.f8 = []
    this.f82 = []

    this.form.roles = [];
    this.form.rolescrm =[]

    this.role = (<HTMLInputElement>document.getElementById("rolerh")).value;
    this.rolecrm = (<HTMLInputElement>document.getElementById("rolecrm")).value;
    if(this.role != "") {
      this.f8.push(this.role);
    }
    this.form.roles = this.f8;

    if(this.rolecrm != ""){
      this.f82.push(this.rolecrm);
    }
    this.form.rolescrm = this.f82;
    this.us.register(this.form).subscribe(
      data => {
        this.isSuccessful = true;
        this.isSignUpFailed = false;

        (async () => {
          const dialogRef = this.dialog.open(ProgressSpinnerDialogComponent, {
            data: {
              message: "Chargement en cours..."
            },
          });
          await delay(1000);
          dialogRef.close();
          this.form={}

          this.ngOnInit();
        })();
      },
      err => {
        this.errorMessage = err.error.message;
        this.isSignUpFailed = true;
        this.popup(this.errorMessage)
      }
    );
  }

  popup(er) {
    const dialogRef = this.dialog.open(ProgressSpinnerDialogComponent, {
      data: {
        message: er,
        buttonText: {
          cancel: 'Retour'
        }
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
    });
  }

  loader(){
    this.dialog.open(ProgressSpinnerDialogComponent, {
      data: {

        message: `
         Chargement en cours`

      },
      disableClose: true,
    });
  }
}
