<div class="d-flex justify-content-center">
    <div id="content">

        <h1 id="title">Nouveau mot de passe</h1>
        <p id="subtitle">Entrer votre nouveau mot de passe.</p>

        <form #resetForm="ngForm" [formGroup]="form" (ngSubmit)="resetPassword()">
            <div class="alert alert-danger error-section" *ngIf="error">{{error}}</div>
            <div class="form-group">
                <label>Mot de passe</label>
                <input formControlName="password" type="password" class="form-control" placeholder="Mot de passe" [class.status-error]="form.controls.password.invalid && form.controls.password.touched" [class.status-success]="form.controls.password.valid && form.controls.password.touched">
                <div *ngIf="(form.controls.password.invalid && form.controls.password.touched) || form.controls.password.dirty">
                    <small *ngIf="form.controls.password.errors?.required" class="text-danger">Le mot de passe est requis!</small>
                    <small *ngIf="form.controls.password.errors?.minlength && !form.controls.password.errors?.strong" class="text-danger">Le mot de passe doit contenir au moins six caractères</small>
                    <small *ngIf="form.controls.password.errors?.strong && !form.controls.password.errors?.required" class="text-danger">{{form.controls.password.errors?.strong}}</small>
                </div>
            </div>
            <div class="form-group">
                <label>Confirmer le mot de passe</label>
                <input formControlName="confirm" type="password" class="form-control" placeholder="Confirmer le mot de passe" [class.status-error]="form.controls.confirm.invalid && form.controls.confirm.touched" [class.status-success]="form.controls.confirm.valid && form.controls.confirm.touched">
                <div *ngIf="(form.controls.confirm.invalid && form.controls.confirm.touched) || form.controls.confirm.dirty">
                    <small *ngIf="form.controls.confirm.errors?.required" class="text-danger">La confirmation du mot de passe est requise.</small>
                    <small *ngIf="form.controls.confirm.errors?.confirmation" class="text-danger">{{form.controls.confirm.errors?.confirmation}}</small>
                </div>
            </div>
            <div class="d-flex justify-content-center">
                <button class="btn btn-primary" type="submit" [disabled]="!resetForm.valid" [ngClass]="{'btn-disabled': !resetForm.valid}">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="spin"></span>
              Enregistrer
          </button>
            </div>
        </form>

        <div class="another-action d-flex justify-content-between">
            <a [routerLink]="['../login']">connexion</a>
        </div>

    </div>
</div>