import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-enter-code',
  templateUrl: './enter-code.component.html',
  styleUrls: ['./enter-code.component.scss']
})
export class EnterCodeComponent implements OnInit {

  form: FormGroup;

  error = null;

  data: string = "";

  spin = false;
  email: string = "";

  constructor(private fb: FormBuilder, private auth: AuthService, private router: Router) {
    this.form = this.fb.group({
      code: ['', [
        Validators.required,
      ]]
    });
  }

  ngOnInit(): void {
    this.email = this.auth.getUseremail();
    if(this.email == ""){
      this.router.navigateByUrl("/login");
    }
  }

  sendCode(): void {
    this.spin = true;
    this.error = null;
    this.auth.sendCode(this.email, this.form.value.code).subscribe(
      data => this.handleData(data),
      error => this.handleError(error)
    );
  }

  handleData(data:any): void {
    this.spin = false;
    this.auth.setUserid(data["user_id"]);
    this.auth.setTokenid(data["token_id"]);
    this.router.navigateByUrl("/reset-password");
  }

  handleError(error: any): void {
    this.spin = false;
    this.error = error.error.message;
  }

}
