<div style="display: flex;justify-content: space-around;">

    <div style="display: flex;">

        <div style="margin: auto;max-width: 1400px;">
            <div class="tableFixHead" style="margin-top: 25px;padding-bottom: 20px;height: 92vh; overflow-y: scroll;">
                <table class="table table-bordered" style="width: 100%;border: 1px solid black;margin-left: 0;">
                    <thead style="text-align: center;background-color: #EFEDEE;">
                        <tr>
                            <th colspan="10" style="color: red;font-size: 20px;">Liste des utilisateurs</th>
                        </tr>
                        <tr style="text-align: center;">
                            <th style="max-width:150px ;font-size: 15px;width: 150px;">Nom</th>
                            <th style="max-width: 110px;font-size: 15px;width: 150px;">Prenoms</th>
                            <th style="max-width:125px ;font-size: 15px;width: 150px;">Trigramme</th>

                            <th style="max-width: 36px;font-size: 15px;width: 180px;">Email</th>
                            <th style="max-width: 250px;font-size: 15px;width: 150px;">Mot de passe </th>
                            <th style="max-width:125px ;font-size: 15px;width: 180px;">Droits RH</th>

                            <th style="max-width: 36px;font-size: 15px;width: 180px;">Droits CRM</th>
                            <th style="max-width: 250px;font-size: 15px;width: 150px;">Supprimer </th>

                        </tr>
                    </thead>
                    <tbody>

                        <tr *ngFor="let user of Users" style="font-size: 15px;text-align: center;">
                            <td style="text-align: left;">{{user.nom}}</td>
                            <td style="text-align: left;">{{user.prenom}}</td>
                            <td>{{ user.trigramme }}</td>
                            <td style="text-align: left;">{{user.email}}</td>
                            <td>***********</td>

                            <td>{{ user.rolesrh }}</td>
                            <td>{{ user.rolecrm }}</td>


                            <td style="display: flex;justify-content: center;align-items: center;">
                                <img (click)="DeleteUser(user)" style="cursor: pointer;" src="../../assets/del.png" alt="">
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <input type="text" style="width: 150px;height: 40px;" placeholder="Nom" type="nom" class="form-control" name="nom" [(ngModel)]="form.nom" required minlength="6" #nom="ngModel">
                            </td>
                            <td>
                                <input type="text" style="width: 150px;height: 40px;" placeholder="Prenom" type="prenom" class="form-control" name="prenom" [(ngModel)]="form.prenom" required minlength="6" #prenom="ngModel">
                            </td>
                            <td>
                                <input type="text" style="width: 150px;height: 40px;" placeholder="Trigramme" type="trigramme" class="form-control" name="trigramme" [(ngModel)]="form.trigramme" required minlength="3" maxlength="3" #trigramme="ngModel" />

                            </td>
                            <td>
                                <input type="text" style="width: 180px;height: 40px;" placeholder="Email" type="email" class="form-control" name="email" [(ngModel)]="form.email" required email #email="ngModel">

                            </td>
                            <td>
                                <input type="text" style="width: 150px;height: 40px;" placeholder="Mot de passe" type="password" class="form-control" name="password" [(ngModel)]="form.password" required minlength="6" #password="ngModel">
                            </td>
                            <td>
                                <select style="width: 110px;" class="browser-default custom-select" id="rolerh">

              <option value=""></option>
               <option value="Gestionnaire">Gestionnaire</option>
               <option value="Facturation">Facturation</option>
              <option value="Lecture">Lecture</option>
              <option value="Ecriture">Ecriture</option>
          </select>
                            </td>
                            <td>
                                <select style="width: 110px;" class="browser-default custom-select" id="rolecrm">

              <option value=""></option>
               <option value="Gestionnaire">Gestionnaire</option>
              <option value="Lecture">Lecture</option>
              <option value="Ecriture">Ecriture</option>
          </select>
                            </td>
                            <td style="display: flex;justify-content: center;align-items: center;">

                                <img (click)="onSubmit()" style="cursor: pointer;" src="../../assets/add.png" alt="">
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>
            <div style="display: flex;">

            </div>
        </div>

    </div>

</div>
