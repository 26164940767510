<div *ngIf="modified" class="alert alert-success" role="success" style="margin-top: 5em;width: 50%;margin-left: 25%;border-left: 4px solid red;">

    Vous avez modifié votre adresse mail. <br> Vous devez authentifier de nouveau pour utiliser cette application

</div>
<div *ngIf="isLoginFailed" class="alert alert-danger" role="alert" style="margin-top: 2em;width: 50%;margin-left: 25%;border-left: 4px solid red;">

    Une erreur est survenue <br> le mot de passe saisi est incorrect ou l'adresse email n'existe pas

</div>
<div *ngIf="isLoginFailed2" class="alert alert-danger" role="alert" style="margin-top: 2em;width: 50%;margin-left: 25%;border-left: 4px solid red;">

    Une erreur est survenue <br> Vous n'avez pas la possibilité de vous connecter à cette application

</div>
<div class="col justify-content-center" style="margin-top: 10em;" [ngStyle]="isLoginFailed && {'margin-top': '2em'}||!isLoginFailed && {'margin-top': '10em'}">
    <p style="text-align: center;"><img height="35px" src="../assets/RH.png" alt=""></p>
    <h2 style="text-align:center;margin-top: 3vw;font-weight: bold;margin-bottom: 3vw;">Portail Consultant</h2>
    <form class="form" name="form" style="max-width: 500px;align-self: center;justify-self: center;margin: auto;" (ngSubmit)="onSubmit()" [formGroup]="registerForm">

        <label>Adresse e-mail</label>
        <div class="input-group intput-lg mb-4">

            <div class="input-group-append">
                <span class="input-group-text bg-transparent"> <i class="fa fa-user"></i></span>
            </div>
            <input type="text" formControlName="email" class="form-control" placeholder="saisir l'email" style="clear: left;" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />

        </div>

        <p *ngIf="submitted && f.email.errors">
            <span *ngIf="f.email.errors.required" style="color: #dc3545;"> Email est obligatoire</span>
            <span *ngIf="f.email.errors.email" style="color: #dc3545;"> Email doit etre une adresse email valide</span>

        </p>
        <div class="d-flex justify-content-between">
            <label>Mot de passe</label>
            <a [routerLink]="['../request-password']" style="font-size: smaller;margin-left: 17vw;"> Mot de passe oublié?</a>
        </div>
        <div class="input-group intput-lg mb-4">
            <div class="input-group-append">
                <span class="input-group-text bg-transparent"> <i class="fa fa-lock"></i></span>
            </div>
            <input type="password" class="form-control" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" placeholder="saisir le mot de passe" name="password" requiered>

        </div>
        <p *ngIf="submitted && f.password.errors">
            <span *ngIf="f.password.errors.required" style="color: #dc3545;">Mot de passe obligatoire</span>
        </p>
        <input type="checkbox"> Rester connecté <br><br>

        <button type="submit" class="btn btn-primary btn-m btn-block ">Connecter</button>
        <br>
        <div class="text-center w-100 font-weight-bold">OU</div>
        <br>
        <button class="btn btn-success btn-m btn-block" (click)="goToRegister()">Inscription consultant</button>
        <br><br><br>


    </form>
</div>
<div *ngIf="isLoggedIn" class="alert alert-success" role="alert" style="width: 40%;margin-left: 30%;color: white;background-color: #70B876;">
    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
			 <span aria-hidden="true">&times;</span>
		   </button>
    <img src="assets/valider.png" style="float: left;margin-right: 5%;margin-top: 1%;">
    <div>
        Login<br> vous avez été identifié avec succes
    </div>

</div>
