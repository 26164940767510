<div class="d-flex justify-content-center" style="margin-top: 10em;">

    <div class="col-lg-3 col-md-4">

        <div class="text-danger text-center h3 font-weight-bolder mb-3">Créer un Compte Consultant</div>
        <div class="card">
            <div class="card-body">

                <form #registerForm="ngForm" [formGroup]="form" (ngSubmit)="register()">
                    <div class="alert alert-danger error-section" *ngIf="error">{{error}}</div>
                    <div class="alert alert-success error-section" *ngIf="success">
                        Votre compte a été créé avec succès. <a [routerLink]="['../login']">Connectez-vous!</a>
                    </div>
                    <div class="form-group">
                        <label>Nom(s)</label>
                        <div class="input-group">
                            <div class="input-group-append">
                                <span class="input-group-text bg-transparent"> <i class="bi bi-person-fill"></i></span>
                            </div>
                            <input formControlName="lastname" type="text" class="form-control " [class.status-error]="form.controls.lastname.invalid && form.controls.lastname.touched" [class.status-success]="form.controls.lastname.valid && form.controls.lastname.touched" placeholder="Nom">
                        </div>
                        <div *ngIf="(form.controls.lastname.invalid && form.controls.lastname.touched) || form.controls.lastname.dirty">
                            <small *ngIf="form.controls.lastname.errors?.required" class="text-danger">Votre nom est requis.</small>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Prénom(s)</label>
                        <div class="input-group">
                            <div class="input-group-append">
                                <span class="input-group-text bg-transparent"> <i class="bi bi-person-fill"></i></span>
                            </div>
                            <input formControlName="firstname" type="text" class="form-control " [class.status-error]="form.controls.firstname.invalid && form.controls.firstname.touched" [class.status-success]="form.controls.firstname.valid && form.controls.firstname.touched" placeholder="Prénom">
                        </div>
                        <div *ngIf="(form.controls.firstname.invalid && form.controls.firstname.touched) || form.controls.firstname.dirty">
                            <small *ngIf="form.controls.firstname.errors?.required" class="text-danger">Votre prénom est requis.</small>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Email</label>
                        <div class="input-group">
                            <div class="input-group-append">
                                <span class="input-group-text bg-transparent"> <i class="bi bi-envelope-fill"></i></span>
                            </div>
                            <input formControlName="email" type="email" class="form-control " [class.status-error]="form.controls.email.invalid && form.controls.email.touched" [class.status-success]="form.controls.email.valid && form.controls.email.touched" placeholder="Adresse mail">
                        </div>
                        <div *ngIf="(form.controls.email.invalid && form.controls.email.touched) || form.controls.email.dirty">
                            <small *ngIf="form.controls.email.errors?.required" class="text-danger">L'adresse mail est requise.</small>
                            <small *ngIf="form.controls.email.errors?.email" class="text-danger">Entrer une adresse mail valide.</small>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Mot de passe</label>
                        <div class="input-group">
                            <div class="input-group-append">
                                <span class="input-group-text bg-transparent"> <i class="bi bi-lock-fill"></i></span>
                            </div>
                            <input formControlName="password" type="password" class="form-control " placeholder="Password" [class.status-error]="form.controls.password.invalid && form.controls.password.touched" [class.status-success]="form.controls.password.valid && form.controls.password.touched">
                        </div>
                        <div *ngIf="(form.controls.password.invalid && form.controls.password.touched) || form.controls.password.dirty">
                            <small *ngIf="form.controls.password.errors?.required" class="text-danger">Le mot de passe est requis!</small>
                            <small *ngIf="form.controls.password.errors?.minlength && !form.controls.password.errors?.strong" class="text-danger">Le mot de passe doit avoir au minimum 6 caractères</small>
                            <small *ngIf="form.controls.password.errors?.strong && !form.controls.password.errors?.required" class="text-danger">{{form.controls.password.errors?.strong}}</small>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Confirmer le mot de passe</label>
                        <input formControlName="confirm" type="password" class="form-control " placeholder="Confirmer le mot de passe" [class.status-error]="form.controls.confirm.invalid && form.controls.confirm.touched" [class.status-success]="form.controls.confirm.valid && form.controls.confirm.touched">
                        <div *ngIf="(form.controls.confirm.invalid && form.controls.confirm.touched) || form.controls.confirm.dirty">
                            <small *ngIf="form.controls.confirm.errors?.required" class="text-danger">La confirmation de mot de passe est requise</small>
                            <small *ngIf="form.controls.confirm.errors?.confirmation" class="text-danger">{{form.controls.confirm.errors?.confirmation}}</small>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Saisir le code du parrain</label>
                        <div class="input-group">
                            <div class="input-group-append">
                                <span class="input-group-text bg-transparent"> <i class="bi bi-people-fill"></i></span>
                            </div>
                            <input formControlName="codeParrain" type="text" class="form-control " [class.status-error]="form.controls.codeParrain.invalid && form.controls.codeParrain.touched" [class.status-success]="form.controls.codeParrain.valid && form.controls.codeParrain.touched">
                        </div>
                    </div>
                    <div class="d-flex justify-content-center">
                        <button class="btn btn-primary w-100" type="submit" [disabled]="!registerForm.valid && !success" [ngClass]="{'btn-disabled': !registerForm.valid || form.value.agreement === false}">
                            <span class="spinner-border spinner-border-sm mt-1" role="status" aria-hidden="true" *ngIf="spin"></span>
                            Créer mon compte
                        </button>
                    </div>
                </form>

                <div class="another-action">Vous avez déjà un compte? <a [routerLink]="['../login']">Connexion</a></div>

            </div>
        </div>
    </div>

</div>
