import { Component, OnInit } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute} from '@angular/router';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  strongPasswordValidation(control: FormControl): { strong: string } | null {
    const hasNumber = /\d/.test(control.value);
    const hasUpper = /[A-Z]/.test(control.value);
    const hasLower = /[a-z]/.test(control.value);

    const valid = hasNumber && hasUpper && hasLower;
    if (!valid) {
      return {
        strong: 'Votre mot de passe doit contenir au moins une majuscule, une minuscule et un nombre.'
      };
    }
    return null;
  }

  confirmPasswordValidation(control: FormControl): { confirmation: string } | null {
    if ((control.value !== '') && (control.root.get('password')?.value !== '') && (control.value !== control.root.get('password')?.value)) {
      return { confirmation: 'Le mot de passe et la confirmation ne correspondent pas.' };
    }

    return null;
  }

  user_id: any;
  token_id: any;

  form!: FormGroup;

  error = null;

  spin = false;

  constructor(private fb: FormBuilder, private auth: AuthService, private router: Router, private route: ActivatedRoute) {
    this.form = this.fb.group({
      password: ['', [
        Validators.required,
        Validators.minLength(6),
        this.strongPasswordValidation
      ]],
      confirm: ['', [
        Validators.required,
        this.confirmPasswordValidation
      ]],
    });
  }

  ngOnInit(): void {
    this.user_id = this.auth.getUserid();
    this.token_id = this.auth.getTokenid();
    if(this.user_id == "" || this.token_id == "") {
      this.router.navigateByUrl("/login")
    }
  }

  resetPassword(): void {
    this.spin = true;
    this.auth.resetPassword({
      user_id: this.user_id,
      token_id: this.token_id,
      password: this.form.value.password
    }).subscribe(
      data => this.handleData(data),
      error => this.handleError(error)
    )
  }

  handleData(data:any): void {
    this.spin = false;
    this.router.navigate(['../login']);
  }

  handleError(error: any): void {
    this.spin = false;
    this.error = error.error.message;
  }

}
