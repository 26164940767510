import { Observable } from 'rxjs';
import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RHbackEndpoint } from 'src/app/config';

const endpoint =  RHbackEndpoint+'/api/opportunite';

@Injectable({
  providedIn: 'root'
})
export class OpportuniteService {

  constructor(private http: HttpClient){}

  addOpportunite(data): Observable<any>{
    return this.http.post(endpoint+"/add", data);
  }

  getOpportunites(trigcrm: string, userid: string, date: string, dateouverture: string, client: string,
    contact: string, telcontact: string, emailcontact: string, description: string, competences: string,
    debutmission: string, observations: string, sortby: string, sortdirection: string, page: string,
     size: string): Observable<any>{
    return this.http.get(endpoint, {params: {
      "trigcrm": trigcrm,
      "userid": userid,
      "date": date,
      "dateouverture": dateouverture,
      "client": client,
      "contact": contact,
      "telcontact": telcontact,
      "emailcontact": emailcontact,
      "description": description,
      "competences": competences,
      "debutmission": debutmission,
      "observations": observations,
      "sortby": sortby,
      "sortdirection": sortdirection,
      "page": page,
      "size": size
    }})
  }

}
